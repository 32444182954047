<template>
  <div class="userCenter">
    <div class="mine">
      <div class="scrollList">
        <div class="wrapper-tontent">
          <div class="headerBox">
            <div class="headerNav">
              <h6> Của tôi </h6>
              <nav @click="toMessage()">
                <div class="chat animate__animated"></div>
              </nav>
            </div>
            <div class="header">
              <div class="userInfo">
                <div>
                  <div class="avatar-container">
                    <img class="avatar-img" :src="require('@/assets/common/avatar/' + userInfo.Avatar + '.png')" alt="" @click="openAvatar()">
                    <i class="iconfont icon-icon_edit_avatar"></i>
                  </div>
                </div>
                <div class="user">
                  <p class="userName">
                    <span class="hi">HI, </span>
                    {{ userInfo.Account }}
                  </p>
                  <p class="joinDay">Gia nhập ngày thứ {{ getDay() }} </p>
                </div>
              </div>
              <div class="profile" @click="toPersonal()">
                <span> Trang cá nhân </span>
                <i class="iconfont icon-icon_more_white_16"></i>
              </div>
            </div>
            <div class="card moneyBox">
              <div class="money">
                <p class="userMoney">
                  <span>{{ $tools.formatNum2k(wallet.VndBalance) }}</span>
                </p>
                <div class="refresh">
                  <span class="myPurseText">Ví tiền của tôi</span>
                  <div class="refreshIconBox">
                    <i class="iconfont icon-icon_refresh_blue_12 refreshIcon"></i>
                  </div>
                </div>
              </div>
              <div class="payOrwithdraw">
                <div class="pay" @click="toRecharge()">
                  <span>Nạp tiền</span>
                </div>
                <div class="withdraw" @click="toWithDraw()">
                  <span>Rút tiền</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mineBody">
            <div class="card navigationBoxTop">
              <van-grid :border="false" :column-num="4">
                <van-grid-item v-for="(item, index) in gridList1" @click="gridClick(item)">
                  <div class="navigationBoxTopIcon" :class="item.class">
                  </div>
                  <span>{{ item.name }}</span>
                </van-grid-item>
              </van-grid>
            </div>
            <div class="card promote">
              <van-swipe :autoplay="3000" lazy-render>
                <van-swipe-item v-for="(item, index) in bannerDate" :key="item" @click="bannerClick(item)">
                  <img :src="item.PUrl" alt="" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <div class="discount-wrapper">
              <div class="discount" @click="jumpToDiscount()">
                <img :src="$img('userCenter/img_discount.png')" alt="">
              </div>
              <div class="discount" @click="jumpToApp()">
                <img :src="$img('userCenter/img_app.png')" alt="">
              </div>
            </div>
            <div class="card navigationBox">
              <h6>Tính năng chính</h6>
              <van-grid :border="false" :column-num="5">
                <van-grid-item v-for="(item, index) in gridList2" @click="gridClick(item, index)">
                  <div class="icon">
                    <span class="iconfont" :class="item.class"></span>
                  </div>
                  <span class="title">{{ item.name }}</span>
                </van-grid-item>
              </van-grid>
            </div>
            <div class="logout" @click="toLogin()">
              <div class="logoutBtn"> Đăng xuất </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>


<script>
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import action from '@/components/core/action'
export default {
  data() {
    return {

    };
  },
  components: {
  },

  watch: {},
  computed: {
    ...mapGetters("tcp", ["isLogged", "wallet", "basicInfo"]),
    ...mapGetters("user", ["userInfo",]),
    ...mapGetters("global", ["lobbyInfo", "version"]),
  },
  created() {

  },
  methods: {
    ...mapMutations("user", ["loginOut"]),
    ...mapActions("global", ["loginOutAll"]),
    ...mapActions("tcp", ["disConnect"]),
    toLogin() {
      this.$router.push({
        path: "/"
      });
      this.loginOutAll();
      Toast.success('Thoát thành công')

    },
    toMessage() {

      this.$router.push({ name: "messageCenter" });

    },
    toPersonal() {
      this.$router.push({ name: "personalInfo" });

    },
    gridClick(val, index) {
      ; if (index == 2) {
        let url = this.lobbyInfo.CustomerInfo.customerLiveChat;
        window.open(url)
        return;
      }
      this.$router.push({ name: val.to });
    },
    getDay() {
      // if (this.isLogged) {
      //   mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
      //     if (data.Code == 0) {
      //       return data.Data.RegisterDays;
      //     }
      //   });
      // }
      let dateSpan, tempDate, iDays

      var date = moment();
      var sDate1 = this.$tools.formatDate(this.userInfo.CreateAt, "yyyy-MM-dd")
      var sDate2 = moment(moment().valueOf()).format("YYYY-MM-DD")

      sDate1 = Date.parse(sDate1)
      sDate2 = Date.parse(sDate2)
      dateSpan = sDate2 - sDate1
      dateSpan = Math.abs(dateSpan)
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000))
      return iDays + 1

    },
    openAvatar() {
      bus.emit(bus.event.avatarState, true);
    },
    jumpToDiscount() {
      setTimeout(() => {
        bus.emit("jumpFromUserCenter");
      }, 50);
      var that = this
      that.$router.push({ path: "/" });


    },
    jumpToApp() {
      if (tools.platform() == 'h5_android') {
        this.$act.openPage(lobbyInfo.value.VersionGet.android.UrlPath)
      } else {
        //下载界面需要跳转
        this.$act.openPage("https://zbet668.com/static/download/index.html");
      }

    },
    toRecharge() {
      var that = this;
      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        if (!data.Data.DoudouPwd.length) {
          that.$dialog.confirm({
            title: "Xin lưu ý",
            message:
              "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ',
          })
            .then(() => {
              that.$router.push({ path: "/transactionPwd" });
            })
            .catch(() => { });
        } else
          if (!data.Data.myDouDouBT.length) {
            that.$dialog.confirm({
              title: "Xin lưu ý",
              message: 'Cần điền thông tin ngân hàng',
              confirmButtonText: 'Xác nhận',
              cancelButtonText: 'Hủy bỏ'
            })
              .then(() => {
                that.$router.push({ path: '/addBankCard' });
              })
              .catch(() => { });

          } else {
            that.$router.push({ name: "recharge" });

          }
      });
    },

    toWithDraw() {
      var that = this;
      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        if (!data.Data.DoudouPwd.length) {
          that.$dialog.confirm({
            title: "Xin lưu ý",
            message:
              "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ'
          })
            .then(() => {
              that.$router.push({ path: "/transactionPwd" });
            })
            .catch(() => { });
        } else if (!data.Data.myDouDouBT.length) {
          that.$dialog.confirm({
            title: "Xin lưu ý",
            message: 'Cần điền thông tin ngân hàng',
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ'
          })
            .then(() => {
              that.$router.push({ path: '/addBankCard' });
            })
            .catch(() => { });

        } else {
          that.$router.push({ name: "withdraw" });

        }
      });
    },
    columClick(item) {
      var that = this;
      if (item.to == "login") {
        Dialog.confirm({
          title: "Lời khuyên",
          message: "Chắc chắn muốn đăng xuất chứ? ",
          confirmButtonText: 'Xác nhận',
          cancelButtonText: 'Hủy bỏ'
        })
          .then(() => {
            this.$router.push({
              name: item.to,
            });
          })
          .catch(() => {
            // on cancel
          });
      } else if (item.to == "liveChat") {
        this.$act.openPage(this.lobbyInfo.CustomerInfo.customerLiveChat);
      } else {
        if (item.to.length) {
          var params = {};
          if (item.to == "betRecord") {
            params = { isRoot: false };
          }
          this.$router.push({
            name: item.to,
            params: params,
          });
        }
      }
    },
    back() {
      if (this.$route.path.indexOf("Lottery") >= 0) {
        setTimeout(() => {
          bus.emit("fromLottery");
        }, 100);
        this.$router.push({
          name: "home",
        });

        return;
      }
      window.history.back();
    },
    toAllGame() {
      if (!this.showArrow) {
        return;
      }
      this.$router.push({
        name: "allGameSelect",
        query: {
          curlottery: this.selectLottery.LotteryCode,
        },
      });
    },
  },
};
</script>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch } from "vue";

import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import Avatar from "@/views/mobile/components/Avatar.vue";
import { mqant } from "@/components/protocol/mqantlib";
import { Toast } from 'vant';

const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);

let gridList1 = reactive([

  {
    name: 'Lịch sử cược',
    class: 'tzjl',
    to: 'betRecord'
  },
  {
    name: 'Giao dịch',
    class: 'ctjl',
    to: 'billRecord'
  },
  {
    name: 'Phúc lợi Vip',
    class: 'zjzz',
    to: 'vip'
  },
  {
    name: 'Nhận ưu đãi',
    class: 'zjmx',
    to: 'discount'
  }

])
let gridList2 = reactive([
  {
    name: 'Thắng thua',
    class: 'icon-icon_ucenter_lssy',
    to: 'gameRecord'
  },
  {
    name: 'Chi tiết',
    class: 'icon-icon_ucenter_kjjg',
    to: 'rewardHistory'
  },
  {
    name: 'CSKH',
    class: 'icon-icon_ucenter_kfzx',
  },
  // {
  //   name: 'Trợ giúp',
  //   class: 'icon-icon_ucenter_bzzx'
  // },
  // {
  //   name: '',
  //   class: 'icon-icon_ucenter_banbh'
  // }
])
let bannerSort = lobbyInfo.value.FirstPageBanner.sort((a, b) => {
  return b.Sort - a.Sort;
});
let bannerDate = reactive([]);
for (let obj of bannerSort) {
  bannerDate.push(obj);
}

function bannerClick(val) {
  if (val.PType == "") {
    return;
  } else if (val.PType == "LinkUrl") {
    if (val.GameCode.length) {
      window.open(val.GameCode);
    }
  } else if (val.PType == "Activity") {
    setTimeout(() => {
      bus.emit("jumpFromUserCenter");
    }, 50);
    router.push({ path: "/" });

  } else {
    if (
      val.PType == "LiveCasino" ||
      val.PType == "Lottery" ||
      val.PType == "LiveSports"
    ) {
      if (val.Factory.length) {
        let name = val.Factory;
        let url = getApiLoginUrl(name, "");
        $act.openPage(url);
      } else {
      }
    } else if (val.PType == "Card" || val.PType == "Fish" || val.PType == "Slot") {
      if (val.Factory.length) {
        if (val.GameCode.length) {

          if (!isLogged.value) {
            router.push({ name: "LoginRegist" });
            return;
          }
          let url = getApiLoginUrl(val.Factory, val.GameCode);
          $act.openPage(url);
        } else {
          var name;
          if (val.PType == "Fish") {
            name = "fish";
          } else if (val.PType == "Slot") {
            name = "slot";
          } else {
            name = "chess";
          }
          router.push({
            name: name,
            params: {
              FactoryName: val.Factory,
            },
          });
        }
      }
    }
  }
}

function openRouter(name) {
  if (!name) return;

  router.push({
    name: name,
    query: {

    }
  })


}
</script>

<style lang="scss" scoped>
.userCenter {
  position: relative;
  width: 100%;
  height: 100%;

  .mine {
    padding: 0 4.266667vw;

    .card {
      background: #35416d;
      color: #fff;
    }

    .scrollList {
      background: #272d4d;
      overflow-y: auto;
      position: absolute;
      top: 0;
      bottom: 13.333333vw;
      left: 0;
      right: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .wrapper-tontent {
        .headerBox {
          padding: 0;
          box-sizing: border-box;
          background: url("@/assets/mobile/userCenter/img_bg_person.png") right
            0 no-repeat #1c233d;
          background-size: 90%;

          .headerNav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3.466667vw 4.266667vw;

            h6 {
              color: #fff;
              font-size: 4.8vw;
            }

            nav {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 50%;

              > div {
                width: 7.466667vw;
                height: 7.466667vw;
              }

              .chat {
                background: url("@/assets/mobile/userCenter/icon-new-message.png")
                  no-repeat;
                background-size: cover;
              }

              .animate__animated {
                animation-duration: 1s;
                animation-fill-mode: both;
              }
            }
          }

          .header {
            display: flex;
            justify-content: space-between;
            padding: 1.333333vw 4.266667vw 2.666667vw;
            position: relative;

            .userInfo {
              display: flex;

              .avatar-container {
                width: 16vw;
                height: 16vw;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid hsla(0, 0%, 100%, 0.22);
                border-radius: 8vw;
                overflow: hidden;

                .avatar-img {
                  max-width: 16vw;
                  max-height: 16vw;
                  object-fit: cover;
                  box-sizing: border-box;
                }

                .iconfont {
                  position: absolute;
                  right: 1.6vw;
                  bottom: 1.6vw;
                  margin-left: -2.133333vw;
                  font-size: 4.266667vw;
                  color: #e9cfa4;
                  background-color: #fff;
                  border-radius: 2.666667vw;
                  overflow: hidden;
                  line-height: 4.266667vw;
                }
              }

              .user {
                margin-left: 2.133333vw;
                display: flex;
                justify-content: center;
                flex-direction: column;
                color: #fff;
                overflow: hidden;

                p {
                  line-height: 1.5;
                  font-size: 3.733333vw;
                }

                .userName {
                  .hi {
                    color: #e9cfa4;
                  }
                }

                .joinDay {
                  display: flex;
                  align-items: center;
                  font-size: 3.2vw;
                }
              }
            }

            .profile {
              color: #fff;
              font-size: 3.2vw;
              display: flex;
              align-items: center;
              position: absolute;
              right: 2.133333vw;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);

              span {
                opacity: 0.8;
                white-space: nowrap;
              }

              i {
                margin-left: 0;
              }
            }
          }

          .moneyBox {
            background-image: url("@/assets/mobile/userCenter/bg_my_wallet.png");
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5.333333vw;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: 24vw;
            margin: 0 4.266667vw;
            padding: 0 4vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 2.666667vw;

            .money {
              overflow: hidden;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex: 1;

              .userMoney {
                font-size: 4.266667vw;
                height: 7.466667vw;
                color: #9a6c31;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              p {
                line-height: 1;
                width: 100%;
              }

              .refresh {
                display: flex;

                .myPurseText {
                  font-size: 4.266667vw;
                  color: #9a6c31;
                  text-align: right;
                  margin-right: 2.666667vw;
                  font-weight: 500;
                  white-space: nowrap;
                }

                .refreshIconBox {
                  width: 3.733333vw;
                  height: 3.733333vw;
                  text-align: center;
                  vertical-align: middle;
                  position: relative;
                }

                .refreshIcon {
                  width: 4.266667vw;
                  height: 4.266667vw;
                  font-size: 4.266667vw;
                  color: #202844;
                  position: absolute;
                  left: 0;
                }
              }
            }

            .payOrwithdraw {
              display: flex;
              justify-content: flex-end;
              width: 37.333333vw;

              .pay {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 1px solid #874f02;
                text-align: center;
                border-radius: 4.266667vw;
                background: #874f02;
                margin-left: 2.666667vw;

                span {
                  display: inline-block;
                  width: 16vw;
                  height: 6.666667vw;
                  vertical-align: middle;
                  border-radius: 4.266667vw;
                  font-size: 3.2vw;
                  color: #fff;
                  font-weight: 400;
                  line-height: 6.666667vw;
                }
              }

              .withdraw {
                align-items: flex-end;
                background: 0 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 1px solid #874f02;
                text-align: center;
                border-radius: 4.266667vw;
                margin-left: 2.666667vw;

                span {
                  color: #874f02;
                  display: inline-block;
                  width: 16vw;
                  height: 6.666667vw;
                  vertical-align: middle;
                  border-radius: 4.266667vw;
                  font-size: 3.2vw;
                  font-weight: 400;
                  line-height: 6.666667vw;
                }
              }
            }
          }
        }

        .mineBody {
          background-image: linear-gradient(#1c233d, #272d4d 13.333333vw);

          .navigationBoxTop {
            border-radius: 6.4vw;
            color: #fff;
            font-size: 3.2vw;
            margin-top: 0;
            padding: 1.333333vw;

            :deep(.van-grid-item__content) {
              display: flex;
              align-items: center;
              justify-content: center;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -webkit-flex-direction: column;
              flex-direction: column;
              box-sizing: border-box;
              height: 100%;
              padding: 4.266667vw 2.133333vw;
              background-color: transparent;

              .zjzz {
                background: url("@/assets/mobile/userCenter/vip_icon.png");
                background-size: cover;
              }

              .zjmx {
                background: url("@/assets/mobile/userCenter/fanshui_icon.png");
                background-size: cover;
              }

              .tzjl {
                background: url("@/assets/mobile/userCenter/icon_tzjl.png");
                background-size: cover;
              }

              .ctjl {
                background: url("@/assets/mobile/userCenter/icon_ctjl.png");
                background-size: cover;
              }

              .navigationBoxTopIcon {
                width: 12.8vw;
                height: 12.8vw;
                margin-bottom: 2.666667vw;
              }
            }
          }

          .promote {
            margin: 4.266667vw 4.266667vw 3.2vw;
            height: 29.333333vw;

            :deep(.van-swipe) {
              border-radius: 2.133333vw;

              .van-swipe-item {
                color: #fff;
                font-size: 5.333333vw;
                line-height: 29.333333vw;
                height: 29.333333vw;
                text-align: center;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .discount-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 4.266667vw;

            .discount {
              padding: 0 0 1.6vw;

              img {
                border-radius: 2.133333vw;
                width: 44vw;
                height: 18.933333vw;
              }
            }
          }

          .navigationBox {
            border-radius: 0;
            margin-top: 0;
            padding: 4.266667vw 4.266667vw 0;

            h6 {
              font-size: 4.266667vw;
              margin-bottom: 4vw;
              font-weight: 600;
            }

            :deep(.van-grid) {
              position: relative;

              .van-grid-item {
                text-align: center;
                color: hsla(0, 0%, 100%, 0.6);
                margin-bottom: 3.2vw;
                overflow: hidden;
              }

              .van-grid-item__content {
                display: flex;
                align-items: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                flex-direction: column;
                box-sizing: border-box;
                height: 100%;
                padding: 0;
                background-color: transparent;

                .icon {
                  width: 11.733333vw;
                  height: 11.733333vw;
                  border-radius: 50%;
                  vertical-align: middle;
                  line-height: 11.733333vw;
                  text-align: center;

                  .iconfont {
                    font-size: 8.533333vw;
                    color: #fff;
                  }
                }

                .title {
                  display: inline-block;
                  font-size: 3.2vw;
                  white-space: nowrap;
                  -webkit-transform: scale(0.92);
                  transform: scale(0.92);
                }
              }
            }
          }

          .logout {
            .logoutBtn {
              margin: 5.333333vw;
              height: 13.333333vw;
              background-image: linear-gradient(90deg, #7146ff, #4a69ff);
              border-radius: 6.666667vw;
              line-height: 13.333333vw;
              vertical-align: middle;
              font-size: 3.733333vw;
              color: #fff;
              text-align: center;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
