<template>
  <div>
    <h1 style="text-align: center;"><strong>HƯỚNG DẪN NẠP TIỀN</strong></h1>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">- ZBET668 hỗ trợ nhiều hình thức gửi
        tiền Quý Khách có thể tùy chọn phương thức phù hợp để gửi tiền.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">1. Quý Khách vào mục NẠP TIỀN.</span>
    </p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">2. Chọn phương thức nạp, chọn ngân hàng
        muốn chuyển và chọn số tiền Quý Khách muốn nạp vào tài khoản.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">3. Xác nhận số tiền gửi hệ thống sẽ
        hiển thị thông tin ngân hàng đại diện mới nhất của ZBET668.com Quý Khách tiến hành thao tác
        chuyển tiền dễ dàng vào ngân hàng đang hiển thị theo hướng dẫn của ZBET668.com .</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">4. Sau khi tao tác chuyển thành công
        phía Quý Khách thì xin mời Quý Khách quay vào trang chủ ZBET668.com sau 1 vài giây dòng tiền Quý
        Khách nạp đã được cập nhật thành công.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">- Nếu có bất kỳ vấn đề gì với việc
        thanh toán, Quý Khách có thể liên hệ bộ phận dịch vụ khách hàng hoặc nhân viên dịch vụ khách
        hàng có thể liên hệ với Quý Khách để hỗ trợ ngay lập tức.</span></p>
    <p><span style="color: rgb(255, 255, 255);"><strong>A. PHƯƠNG THỨC CHUYỂN KHOẢN NGÂN HÀNG ( Sử dụng internet
          banking để thanh toán giao dịch)</strong></span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">1. Quý Khách vào NẠP TIỀN chọn CHUYỂN
        KHOẢN NGÂN HÀNG sau đó chọn mục nạp TỰ ĐỘNG ZBET668.com</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">2. Chọn bất kì ngân hàng nào đang có
        tại cổng nạp.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">3. Điền số điểm muốn nạp (50 điểm =
        50,000 VNĐ) - Chọn Thanh toán ngay bây giờ</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">4. Hệ thống sẽ hiển thị cửa số mới gồm
        Ngân hàng nhận, số tài khoản, tên chủ tài khoản nhận, và nội dung yêu cầu chuyển khoản.</span>
    </p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">5. Quý Khách vui lòng sao chép đúng
        thông tin tài khoản nhận và nội dung nạp sau đó đăng nhập internet banking thao tác chuyển
        khoản</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">6. Sau khi tao tác chuyển thành công
        phía Quý Khách thì xin mời Quý Khách quay vào trang chủ ZBET668.com sau 1 vài giây dòng tiền Quý
        Khách nạp đã được cập nhật thành công.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">* Nếu có bất kỳ vấn đề gì với việc
        thanh toán, Quý Khách có thể liên hệ bộ phận dịch vụ khách hàng hoặc nhân viên dịch vụ khách
        hàng có thể liên hệ với Quý Khách để hỗ trợ ngay lập tức.</span></p>
    <p><span style="color: rgb(255, 255, 255);"><strong>B. PHƯƠNG THỨC NẠP QR (Sử dụng app banking để quét mã thanh
          toán giao dịch)</strong></span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">1. Quý Khách vào mục NẠP TIỀN chọn nạp
        Quét mã ngân hàng.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">2. Chọn ngân hàng giao dịch (theo hiển
        thị có sẵn của cổng nạp)</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">3. Điền số tiền muốn nạp (100 điểm nạp
        = 100,000 VNĐ) - Chọn Thanh toán ngay bây giờ</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">4. Hệ thống nạp sẽ hiển thị mã QR và
        nội dung nạp cần điền, Quý KJhách chụp lại mã QR và lưu lại.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">5. Quý Khách đăng nhập banking của mình
        chọn quét mã QR và tải hình ảnh mã QR đã lưu.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">6. Sau khi tao tác chuyển thành công
        phía Quý Khách thì xin mời Quý Khách quay vào trang chủ ZBET668.com sau 1 vài giây dòng tiền Quý
        Khách nạp đã được cập nhật thành công.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">* Nếu có bất kỳ vấn đề gì với việc
        thanh toán, Quý Khách có thể liên hệ bộ phận dịch vụ khách hàng hoặc nhân viên dịch vụ khách
        hàng có thể liên hệ với Quý Khách để hỗ trợ ngay lập tức.</span></p>
    <p><span style="color: rgb(255, 255, 255);"><strong>C. PHƯƠNG THỨC NẠP MOMO ( Sử dụng ví MOMO để thanh toán giao
          dịch )</strong></span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">1. Quý Khách vào mục NẠP TIỀN NGAY chọn
        nạp MOMO.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">2. Điền số điểm muốn nạp (50 điểm nạp =
        50,000 VNĐ) - Chọn Thanh toán ngay bây giờ</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">3. Hệ thống sẽ hiển thị cửa số mới gồm
        mã QR và số MOMO người nhận kèm theo dãy số NỘI DUNG quý khách chụp lại mã QR và lưu vào thiết
        bị.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">4. Đăng nhập ví điện tử MOMO cá nhân
        chọn Quét mã trong MOMO và tải hình ảnh QR đã lưu (hoặc có thể coppy số MOMO người nhận để
        chuyển tiền). Điền đúng mã số nội dung đã hiển thị trên mã QR</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">5. Sau khi tao tác xác nhận chuyển
        thành công phía Quý Khách thì xin mời Quý Khách quay vào trang chủ sau 1 vài giây dòng tiền Quý
        Khách nạp đã được cập nhật thành công.</span></p>
    <p><span style="color: rgb(255, 255, 255);"><strong>D. PHƯƠNG THỨC NẠP THẺ CÀO (Sử dụng THẺ CÀO ĐIỆN THOẠI để
          thanh toán giao dịch)</strong></span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">1. Quý Khách vào mục NẠP TIỀN NGAY chọn
        nạp THẺ CÀO.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">2. Chọn nhà mạng điện thoại bất kỳ sau
        đó chọn mệnh giá thẻ cào muốn nạp.</span></p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">3. Nhập mã số thẻ cào sau đó nhập số
        seri thẻ cào và bấm nút xác nhận để hoàn tất thao tác nạp tiền bằng thẻ cào điện thoại.</span>
    </p>
    <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">4. Sau khi tao tác xác nhận thành công
        phía Quý Khách thì xin mời Quý Khách quay vào trang chủ sau 1 vài giây dòng tiền Quý Khách nạp
        đã được cập nhật thành công. Lưu ý: Nạp tiền thẻ cào quý khách sẽ phải chịu phí gạch thẻ của nhà
        mạng.</span></p>
    <p><span style="color: rgb(255, 255, 255);"> </span></p>

  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>