<template>
  <topHeader title="Nhận ưu đãi" :showRight="false" :showBack="true"></topHeader>
  <div class="wrap">
    <div class="flexs_r" style="padding-top: 20px">
      <div class="flexs_colum" style="text-align: center; margin-left: 22vw">
        <div @click="selectIndex = 0" :class="selectIndex == 0 ? 'select' : 'nolmal'" class="topBtn phoneMiddle boldSize"
          style="">
          {{ $t("红利") }}
        </div>
      </div>
      <div class="flexs_colum" style="text-align: center">
        <div @click="selectIndex = 1" :class="selectIndex == 1 ? 'select' : 'nolmal'"
          class="topBtn mainBG phoneMiddle boldSize" style="">
          {{ $t("返水") }}
        </div>
      </div>
    </div>
    <div class="no-data" v-if="!getList().length">{{ "Không có dữ liệu" }}</div>

    <div class="list" v-for="(item, index) in getList()" :key="item" style="margin-top: 5vw">
      <div class="title" style="">{{ item.ActivityName }}</div>
      <div class="flexs_sb" style="padding: 0 10px; height: 45px">
        <div class="" style="color: rgb(138, 159, 220)">
          {{ $t("金额")
          }}<span style="color: rgb(234, 181, 25)">{{
  $tools.formatNum(item.Get)
}}</span>
        </div>
        <div class="lingqu" @click="onClick(item)">{{ $t("领取") }}</div>
      </div>
    </div>
  </div>

  <my-dialog v-if="showDialog" :close="function () {
    showDialog = false;
  }
    " :title="dialogTitle" :msg="dialogMsg" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { http, topic } from "@/components/protocol/api";
import auth from "@/components/core/auth";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";

export default {
  data() {
    return {
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      selectIndex: 0,
    };
  },
  components: {
    topHeader,
  },
  props: {},
  created() { },
  mounted() { },
  computed: {
    ...mapGetters("tcp", ["basicInfo", "wallet"]),
  },
  methods: {
    ...mapActions("tcp", ["requestBasicInfo"]),
    getList() {
      return this.selectIndex == 0
        ? this.basicInfo.BonusList
        : this.basicInfo.RebateList;
    },
    onClick(item) {
      var that = this;
      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        if (!data.Data.DoudouPwd.length) {
          that.$dialog
            .confirm({
              title: "Xin lưu ý",
              message:
                "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
              confirmButtonText: "Xác nhận",
              cancelButtonText: "Hủy bỏ",
            })
            .then(() => {
              that.$router.push({ path: "/transactionPwd" });
            })
            .catch(() => { });
        } else if (!data.Data.myDouDouBT.length) {
          that.$dialog
            .confirm({
              title: "Xin lưu ý",
              message: "Cần điền thông tin ngân hàng",
              confirmButtonText: "Xác nhận",
              cancelButtonText: "Hủy bỏ",
            })
            .then(() => {
              that.$router.push({ path: "/addBankCard" });
            })
            .catch(() => { });
        } else {
          mqant.request(
            topic.discount,
            { ActivityId: item.ActivityId },
            function (res, topic, msg) {
              if (res.Code == 0) {
                that.requestBasicInfo();
                that.$message({
                  message: that.$t('领取成功') + '!',
                  type: 'success'
                });
              }
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topBtn {
  color: white;
  width: 28vw;
  height: 8vw;
  text-align: center;
  line-height: 8vw;
  border-radius: 1vw;
}

.item {
  width: 32vw;
  height: 10vw;
  line-height: 10vw;
  background-color: #3a8c38;
  margin-left: 7.5vw;
  position: relative;
  z-index: 99;
  margin-top: 2vw;
}

.item2 {
  width: 32vw;
  height: 10vw;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 10vw;
  margin-left: -1vw;
  margin-top: 2vw;
  position: relative;
  background-color: rgb(44, 44, 44);
  z-index: 1;
}

.lingqu {
  //width: 64px;
  padding: 0 5px;
  height: 26px;
  line-height: 26px;
  background: linear-gradient(to right, rgb(110, 74, 255), rgb(80, 100, 255));
  color: white;
  text-align: center;
  border-radius: 5px;
}

.select {
  color: white;
  background: linear-gradient(to right, rgb(110, 74, 255), rgb(80, 100, 255));
}

.nolmal {
  background-color: rgb(34, 43, 73);
  color: white;
}

.wrap {
  padding-top: 50px;
}

.list {
  border-radius: 4px;
  width: 90vw;
  margin-left: 5vw;
  background-color: rgb(53, 65, 109);
}

.title {
  height: 32px;
  background-color: rgb(34, 43, 73);
  color: white;
  padding-left: 10px;
  line-height: 32px;
}

.no-data {
  padding-top: 6.4vw;
  height: 5.333333vw;
  line-height: 5.333333vw;
  opacity: 0.5;
  color: #fff;
  text-align: center;
  font-size: 3.733333vw;
  font-weight: 500;
}
</style>
