<template>
  <div class="qrCode">
    <div class="card">
      <p class="channelTitle">Số tiền nạp</p>
      <div class="less-pay">
        <span>Chuyển khoản ngân hàng</span>
        <span>Giới hạn nạp tối thiểu <span style="color:red;">&nbsp;{{ $tools.formatNum2k(obj.Mini) }}~{{
          $tools.formatNum2k(obj.Max) }} VND(K)</span></span>
      </div>
    </div>
    <div class="card">
      <div class="select-bank" @click="showPicker = true">
        <span class="span-1">Chọn ngân hàng</span>
        <span class="span-2">{{ obj.curBankName }}</span>
        <img :src="$img('userCenter/icon_jiantou.png')" alt="">
      </div>
    </div>
    <div class="card">
      <div class="input-class">
        <van-field v-if="ChargeNum > 0" v-model="obj.money" type="number" readonly placeholder="Vui lòng nhập số tiền"
          :border="false" />

        <van-field v-else v-model="obj.money" type="number" placeholder="Vui lòng nhập số tiền" :border="false" />


        <div class="input-money">= {{ Number(obj.money) * 1000 }} VND</div>
        <!-- <van-field v-model="value" label="银行账号" placeholder="请输入您的银行账号" :border="false" /> -->
      </div>
    </div>
    <div class="card">
      <div class="pay-btn" @click="onSubmit">
        <span> Nạp ngay </span>
      </div>
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker :columns="obj.bankNameList" @cancel="showPicker = false" @confirm="onConfirm"
        confirm-button-text="confirm" cancel-button-text="cancel" />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch, defineProps } from "vue";
import { Toast } from "vant";
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const loading = ref(true);
const props = defineProps({
  data: {}
})
let showPicker = ref(false);
let obj = reactive({
  bankNameList: [],
  curBankName: '',
  money: '',
  Mini: 0,
  Max: 0,
  MethodId: ''
})

let ChargeNum = ref(0);

onMounted(() => {
  if (Object.keys(props.data).length <= 0) return;


  if (localStorage.getItem('ChargeNum')) {
    ChargeNum.value = localStorage.getItem('ChargeNum')
    obj.money = localStorage.getItem('ChargeNum')
  }
  for (let key in props.data.vgPayBankList) {
    let info = props.data.vgPayBankList[key];
    if (info._id == "direct") {
      for (let key2 in info.bankList) {
        obj.bankNameList.push(info.bankList[key2].Name);
      }
    }
  }
  obj.curBankName = obj.bankNameList[0];
  for (let key in props.data.payList) {
    let info = props.data.payList[key];
    if (info.MethodType == "direct" && info.Merchant == 'VgPay') {
      obj.Mini = info.Mini;
      obj.Max = info.Max;
      obj.MethodId = info.MethodId;
    }
  }
})

function onConfirm(value) {
  obj.curBankName = value;
  showPicker.value = false;
}
function onSubmit() {
  if (!obj.money) {
    Toast.fail('Vui lòng nhập số tiền');
    return;
  }
  if (Number(obj.money) * 1000 < obj.Mini || Number(obj.money) * 1000 > obj.Max) {
    Toast.fail('Số tiền nhập khẩu sẽ là' + obj.Mini + '-' + obj.Max);
    return
  }
  let objSubmit = {
    amount: Number(obj.money) * 1000,
    methodId: obj.MethodId,
    accountName: 'default',
    bankName: obj.curBankName
  }
  if (ChargeNum.value > 0) {
    objSubmit.aType = 'TreasureBowl'
  }
  let oWindow = window.open('', '_blank');
  // log.info('objSubmit', objSubmit)
  mqant.request(topic.payCharge, objSubmit, function (data, topicName, msg) {
    if (data.Code == 0) {
      oWindow.location = data.Data.TargetUrl;
      obj.money = '';
      Toast.success(data.ErrMsg)
    } else {
      Toast.fail(data.ErrMsg)
    }
  })
}
</script>

<style lang="scss" scoped>
.qrCode {
  .card {
    padding: 4.266667vw 0 0;
    display: table;
    width: 100%;

    .channelTitle {
      height: 4.8vw;
      font-family: PingFangSC-Regular;
      font-size: 3.466667vw;
      color: #fff;
      font-weight: 400;
      margin-bottom: 2.666667vw;
    }

    :deep(.van-cell) {
      background-color: #1e2646;
      border-radius: 8px;
      font-size: 12px;
      --van-field-label-color: #424f81;
      --van-field-input-text-color: #fff;
    }
  }

  .less-pay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 21px;
    font-size: 12px;
    width: 100%;
    height: 82px;
    padding: 21px 12px;
    background-color: #2f395f;
    border-radius: 8px;

    >span {
      color: #fff;
    }

    span+span {
      color: #8a9fdc;
    }
  }

  .select-bank {
    width: 100%;
    height: 49px;
    padding: 0 16px;
    background-color: #1e2646;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .span-1 {
      font-size: 12px;
      color: #424f81;
    }

    .span-2 {
      font-size: 12px;
      color: #ffffff;
    }

    img {
      height: 11px;
    }
  }

  .bank-info {
    margin-top: 12px;
    background-color: #2f395f;
    border-radius: 5px;
    height: 167px;
    padding: 13px 17px;

    .bank-info-item {
      height: 30px;
      border-bottom: solid 2px #434f78;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;

      span {
        font-size: 13px;
        color: #8a9fdc
      }

      img {
        width: 17px;
        height: 17px;
      }
    }

    .bank-tips {
      margin-top: 12px;
      font-size: 10px;
      color: #ee0a24;
    }
  }

  .input-class {
    .input-money {
      margin: 9px;
      font-size: 10px;
      color: #e9cfa4;
    }
  }

  .pay-btn {
    position: relative;
    height: 49px;
    background-image: linear-gradient(90deg,
        #6e4aff 17%,
        #5064ff 100%),
      linear-gradient(#0e1525,
        #0e1525);
    background-blend-mode: normal,
      normal;
    border-radius: 25px;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>
