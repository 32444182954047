<template>
  <div class="chatroom">
    <div class="chatroom-header">
      <van-nav-bar title="Phòng chat" left-arrow @click-left="close">
        <template #right>
          <span class="iconfont rightIcon icon-icon_setup" @click="settingShow = true"></span>
          <span class="iconfont rightIcon icon-icon_sidebar" @click="showRightPop = true"></span>
        </template>
      </van-nav-bar>
      <ComonRightInfoPop :showPop="showRightPop" />
    </div>
    <div v-if="pinnedList.length > 0 && pinnedObj.show" class="pinned">
      <div class="pinned-msg-bar">
        <div class="label">Ghim :</div>
        <div class="msg-wp">
          <div v-for="(item, index) in pinnedList" class="msg-item-wp">
            <div class="img-wp" @click="pinnedItemClick(item)">
              <img :src="item.appendInfo.normalImg">
            </div>
          </div>
          <div class="iconfont icon-icon_close_24" @click="pinnedObj.show = false"></div>
        </div>
      </div>
    </div>
    <div class="chatroom-body" id="bodyId" @scroll="bodyScrollHandler">
      <van-loading v-if="loading" class="loading" type="spinner" />
      <ul v-if="!loading" id="chatLogListId">
        <li v-for="(item, index) in chatList">
          <span id="li-flag-id" v-text="setLiFlag(item)" style="display:none"></span>
          <div v-if="(item.msgType == msgType.MSG_TEXT || item.msgType == msgType.MSG_IMAGE)" class="chatLogItem">
            <div class="msgItem" :class="isSelf(item) ? 'userMsg' : ''">
              <div class="avatar">
                <div>
                  <img v-if="isSystem(item.fromUid) || !item.fromUser.Avatar" :src="require('@/assets/pc/chat/mb2p.png')" class="avatar">
                  <img v-else :src="require('@/assets/common/avatar/' + item.fromUser.Avatar + '.png')" class="avatar">
                </div>
              </div>
              <div class="msgContent level" :class="item.vipLevel <= 8 ? 'level' + item.vipLevel : 'level8'">
                <div class="nameImg">
                  <template v-if="isSystem(item.fromUid)">
                    <img src="@/assets/pc/chat/img_vip_gl_1.png" alt="" class="levelImg">
                    <img src="@/assets/pc/chat/img_vip_gl_2.png" alt="" class="levelImgAngle levelImgAngleGl">
                    <span class="nickname">Zbet668：</span>
                    <span class="message">{{
                                            item.content }}
                    </span>
                  </template>
                  <template v-else>
                    <img v-show="item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_1.png')" alt="" class="levelImg">
                    <img v-show="!isSelf(item) && item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_2.png')" alt="" class="levelImgAngle">
                    <span class="nickname">{{ formatName(item.fromUser.NickName) }}：</span><span class="message">{{
                                                item.content }}
                    </span>
                  </template>
                </div>
                <div class="van-img" v-if="item.msgType == msgType.MSG_IMAGE">
                  <van-image :src="item.appendInfo.normalImg" @click="showImage(item.appendInfo.normalImg)">
                  </van-image>
                </div>
                <div class="shareOrder" v-if="item.msgType == msgType.MSG_LOTTERY_BET">
                  <div class="orderItem">
                    <div>
                      <div class="itemWrap">
                        <div class="item lotteyName">{{
                                                    getLotteyName(item.appendInfo)
                                                }}
                        </div>
                        <div class="item">{{ item.appendInfo.Number }}</div>
                        <div class="item play">
                          <span> Tên kiểu cược：</span>
                          <div>{{ getLotteyPlay(item.appendInfo) }}</div>
                        </div>
                        <div class="item itemContent">
                          <span> Đặt cược： </span>
                          <div>{{ getLotteyType(item.appendInfo.Code) }}</div>
                        </div>
                        <div class="item betNum">
                          <span> Số lần cược： </span>
                          <div class="greenText">{{ getLotteyBetCount(item.appendInfo.Code) }}
                          </div>
                        </div>
                        <div class="item betMoney">
                          <span> Tổng tiền cược： </span>
                          <div class="greenText">{{ item.appendInfo.TotalAmount }}</div>
                        </div>
                      </div>
                      <div class="betBtn" @click="showBetInfoDialog(item.appendInfo)">Cược theo
                        ngay
                      </div>
                    </div>
                  </div>
                </div>
                <p class="time">{{ $tools.formatDate(item.createAt, "yyyy-MM-dd") }}</p>
              </div>
            </div>
          </div>
          <div v-else-if="item.msgType == msgType.MSG_LOTTERY_BET" class="chatLogItem">
            <div class="msgItem" :class="isSelf(item) ? 'userMsg' : ''">
              <div class="avatar">
                <div>
                  <img v-if="item.appendInfo.isRobot || !item.fromUser.Avatar" :src="require('@/assets/common/avatar/' + item.appendInfo.Avatar + '.png')" class="avatar">
                  <img v-else :src="require('@/assets/common/avatar/' + item.fromUser.Avatar + '.png')" class="avatar">
                </div>
              </div>
              <div class="msgContent level" :class="(item.appendInfo.isRobot ? item.appendInfo.vipLevel : item.vipLevel) <= 8 ? 'level' + (item.appendInfo.isRobot ? item.appendInfo.vipLevel : item.vipLevel) : 'level8'">
                <div v-if="item.appendInfo.isRobot" class="nameImg">
                  <img v-show="item.appendInfo.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.appendInfo.vipLevel + '_1.png')" alt="" class="levelImg">
                  <img v-show="!isSelf(item) && item.appendInfo.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.appendInfo.vipLevel + '_2.png')" alt="" class="levelImgAngle">
                  <span class="nickname">{{ formatName(item.appendInfo.NickName) }}：</span><span class="message">{{
                                            item.content }}
                  </span>
                </div>
                <div v-else class="nameImg">
                  <img v-show="item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_1.png')" alt="" class="levelImg">
                  <img v-show="!isSelf(item) && item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_2.png')" alt="" class="levelImgAngle">
                  <span class="nickname">{{ formatName(item.fromUser.NickName) }}：</span><span class="message">{{
                                            item.content }}
                  </span>
                </div>
                <div class="van-img" v-if="item.msgType == msgType.MSG_IMAGE">
                  <van-image :src="item.appendInfo.normalImg" @click="showImage(item.appendInfo.normalImg)">
                  </van-image>
                </div>
                <div class="shareOrder" v-if="item.msgType == msgType.MSG_LOTTERY_BET">
                  <div class="orderItem">
                    <div>
                      <div class="itemWrap">
                        <div class="item lotteyName">{{
                                                    getLotteyName(item.appendInfo)
                                                }}
                        </div>
                        <div class="item">{{ item.appendInfo.Number }}</div>
                        <div class="item play">
                          <span> Tên kiểu cược：</span>
                          <div>{{ getLotteyPlay(item.appendInfo) }}</div>
                        </div>
                        <div class="item itemContent">
                          <span> Đặt cược： </span>
                          <div>{{ getLotteyType(item.appendInfo.Code) }}</div>
                        </div>
                        <div class="item betNum">
                          <span> Số lần cược： </span>
                          <div class="greenText">{{ getLotteyBetCount(item.appendInfo.Code) }}
                          </div>
                        </div>
                        <div class="item betMoney">
                          <span> Tổng tiền cược： </span>
                          <div class="greenText">{{ item.appendInfo.TotalAmount }}</div>
                        </div>
                      </div>
                      <div class="betBtn" @click="showBetInfoDialog(item.appendInfo)">Cược theo
                        ngay
                      </div>
                    </div>
                  </div>
                </div>
                <p class="time">{{ $tools.formatDate(item.createAt, "yyyy-MM-dd") }}</p>
              </div>
            </div>
          </div>
          <div v-else-if="item.msgType == msgType.MSG_REDEVNELOP_TIP" class="chatRedRecord">
            <span class="name">{{ formatName(item.appendInfo.nickName) }}&nbsp;</span>
            <span class="recordText2"> Chúc mừng bạn nhận được lì xì</span>
            <span class="redRecordMoney">{{ item.appendInfo.vndBalance }}</span>
          </div>
          <div v-else-if="item.msgType == msgType.MSG_LOTTERY_PLAY_RECOMMEND" class="changLong">
            <div class="chatLogItem">
              <div class="avatar">
                <div>
                  <img v-if="isSystem(item.fromUid) || !item.fromUser.Avatar" :src="require('@/assets/pc/chat/mb2p.png')" class="avatar">
                  <img v-else :src="require('@/assets/common/avatar/' + item.fromUser.Avatar + '.png')" class="avatar">
                </div>
              </div>
              <div class="main">
                <div class="title">
                  <span class="lotteyName">{{
                                        getLotteyName(item.appendInfo)
                                    }}</span>
                  <span class="issue">{{ item.appendInfo.Number }}</span>
                </div>
                <div class="logItem">
                  <div class="playName">
                    <span class="names" tabindex="0">{{ getLotteyPlay(item.appendInfo)
                                        }}</span>
                    <span class="plays1">{{ item.appendInfo.Code }}</span>
                  </div>
                  <div class="logItemInfo">
                    <div class="openInfo"><span class="openIssue">{{ item.appendInfo.KeepNum }}Kỳ</span>
                      <span class="openState">liên tiếp</span>
                    </div>
                    <div class="timeInfo">
                      <span v-if="item.appendInfo.lessTime < 0" class="close">Đang đóng
                        kèo</span>
                      <i v-if="item.appendInfo.lessTime >= 0" class="iconfont icon-icon_alarm">
                        <span>{{ new Date(item.appendInfo.lessTime).Format("mm: ss")
                                                }}</span>
                      </i>
                    </div>
                  </div>
                  <div class="playItem">
                    <span class="plays active" @click="showBetTypeDialog(item.appendInfo, 0)"> {{
                                            getBetTypeText(item.appendInfo)[0] }}
                    </span>
                    <span class="plays active" @click="showBetTypeDialog(item.appendInfo, 1)"> {{
                                            getBetTypeText(item.appendInfo)[1] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="chatroom-footer">
      <div class="chatInput">
        <div class="left">
          <van-uploader v-if="!$tools.isNative()" @click-upload="openUpload()" :disabled="uploadObj.disabled" :after-read="afterRead" :max-size="500 * 1024" @oversize="onOversize">
            <van-button><span class="iconfont icon-icon_send_img"></span></van-button>
          </van-uploader>
          <!-- <van-button v-if="$tools.isNative()"> -->
          <span v-if="$tools.isNative()" class="iconfont icon-icon_send_img" @click="openCamare()">
          </span>
          <!-- </van-button> -->
        </div>
        <div class="mid">
          <div placeholder="Nhập nội dung chat tại đây..." contenteditable="true" class="edit-div" id="ed-input" @keydown.enter.native.prevent="sendMsg()">
          </div>
          <span>
            <i class="iconfont icon-icon_send_emoji" @click="showEmoji = !showEmoji"></i>
          </span>

        </div>
        <div class="right">
          <van-button @click="sendMsg()">Gửi</van-button>
        </div>
      </div>
    </div>
    <div v-if="redBagObj.show && redBag.state == 0" class="redBagFix">
      <div class="wrapper">
        <div class="item">
          <img :src="$img('chat/redBag4.png')" alt="">
          <div class="detail faildDetail">
            <div class="title">Xin lỗi</div>
            <div class="title faild"></div>
          </div>
          <div class="ball errorBall" @click='closeRedBagFix(redBag.state)'>
            <i class="iconfont icon-icon_floatbar_close"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="redBagObj.show && redBag.state == 1" class="redBagFix">
      <div class="wrapper">
        <div class="item">
          <img :src="$img('chat/redBag4.png')" alt="">
          <div class="detail">
            <div class="title">Chúc mừng bạn nhận được lì xì</div>
            <div class="money">{{ redBag.money }}</div>
          </div>
          <div class="ball errorBall" @click='closeRedBagFix(redBag.state)'>
            <i class="iconfont icon-icon_floatbar_close"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="redBagObj.show && redBag.state == 2" class="redBagFix">
      <div class="redWrap">
        <img :src="$img('chat/redBag3.png')" alt="" class="redBag3">
        <p class="redBagP">Nhận lì xì miễn phí</p>
        <div class="openBtn" @click="robRedBag()">
          <span> Mở </span>
          <img :src="$img('chat/red_quanquan.png')" alt="" class="redBag2">
        </div>
        <div class="ball" @click='closeRedBagFix(redBag.state)'>
          <i class="iconfont icon-icon_floatbar_close"></i>
        </div>
      </div>
    </div>
    <div v-if="redBag.show" class="redBagAniBox">
      <div class="aniBox" @click="openRedBag()">
        <img src="@/assets/mobile/chat/red_eff_img.png" alt="" class="fireworks fireworksAni">
        <img src="@/assets/mobile/chat/red_eff_bg.png" alt="" class="redBagBg redBagBgAni">
        <img src="@/assets/mobile/chat/red_icon.png" alt="" class="redBag redBagAni2">
        <div class="redBagTxt1 redBagTxtLoopAni">
          <!-- <div class="mask"></div> -->
        </div>
        <div class="redBagTxt2 redBagTxtLoopAni">
          <!-- <div class="mask"></div> -->
        </div>
      </div>
      <div v-if="redBag.state == 1" class="closeBtn" @click='closeRedBagFix(redBag.state)'>
        <i class="iconfont icon-icon_close_24"></i>
      </div>
    </div>

    <div v-if="showEmoji" class="emoji-layout">
      <div>
        <p class="group">Smileys &amp; Emotion</p>
        <span v-for="( item, index ) in  chatEmojiList " class="item" @click="chooseEmoji(item)">{{ item }}</span>
      </div>
    </div>
    <div v-if="settingShow" class="setting">
      <img v-if="$tools.isNative()" :src="uploadObj.localUrl" @click="openCamare()" alt="">
      <div class="header">
        <van-nav-bar title="Cài đặt" left-arrow @click-left="settingShow = false">
        </van-nav-bar>
      </div>
      <div class="list bottomBorder">
        <div class="listLeft">Cấp độ của bạn</div>
        <div class="listRight">
          <img v-if="info.VipLevel > 0" :src="require('@/assets/common/vip/vip' + info.VipLevel + '_1.png')" alt="" class="levelImg">
        </div>
      </div>
      <div class="list bottomBorder">
        <div class="listLeft">Hiển thị đơn cược của tôi</div>
        <div class="listRight">
          <van-switch v-model="showMybet" @change="myBetSwitchChange" active-color="rgb(49, 214, 74)" inactive-color="rgb(58, 64, 89" size="18px" />
        </div>
      </div>
      <div class="list bottomBorder">
        <div class="listLeft">Chặn tất cả cược</div>
        <div class="listRight">
          <van-switch v-model="stopAllBet" @change="allBetSwitchChange" active-color="rgb(49, 214, 74)" inactive-color="rgb(58, 64, 89)" size="18px" />
        </div>
      </div>
    </div>
  </div>
  <div class="pinned-dialog">
    <van-dialog v-model:show="pinnedObj.showDialog" :showConfirmButton="false" :close-on-click-overlay="true" title="Ghim lên đầu" @cancel="uploadObj.show = false">
      <div class="img-wp">
        <img :src="pinnedObj.data.appendInfo.normalImg" />
      </div>
      <div class="msg">
        <span>
          {{ pinnedObj.data.content }}
        </span>
      </div>

    </van-dialog>
  </div>
  <div class="upload-dialog">
    <van-dialog v-model:show="uploadObj.showDialog" :close-on-click-overlay="true" title="Xem trước hình ảnh" show-cancel-button confirm-button-text="Gửi hình ảnh" cancel-button-text="Huỷ" @cancel="uploadObj.show = false" @confirm="upladFile">
      <img class="upload-img" :src="uploadObj.localUrl" />
      <van-field class="upload-input" v-model="uploadObj.input" placeholder="Thêm hình ảnh mô tả" />
    </van-dialog>
  </div>
  <div class="bet-info-dialog">
    <van-dialog v-model:show="betInfoObj.showDialog" :close-on-click-overlay="true" :showConfirmButton="false">
      <div class="orderPopup">
        <div class="header"> Thông tin cược </div>
        <div class="body">
          <div class="item">
            <span>{{ getLotteyName(betInfoObj.data) }}</span>
          </div>
          <div class="item">
            <span>{{ getLotteyPlay(betInfoObj.data) }}</span>
          </div>
          <div class="item">
            <table class="table">
              <thead>
                <tr>
                  <th>Nội dung cá cược</th>
                  <th>Mỗi đơn cược</th>
                </tr>
              </thead>
              <tr>
                <td class="item1" style="word-break: break-all;">
                  {{ getLotteyType(betInfoObj.data.Code) }}</td>
                <td class="item2">
                  <van-field v-model="betInfoObj.money" />
                </td>
              </tr>
            </table>
          </div>
          <div class="item">
            <span>Số đặt cược</span>
            <div class="info">{{ getLotteyBetCount(betInfoObj.data.Code) }}</div>
          </div>
          <div class="item">
            <span>Tổng tiền cược</span>
            <div class="info">{{ betInfoObj.money }}</div>
          </div>
        </div>
        <div class="footer">
          <span class="btn cencel" @click="betInfoObj.showDialog = false">Hủy
            bỏ</span>
          <span class="btn confim" @click="betLottey('betInfo')">Cá cược</span>
        </div>
      </div>
    </van-dialog>
  </div>
  <div class="bet-type-dialog">
    <van-dialog v-model:show="betTypeObj.showDialog" :close-on-click-overlay="true" :showConfirmButton="false">
      <div class="orderPopup">
        <div class="header"> Thông tin cược </div>
        <div class="body">
          <div class="item">
            <span class="gameLable">Tên trò chơi:</span>
            <span class="gameTitle">{{ getLotteyName(betTypeObj.data) }}</span>
          </div>
          <div class="item">
            <span class="gameLable">Kiểu cược:</span>
            <span class="gameTitle">{{ getLotteyPlay(betTypeObj.data) }}</span>
          </div>
        </div>
        <div class="trendBox">
          <div class="kenoTableData whiteBg">
            <div class="kenoTableTitle">
              <div class="titles circle0">
                <span class="lable" style="background: rgb(249, 65, 104);">{{
                                    getBetTypeText(betTypeObj.data)[0] }}</span>
                <van-circle v-model:current-rate="betTypeObj.currentRate1" :rate="getLotteyPersent(betTypeObj.data.Code == 'big' ? 0
                                    : 2)" :speed="100" :stroke-width="90" color="#f74168" :text="`${getLotteyPersent(betTypeObj.data.Code == 'big' ? 0
        : 2)}%`" />
              </div>
              <div class="titles circle1">
                <span class="lable" style="background: rgb(38, 161, 255);">{{
                                    getBetTypeText(betTypeObj.data)[1] }}</span>
                <van-circle v-model:current-rate="betTypeObj.currentRate2" :rate="getLotteyPersent(betTypeObj.data.Code == 'small' ? 0
                                    : 3)" :speed="100" :stroke-width="90" color="#26a1ff" :text="`${getLotteyPersent(betTypeObj.data.Code == 'small' ? 0
        : 3)}%`" />
              </div>
            </div>
            <div class="tableData">
              <div class="tableItem">
                <div v-for="(  item, index  ) in   18  " class="tableDataList">
                  <div v-for="(  item1, index1  ) in   betTypeObj.betTypeData[index]  " class="list">
                    <span v-if="item1 == 'big'" class="items" style="border-color: rgb(249, 65, 104); background: rgb(249, 65, 104);">{{
                                                getBetTypeText(betTypeObj.data)[2] }}
                    </span>
                    <span v-else class="items" style="border-color: rgb(38, 161, 255); background: rgb(38, 161, 255);">{{
                                                getBetTypeText(betTypeObj.data)[3] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div class="playOdd">
          <div class="playOddList">
            <div class="titles">
              <div class="items" :class="betTypeObj.chooseIndex == 0 ? 'active' : ''" @click="betTypeObj.chooseIndex = 0">
                <span class="lable">{{ getBetTypeText(betTypeObj.data)[0] }}</span>
                <span class="odd">{{ getLotteyOdds(betTypeObj.data) }}</span>
              </div>
            </div>
            <div class="titles">
              <div class="items" :class="betTypeObj.chooseIndex == 1 ? 'active' : ''" @click="betTypeObj.chooseIndex = 1">
                <span class="lable">{{ getBetTypeText(betTypeObj.data)[1] }}</span>
                <span class="odd">{{ getLotteyOdds(betTypeObj.data) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="timeIssue">
          <!-- <div class="timeCountDown"> -->
          <!-- <i class="iconfont icon-icon_alarm" style="display: none;"></i> -->
          <!-- <span data-v-6550df68=""> 00: 00: 36 </span> -->
          <!-- <span class="close">Đang đóng kèo</span> -->
          <!-- </div> -->
          <div class="issueNum">
            <span>Kỳ:</span>
            <span>{{ betTypeObj.data.Number }}</span>
          </div>
        </div>
        <div class="footer">
          <div class="btn cencel" @click="betTypeObj.showDialog = false">
            Huỷ
          </div>
          <div class="price">
            <van-field v-model="betTypeObj.money" />
          </div>
          <div class="btn confim colse" @click="betLottey('betType')">Đặt cược</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { mqant_lottery } from "@/components/protocol/mqantlib";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import md5 from 'js-md5'
import drag from '@/directives/drag'
import { req, qs } from "@/utils/request";
import ComonRightInfoPop from "@/views/mobile/components/ComonRightInfoPop.vue"
import { Toast, ImagePreview } from "vant";
import native from "@/components/core/native";
import { ElMessage } from "element-plus";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { uuid } = useGetters("global", ["uuid"]);
let { isConnect } = useGetters("tcp_lottery", ["isConnect"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { info } = useGetters("tcp", ["info"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const { Lotteries, Plays, isGetConfig } = useGetters("tcp_lottery", ["Lotteries", "Plays", "isGetConfig"]
);
const { chatList, pinnedList } = useGetters("chat", ["chatList", "pinnedList"]);
const { redBag } = useGetters("chat", ["redBag"]);
const { setRedDot } = useMutations("chat", ["setRedDot"]);
const { chatConf } = useGetters("tcp", ["chatConf"]);
let commitObj = reactive({ scene: 'chatRoom/zbet', filename: "" });
const msgType = reactive({
  MSG_TEXT: "MSG_TEXT",      // 文字消息
  MSG_IMAGE: "MSG_IMAGE",   // 图片消息
  MSG_REDEVNELOP: "MSG_REDEVNELOP", // 红包推送
  MSG_REDEVNELOP_UPDATE: "MSG_REDEVNELOP_UPDATE",//红包更新
  MSG_LOTTERY_BET: "MSG_LOTTERY_BET",  // 下注消息
  MSG_LOTTERY_PLAY_RECOMMEND: "MSG_LOTTERY_PLAY_RECOMMEND", // 推荐下注玩法
  MSG_LOTTERY_PLAY: "MSG_LOTTERY_PLAY",// 玩法推荐
  MSG_REDEVNELOP_TIP: "MSG_REDEVNELOP_TIP"//红包消息
})
let showRightPop = ref(false);
//隐藏导航栏
setHomeFooter(false);
//表情
let showEmoji = ref(false);
const chatEmojiData = require("@/utils/emoji.json");
const chatEmojiList = reactive([]);
for (let i in chatEmojiData) {
  chatEmojiList.push(chatEmojiData[i].char);
}
//跑马灯
let noticeObj = reactive({
  content: ['✅Chào mừng quý khách đến với Zbet668 🎁Giật lì xì mỗi ngày tại phòng chat lên đến 6,8 Tỷ VND']
})
// if (localStorage.getItem('localMybetState') == null) {
//     //第一次未存储时默认为打开
//     localStorage.setItem('localMybetState', true);
// }
let settingShow = ref(false);
const showMybet = ref(chatConf.value.shareLotteryBet);
const stopAllBet = ref(JSON.parse(localStorage.getItem('stopAllbetState')));

const fff = ref('');
//置顶图片
let pinnedObj = reactive({
  show: true,//默认打开，通过pinnedList来控制显示
  showDialog: false,
  data: {}
})

//上传图片
let uploadObj = reactive({
  url: "https://f.88bet88bet.com/group1/upload",
  showDialog: false,
  disabled: false,
  input: '',
  localUrl: '',
  severFile: '',
  type: '',
})
//下注信息
let betInfoObj = reactive({
  showDialog: false,
  data: {},
  money: '',
})
//下注大小单双信息
let betTypeObj = reactive({
  showDialog: false,
  data: {},
  betTypeData: [],
  doubleSingleData: [],
  money: '',
  chooseIndex: 0,
  currentRate1: 0,
  currentRate2: 0,
})
//滚动底部
let scroollObj = reactive({
  isScrollToBottom: true,//默认滚动到底部
  showBottonBtn: false,//下拉按钮
})

onMounted(() => {
  setRedDot(false);
})
onBeforeUnmount(() => {
  setHomeFooter(true);
  setRedDot(true);
  globelTimeDown && clearInterval(globelTimeDown);
})
bus.off(bus.event.closeCommonRightPop);
bus.on(bus.event.closeCommonRightPop, () => {
  showRightPop.value = false;
})
bus.off(bus.event.chatListPush);
bus.on(bus.event.chatListPush, () => {
  nextTick(() => {
    refreshListAll();
    scrollToBottom();
  })
  if (!scroollObj.isScrollToBottom) return;
})
//红包
let redBagObj = reactive({
  show: false
});
bus.off(bus.event.chatNewRedbag);
bus.on(bus.event.chatNewRedbag, () => {
  redBagObj.show = false;
  // Toast.success('Tiền lì xì đây');
})
const loading = ref(true);
//彩票配置
let lottryConfig = {};
let recordData = [];
let globelTimeDown = null;
//移动端需要判断一下彩票配置是否已拉取成功
// if (curRouter.path == '/chat') {
//     bus.on('getLottery', () => {
//         //获取到彩票票后才能显示
//         getLottryConfig();
//     })
// } else {
//     if (isConnect.value) {
//         getLottryConfig();
//     }
// }
if (isGetConfig.value) {
  getLottryConfig();
} else {
  bus.on('getLottery', () => {
    //获取到彩票票后才能显示
    getLottryConfig();
  })
}

function setHomeFooter(bool) {
  bus.emit(bus.event.showFooter, bool);
}
globelTimeDown = setInterval(() => {
  for (let key in chatList.value) {
    let info = chatList.value[key];
    if (info.msgType == msgType.MSG_LOTTERY_PLAY_RECOMMEND) {
      let nowTime = new Date().getTime();
      let openTime = new Date(info.appendInfo.OpenTime).getTime();
      if (openTime - nowTime < 0) {
        info.appendInfo.lessTime = -1;
      } else {
        info.appendInfo.lessTime = openTime - nowTime;
      }
    }
  }
}, 1000)
function isSystem(fromUid) {
  return (fromUid == '' || fromUid == '000000000000000000000000');
}
function getLottryConfig() {
  loading.value = false;
  nextTick(() => {
    refreshListAll();
    scrollToBottom(true);
  })
}
function getLotteyName(item) {
  if (Object.keys(Lotteries.value).length <= 0) return;
  let seachList = Lotteries.value[item.AreaCode];
  let desObj = seachList.find(e => e.LotteryCode == item.LotteryCode)
  return desObj.LotteryName;
}
function getLotteyPlay(item) {
  if (Object.keys(Plays.value).length <= 0) return;
  let payStr1 = '';
  let payStr2 = '';
  let seachList = Plays.value[item.AreaCode];
  let desObj = seachList.find(e => e.PlayCode == item.PlayCode);
  payStr1 = `[${desObj.Name}]`
  let subPlayList = desObj.SubPlays;
  let desObj2 = subPlayList.find(e => e.SubPlayCode == item.SubPlayCode);
  payStr2 = desObj2.SubName;
  return payStr1 + payStr2;
}
function getLotteyOdds(item) {
  if (Object.keys(Plays.value).length <= 0) return;
  let seachList = Plays.value[item.AreaCode];
  let desObj = seachList.find(e => e.PlayCode == item.PlayCode);
  let subPlayList = desObj.SubPlays;
  let desObj2 = subPlayList.find(e => e.SubPlayCode == item.SubPlayCode);
  return desObj2.Odds / 1000;
}
function getLotteyType(item) {
  if (item == "big") {
    return "Tài";
  } else if (item == "small") {
    return "Xỉu";
  } else if (item == "single") {
    return "Lẻ";
  } else if (item == "pair") {
    return "Chẵn";
  } else if (item == "up") {
    return "Trên";
  } else if (item == "draw") {
    return "Hoà";
  } else if (item == "down") {
    return "Dưới";
  } else if (item == "jin") {
    return "Kim";
  } else if (item == "mu") {
    return "Mộc";
  } else if (item == "shui") {
    return "Thủy";
  } else if (item == "huo") {
    return "Hỏa";
  } else if (item == "tu") {
    return "Thổ";
  } else if (item == "bigSingle") {
    return "Tài Lẻ";
  } else if (item == "smallSingle") {
    return "Xỉu Lẻ";
  } else if (item == "bigPair") {
    return "Tài Chẵn";
  } else if (item == "smallPair") {
    return "Xỉu Chẵn";
  } else {
    return item;
  }

}

function getLotteyBetCount(item) {
  var regex = new RegExp('-', 'g');
  var result = item.match(regex);
  var count = !result ? 0 : result.length;
  return count + 1;
}
function openRedBag() {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  if (info.value.VipLevel < 1) {
    ElMessage.warning('cần vip1！');
    return;
  }
  redBagObj.show = true;
}
function closeRedBagFix(state) {
  redBagObj.show = false;
  //已领取过的弹窗关闭后不再显示
  if (state == 0 && state == 1) {
    redBag.value.show = false;
  }
}
function robRedBag() {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  if (info.value.VipLevel < 1) {
    ElMessage.warning('cần vip1！');
    return;
  }
  let params = {
    redEnvelopId: redBag.value.data.Oid,
  }
  log.info('robRedBag-params', params);
  mqant.request(topic.robRedEnvelop, params, function (data, topicName, msg) {
    log.info('robRedEnvelop--', data);
    if (data.Code == 0) {
    } else {
      Toast.fail(data.ErrMsg);
    }
  })
}
function scrollToBottom(bool = false) {
  nextTick(() => {
    let bodyElement = document.getElementById('bodyId');
    if (!bodyElement) return;
    if (bool) {
      bodyElement.scrollTo({ top: bodyElement.scrollHeight });
    } else {
      bodyElement.scrollTo({ top: bodyElement.scrollHeight, behavior: 'smooth' });
    }
  })
}
function bodyScrollHandler(event) {
  // log.info(event.target.scrollTop);
  // if (event.target.scrollTop > 150) {
  //     scroollObj.isScrollToBottom = false;
  //     scroollObj.showBottonBtn = true;
  // }
}

function setLiFlag(item) {
  //做一下下注单的标记，方便隐藏或显示
  let resultStr = 'normal';
  if (item.msgType == msgType.MSG_LOTTERY_BET) {
    resultStr = msgType.MSG_LOTTERY_BET;
  }
  return resultStr;
}
function isSelf(item) {
  let nickName = item.fromUser.NickName
  return nickName === userInfo.value.Account;
}
function formatName(name) {
  if (!name || name.length < 2) {
    return name;
  }
  if (name.length == 2) {
    return '*' + name[name.length - 1];
  }
  return name[0] + new Array(name.length - 1).join('*') + name[name.length - 1];
}

function myBetSwitchChange(event) {
  if (info.value.VipLevel < 1) {
    showMybet.value = !showMybet.value;
    Toast.fail('cần vip1！');
    return;
  }
  let params = {
    showLotteryBet: event
  }
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  mqant.request(topic.chatChangePush, params, function (data, topicName, msg) {
    if (data.Code == 0) {
      chatConf.value.shareLotteryBet = event;
    }
    else {
    }
  })
}
function allBetSwitchChange(event) {
  localStorage.setItem('stopAllbetState', event);
  refreshListAll();
  scrollToBottom(true);
}

function refreshListAll(init = false) {
  let element = document.getElementById('chatLogListId');
  if (!element) return;
  for (let i = 0; i < element.children.length; i++) {
    let info = element.children[i];
    let liFlag = info.firstChild;
    if (stopAllBet.value) {
      if (liFlag.outerText && liFlag.outerText == msgType.MSG_LOTTERY_BET) {
        info.style.display = 'none';
      } else {
        info.style.display = 'block';
      }
    } else {
      info.style.display = 'block';
    }
  }
}

function toUrl(url) {
  window.open(url);
}

function close() {
  router.go(-1);
}
function send(msgType, content, normalImg = '') {
  let params = {
    groupId: "chatRoom",
    msgType: msgType,
    content: content,
    msgId: md5(uuid + Date.parse(new Date()).toString()),
    appendInfo: {
      normalImg: normalImg,
    }
  }
  if (tools.HTTPIsBoolean(content)) {
    Toast.fail('Vui lòng nhập nội dung hợp lệ');
    return;
  }
  // log.info('chat-params=----', params);
  mqant.request(topic.chatSend, params, function (data, topicName, msg) {
    if (data.Code == 0) {

    } else {
      Toast.fail(data.ErrMsg);
    }
    // log.info('chat-data=----', data);
  })
}
function sendMsg() {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  if (info.value.VipLevel < 1) {
    Toast.fail('cần vip1！');
    return;
  }
  let textElement = document.getElementById('ed-input');
  let textStr = textElement.innerHTML;
  if (!textStr) {
    Toast.fail('Vui lòng nhập nội dung！');
    return;
  }
  send(msgType.MSG_TEXT, textStr);
  textElement.innerHTML = ''
}

function chooseEmoji(item) {
  let textElement = document.getElementById('ed-input');
  textElement.innerHTML += item;
  showEmoji.value = false;
}

function openUpload() {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  uploadObj.disabled = false;
  if (info.value.VipLevel < 2) {
    Toast.fail('cần vip2！');
    uploadObj.disabled = true;
    setTimeout(() => {
      uploadObj.disabled = false;
    }, .3);
    // isLogged.value && info.value.VipLevel >= 2,
    return;
  }
}

function uploadToServe() {
  send(msgType.MSG_IMAGE, uploadObj.input, uploadObj.severUrl);
  uploadObj.input = '';
  uploadObj.showDialog = false;
}

function upladFile() {
  let form = new FormData(); // FormData 对象
  form.append("file", uploadObj.severFile); // 文件对象
  form.append("scene", commitObj.scene); // 文件对象
  form.append("filename", md5(commitObj.filename)); // 文件对象
  let xhr = new XMLHttpRequest();  // XMLHttpRequest 对象
  xhr.open("post", uploadObj.url, true); //post方式，url为服务器请求地址，true 该参数规定请求是否异步处理。
  xhr.onload = uploadComplete; //请求完成
  xhr.onerror = uploadFailed; //请求失败
  // xhr.upload.onprogress = progressFunction;//【上传进度调用方法实现】
  // xhr.upload.onloadstart = function () {//上传开始执行方法
  //     ot = new Date().getTime();   //设置上传开始时间
  //     oloaded = 0;//设置上传开始时，以上传的文件大小为0
  // };
  xhr.send(form); //开始上传，发送form数据
}

function uploadComplete(evt) {
  let url = evt.target.responseText;
  if (info.value.VipLevel < 2) {
    Toast.fail('cần vip2！');
    return;
  }
  send(msgType.MSG_IMAGE, uploadObj.input, url);
  uploadObj.input = '';
  uploadObj.showDialog = false;
}

function uploadFailed() {
  Toast.fail('Tải lên không thành công');
}

const afterRead = (file) => {
  // 此时可以自行将文件上传至服务器
  uploadObj.localUrl = file.content;
  uploadObj.severFile = file.file;
  commitObj.filename = file.file.name;
  uploadObj.showDialog = true;
};

bus.off(bus.event.openCameraCallBack);
bus.on(bus.event.openCameraCallBack, (data) => {
  log.info('openCameraCallBack data-------------', data);
  // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwA…lYEf2uPdqfCzXFFNz94v5A/IOJyufQ6yQAAAAAElFTkSuQmCC
  let urlBase64 = "data:image/png;base64," + data.result;
  let path = data.path;
  // 将 Base64 编码的图片数据转换为 Blob 对象
  const blob = base64ToBlob(urlBase64);
  // 创建 File 对象
  const file = new File([blob], "uploaded_image.jpg", { type: "image/jpeg" });
  uploadObj.localUrl = urlBase64;
  uploadObj.severFile = file;
  commitObj.filename = file.name;
  uploadObj.showDialog = true;
})
function openCamare() {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  if (info.value.VipLevel < 2) {
    Toast.fail('cần vip2！');
    return;
  }
  native.openCamera();
  log.info('调用了前端openCamera')
}

function base64ToBlob(base64) {
  // 移除 Base64 编码的前缀
  const base64WithoutPrefix = base64.split(",")[1];
  const byteString = atob(base64WithoutPrefix);
  // 转换为 Uint8Array
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  // 创建 Blob 对象
  const blob = new Blob([uint8Array], { type: "image/png" });
  return blob;
}

const onOversize = (file) => {
  Toast.fail('Kích thước ảnh chân dung không thể vượt quá nửa triệu!');
};

function showImage(url) {
  ImagePreview([url]);
}
function showBetInfoDialog(data) {
  betInfoObj.data = data;
  betInfoObj.money = data.TotalAmount;
  betInfoObj.showDialog = true;
}
async function showBetTypeDialog(data, index) {
  if (data.lessTime < 0) {
    Toast.fail('Hết hạn');
    return;
  };
  betTypeObj.data = data;
  betTypeObj.money = data.TotalAmount ? data.TotalAmount : '1000';
  betTypeObj.chooseIndex = index;
  await getLotteRecord(data);
  if (recordData.length <= 0) return;
  betTypeObj.betTypeData = changeTypeData(data, recordData);
  betTypeObj.showDialog = true;
}
function getBetTypeText(data) {
  let [title1, title2, title3, title4] = ['', '', '', ''];
  if (data.Code == "big" || data.Code == "small") {
    title1 = 'Tài';
    title2 = 'Xỉu';
    title3 = 'T';
    title4 = 'X';
  }
  else if (data.Code == "single" || data.Code == "pair") {
    title1 = 'Lẻ';
    title2 = 'Chẵn';
    title3 = 'L';
    title4 = 'C';
  }
  return [title1, title2, title3, title4];
}

function getLotteyPersent(type) {
  //0大 1小 2单 3双
  var arr = betTypeObj.betTypeData;
  var count = 0;
  var total = 0;

  for (var i = 0; i < (arr.length >= 16 ? 16 : arr.length); i++) {
    var tmpArr = arr[i];

    for (var j = 0; j < tmpArr.length; j++) {
      var str = tmpArr[j];
      total++;

      if (type == 0) {
        if (str == "big") {
          count++;
        }
      } else if (type == 1) {
        if (str == "small") {
          count++;
        }
      } else if (type == 2) {
        if (str == "single") {
          count++;
        }
      } else if (type == 3) {
        if (str == "double") {
          count++;
        }
      } else if (type == 4) {
        if (str == "top") {
          count++;
        }
      } else if (type == 5) {
        if (str == "center") {
          count++;
        }
      } else if (type == 6) {
        if (str == "bottom") {
          count++;
        }
      } else if (type == 7) {
        if (str == "jin") {
          count++;
        }
      } else if (type == 8) {
        if (str == "mu") {
          count++;
        }
      } else if (type == 9) {
        if (str == "shui") {
          count++;
        }
      } else if (type == 10) {
        if (str == "huo") {
          count++;
        }
      } else if (type == 11) {
        if (str == "tu") {
          count++;
        }
      }
    }
  }
  return Math.round((count / total) * 100);
}
function changeTypeData(data, recordData) {
  if (data.Code == 'big' || data.Code == 'small') {
    return getBigSmall(recordData)
  } else if (data.Code == 'single' || data.Code == 'pair') {
    return getDoubleSingle(recordData)
  }
}
function getBigSmall(data) {
  //>881大  1-40超过10个上 41-80>10个 下  和 1-40等于10
  var index = 0;
  var indexArr = [];
  var lastStr;
  var tmpArr = [];
  for (var i = 0; i < data.length; i++) {
    var total = 0;
    var arr = data[i].OpenCode.L0;

    for (var num of arr) {
      total += parseInt(num);
    }

    var str;
    if (total >= 811) {
      str = "big";
    } else {
      str = "small";
    }
    if (lastStr == str) {
      if (indexArr.length < 6) {
        indexArr.push(str);
        tmpArr[index] = indexArr;
      } else {
        indexArr = [];
        index++;
        indexArr.push(str);
        tmpArr[index] = indexArr;
      }
    } else {
      indexArr = [];
      if (lastStr) {
        index++;
      }
      indexArr.push(str);
      tmpArr[index] = indexArr;
    }
    lastStr = str;
  }

  if (tmpArr.length > 18) {
    tmpArr = tmpArr.slice(0, 18);
  }
  var arrs = [];
  for (var i = tmpArr.length - 1; i >= 0; i--) {
    arrs.push(tmpArr[i]);
  }

  return arrs;
}
function getDoubleSingle(data) {
  var index = 0;
  var indexArr = [];
  var tmpArr = [];

  var lastStr;
  for (var i = 0; i < data.length; i++) {
    var total = 0;
    var arr = data[i].OpenCode.L0;
    for (var num of arr) {
      total += parseInt(num);
    }

    var str;
    if (total % 2 == 0) {
      str = "double";
    } else {
      str = "single";
    }
    if (lastStr == str) {
      if (indexArr.length < 6) {
        indexArr.push(str);
        tmpArr[index] = indexArr;
      } else {
        indexArr = [];
        index++;
        indexArr.push(str);
      }
    } else {
      indexArr = [];
      if (lastStr) {
        index++;
      }
      indexArr.push(str);
    }
    tmpArr[index] = indexArr;

    lastStr = str;
  }
  if (tmpArr.length > 16) {
    tmpArr = tmpArr.slice(0, 16);
  }
  var arrs = [];
  for (var i = tmpArr.length - 1; i >= 0; i--) {
    arrs.push(tmpArr[i]);
  }
  return arrs;
}
function getLotteRecord(data) {
  return new Promise((resolve, reject) => {
    let obj = {
      LotteryCode: data.LotteryCode,
      limit: 100,
    };
    recordData = [];
    mqant_lottery.request(topic.record, obj, function (data, topicName, msg) {
      if (data.Code == 0) {
        recordData = data.Data;
        resolve(true);
      }
      else {
        reject();
      }
    })
  })
}
function betLottey(type) {
  let targetObj = {}
  if (type == 'betInfo') {
    targetObj = betInfoObj;
  } else if (type == 'betType') {
    targetObj = betTypeObj;
  }
  if (Number(targetObj.money) <= 0) {
    ElMessage.warning('Vui lòng nhập số tiền hợp lệ');
    return;
  }
  if (Number(targetObj.money) < targetObj.data.UnitBetAmount) {
    ElMessage.warning('Đặt cược tối thiểu' + targetObj.data.UnitBetAmount);
    return;
  }
  let obj = {
    TotalAmount: Number(targetObj.money),
    LotteryCode: targetObj.data.LotteryCode,
    SubPlayCode: targetObj.data.SubPlayCode,
    Code: targetObj.data.Code,
    UnitBetAmount: type == 'betType' ? Number(targetObj.money) : targetObj.data.UnitBetAmount,
    CProfit: targetObj.data.CProfit,
  }
  if (type == 'betType') {
    if (obj.Code == 'big' || obj.Code == 'small') {
      if (betTypeObj.chooseIndex == 0) {
        obj.Code == 'big';
      } else {
        obj.Code == 'small';
      }
    } else if (obj.Code == 'single' || obj.Code == 'pair') {
      if (betTypeObj.chooseIndex == 0) {
        obj.Code == 'single';
      } else {
        obj.Code == 'pair';
      }
    }
  }
  log.info("addBet2-obj--", obj);
  mqant_lottery.request(topic.addBet2, obj, function (data, topicName, msg) {
    if (data.Code == 0) {
      Toast.success(data.ErrMsg);
      betInfoObj.showDialog = false;
      betTypeObj.showDialog = false;
    }
    else {
      Toast.fail(data.ErrMsg);
    }
  });
}

function pinnedItemClick(item) {
  pinnedObj.data = item;
  pinnedObj.showDialog = true;
}
</script>

<style lang="scss" scoped>
.chatroom {
  position: relative;
  height: 100%;
  background: #1e2747;

  .van-nav-bar {
    position: relative;
    z-index: 1;
    line-height: 5.866667vw;
    text-align: center;
    background-color: #293356;
    -webkit-user-select: none;
    user-select: none;

    :deep(.van-icon) {
      color: #fff;
    }
  }

  .van-hairline--bottom:after {
    border-bottom-width: 0px;
  }

  :deep(.van-nav-bar__title) {
    max-width: 60%;
    margin: 0 auto;
    color: #fff;
    font-weight: 500;
    font-size: 4.266667vw;
  }

  :deep(.van-nav-bar__content) {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 50px;
  }

  .chatroom-header {
    .rightIcon {
      font-size: 6.4vw;
      color: #fff;
    }

    .icon-icon_sidebar {
      margin-left: 4vw;
    }
  }

  .pinned {
    position: absolute;
    width: 100%;
    z-index: 999;

    .pinned-msg-bar {
      display: flex;
      height: 50px;
      font-size: 12px;
      overflow: hidden;
      box-sizing: border-box;
      opacity: 0.9;
      cursor: pointer;
      background: linear-gradient(105deg, #7146ff, #4a69ff);

      .label {
        padding-left: 15px;
        display: flex;
        align-items: center;
        width: 70px;
        color: #e9cfa4;
        font-weight: 400;
      }

      .msg-wp {
        display: flex;
        align-items: center;
        width: calc(100% - 100px);
        overflow-x: auto;
        overflow-y: hidden;

        .msg-item-wp {
          margin-right: 10px;

          .img-wp {
            display: flex;
            align-items: center;
            padding: 5px 0;

            img {
              width: 34px;
              height: 34px;
              border-radius: 4px;
              margin-right: 4px;
            }
          }
        }

        .icon-icon_close_24 {
          position: absolute;
          right: 8px;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }

  .chatroom-body {
    position: absolute;
    width: 100%;
    top: 50px;
    bottom: 56px;
    overflow-y: auto;

    .loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ul {
      padding-bottom: 1.6vw;

      li {
        margin: 25px 0;

        .chatLogItem {
          padding: 0 20px;

          .msgItem {
            position: relative;
            display: flex;
            width: 100%;

            .avatar {
              width: 40px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              margin-top: 2px;

              > div {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                border: 1px solid hsla(0, 0%, 79.6%, 0.325);
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              img {
                max-width: 32px;
                max-height: 32px;
                width: auto;
                height: auto;
              }
            }

            .msgContent {
              position: relative;
              background: rgba(51, 255, 255, 0.1);
            }

            .level {
              border-radius: 8px;
              max-width: 260px;
              background: #34416d;
            }

            .level1 {
              background: rgba(51, 60, 102, 0.4);
            }

            .level2 {
              background: rgba(51, 255, 51, 0.1);
            }

            .level3 {
              background: rgba(51, 255, 255, 0.1);
            }

            .level4 {
              background: rgba(51, 119, 255, 0.1);
            }

            .level5 {
              background: rgba(255, 187, 51, 0.1);
            }

            .level6 {
              background: rgba(51, 85, 255, 0.1);
            }

            .level7 {
              background: rgba(255, 153, 51, 0.1);
            }

            .level8 {
              background: rgba(255, 119, 51, 0.1);
            }

            .level9 {
            }

            .nameImg {
              color: #8491a5;
              line-height: 22px;
              padding: 6px 20px 6px 6px;
              font-size: 14px;

              .levelImg {
                height: 22px;
                margin-right: 8px;
                vertical-align: middle;
              }

              .levelImgAngle {
                position: absolute;
                right: -15px;
                bottom: 0;
                width: 32px;
                height: 22px;
              }

              .levelImgAngleGl {
                right: -11px;
                width: 22px;
                height: 22px;
              }

              .nickname {
                color: #fff;
              }

              .message {
                color: #b3ffff;
                word-break: break-all;
                word-wrap: break-word;
                font-size: 12px;
                text-align: left;
              }

              span {
                vertical-align: middle;
              }
            }

            .van-img {
              border-radius: 0 2.133333vw 2.133333vw 2.133333vw;
              overflow: hidden;
              display: inline-block;
              padding: 0 2.666667vw;

              :deep(.van-image) {
                position: relative;
                display: inline-block;

                .van-image__img {
                  font-size: 5.333333vw;
                  border-radius: 2.133333vw;
                  display: block;
                  width: 100% !important;
                  max-height: 140px;
                }
              }
            }

            .shareOrder {
              display: inline-block;
              width: 100%;

              .orderItem {
                background: #35416d;
                color: #fff;
                border-radius: 0 0 8px 8px;
                font-size: 12px;
                display: flex;
                justify-content: space-around;
                flex-direction: column;

                .itemWrap {
                  padding: 10px;

                  .item {
                    display: flex;
                    align-items: center;
                    min-height: 20px;
                  }

                  .itemContent {
                    span {
                      display: inline-block;
                    }

                    div {
                      flex: 1;
                      word-break: break-all;
                      text-align: left;
                    }
                  }

                  .greenText {
                    color: #21e06b;
                  }
                }
              }

              .betBtn {
                height: 7.466667vw;
                line-height: 7.466667vw;
                margin-top: 1.333333vw;
                background: #90a2dc;
                font-size: 3.733333vw;
                color: #fff;
                text-align: center;
                font-weight: 400;
                border-radius: 0 0 2.133333vw 2.133333vw;
              }
            }

            .time {
              color: rgba(132, 145, 165, 0.6);
              margin-top: 6px;
              position: absolute;
              bottom: -15px;
              left: 0;
              width: 100%;
            }

            p {
              letter-spacing: 0;
              line-height: 15px;
              font-weight: 400;
            }
          }

          .userMsg {
            flex-direction: row-reverse;

            .nameImg {
              flex-direction: row-reverse;
              border-radius: 8px 0 8px 8px;
              padding: 6px;
            }
          }
        }

        .changLong {
          margin: 6px 0;

          .chatLogItem {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 !important;

            .avatar {
              width: 32px;
              align-self: flex-start;
              padding-top: 4px;

              img {
                width: 32px;
                height: 32px;
                border-radius: 16px;
              }
            }

            .main {
              padding: 0 8px;
              display: inline-block;
              width: 100%;

              .title {
                color: #8491a5;
                font-size: 12px;
                display: flex;
                align-items: center;
                letter-spacing: 0;
                line-height: 20px;
                margin-bottom: 4px;
                font-weight: 400;

                .lotteyName {
                  height: 20px;
                  line-height: 20px;
                  margin-right: 5px;
                }
              }

              .logItem {
                background: #363d4e;
                max-width: 264px;
                word-wrap: break-word;
                border-radius: 0 8px 8px 8px;
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 14px;
                line-height: 20px;
                height: 146px;
                width: 264px;

                .playName {
                  background: #8491a5;
                  display: flex;
                  align-items: center;
                  height: 30px;
                  width: 100%;
                  border-radius: 0 8px 0 0;
                  padding: 0 12px;

                  .names {
                    font-size: 12px;
                    color: #292e3b;
                  }

                  .plays1 {
                    border: 0.83px solid #fd3;
                    border-radius: 10px;
                    font-size: 10px;
                    color: #ffd60a;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    padding: 0 10px;
                    margin-left: 5px;
                  }
                }

                .logItemInfo {
                  padding: 0 12px;
                  display: flex;
                  flex: 1;
                  justify-content: space-between;
                  align-items: center;

                  .openInfo {
                    background: #292e3b;
                    height: 54px;
                    background: #1e2747;
                    border-radius: 10px;
                    width: 120px;
                    display: flex;
                    padding: 5px;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;

                    .openIssue {
                      font-size: 19px;
                      color: #ffd60a;
                    }

                    .openState {
                      font-size: 13px;
                      color: #ffd60a;
                      opacity: 0.7;
                    }
                  }

                  .timeInfo {
                    background: #292e3b;
                    width: 102px;
                    height: 30px;
                    border: 1px solid #ff375f;
                    border-radius: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .close {
                      font-size: 12px;
                      color: #ff375f;
                    }

                    i {
                      margin-right: 5px;
                    }

                    .icon-icon_alarm {
                      font-size: 15px;
                      color: #ff375f;
                    }

                    span {
                      margin-left: 5px;
                    }
                  }
                }

                .playItem {
                  padding: 0 12px;
                  height: 40px;
                  border-top: 1px solid rgba(159, 180, 249, 0.1);
                  display: flex;
                  align-items: center;
                  justify-content: space-around;

                  .plays {
                    color: #fff;
                    background: #292e3b;
                    border-radius: 4px;
                    font-size: 14px;
                    min-width: 44px;
                    text-align: center;
                    opacity: 0.8;
                    cursor: pointer;
                  }

                  .plays:hover {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }

        .chatRedRecord {
          line-height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          font-size: 13px;

          .name {
            color: #fada2f;
          }

          .recordText2 {
            color: #8491a5;
          }

          .redRecordMoney {
            color: #f92855;
          }
        }
      }
    }
  }

  .chatroom-footer {
    width: 100%;
    position: absolute;
    bottom: 0;

    .chatInput {
      display: flex;
      height: 56px;
      align-items: center;
      background: #fff;

      :deep(.van-dialog__content) {
        text-align: center;
      }

      .left {
        width: 52px;
        text-align: center;

        .van-button--default {
          border: none;
        }

        span {
          color: #90a2dc;
          font-size: 5.333333vw;
        }
      }

      .mid {
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: auto;
        padding: 4px 0;

        #ed-input {
          padding: 0 2.666667vw;
          height: 8.533333vw;
          line-height: 8.533333vw;
          background: #f4f4f6;
        }

        .edit-div {
          overflow: auto;
          word-break: break-all;
          outline: none;
          user-select: text;
          white-space: pre-wrap;
          text-align: left;
          font-size: 3.733333vw;
          width: 100%;
          border-radius: 4.266667vw;
          color: #000;
        }

        .edit-div:empty:before {
          content: attr(placeholder);
          display: block;
          line-height: 8.533333vw;
          color: #33427b;
        }

        .icon-icon_send_emoji {
          font-size: 5.333333vw;
          color: #99a9de;
        }
      }

      .right {
        width: 66px;
        text-align: center;

        .van-button--normal {
          padding: 0 4vw;
          font-size: 3.733333vw;
        }

        .van-button--default {
          color: #323233;
          background-color: #fff;
          border: none;
        }

        :deep(.van-button__text) {
          color: #90a2dc;
        }
      }
    }
  }

  .redBagFix {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    z-index: 9;
    justify-content: center;

    .redWrap {
      position: relative;
      text-align: center;

      .redBag3 {
        width: 90%;
      }

      .redBagP {
        min-width: 175.1px;
        text-align: center;
        position: absolute;
        left: 26%;
        top: 24%;
        font-size: 18px;
        background: linear-gradient(180deg, #fff6bb, #ffe87c);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }

      .openBtn {
        cursor: pointer;
        width: 78px;
        height: 78px;
        position: absolute;
        left: 40%;
        bottom: 9%;
        transition: all 200s linear;

        span {
          color: #fff6bb;
          font-size: 30px;
          position: absolute;
          top: 24px;
          left: 18px;
          user-select: none;
        }

        .redBag2 {
          width: 100%;
          height: 100%;
        }
      }
    }

    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .item {
        width: 90%;
        position: relative;

        .faildDetail {
          top: 14% !important;
        }

        .detail {
          position: absolute;
          width: 100%;
          text-align: center;
          top: 11%;

          .title {
            width: 156px;
            margin-left: 94px;
            font-size: 16px;
            text-align: center;
            background: linear-gradient(180deg, #fff6bb, #ffe87c);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            font-weight: 600;
          }

          .money {
            margin-top: 10px;
            font-size: 26px;
            color: #fddfaf;
            text-align: center;
            line-height: 37px;
            font-weight: 600;
          }

          .faild {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }

      img {
        width: 100%;
      }
    }

    .ball {
      cursor: pointer;
      position: absolute;
      left: 44%;
      bottom: -18%;
      width: 47px;
      height: 47px;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      i {
        color: #fff;
        font-size: 22px;
      }
    }

    .errorBall {
      bottom: -54px;
      left: 146px;
    }
  }

  .redBagAniBox {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 230px;
    width: 90px;
    height: 90px;

    .aniBox {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;

      .fireworks {
        transform: scale(0);
        opacity: 1;
        position: absolute;
        width: 90px;
      }

      .fireworksAni {
        animation: fireworksAniKf 1s ease-in both;
      }

      @keyframes fireworksAniKf {
        100% {
          -webkit-transform: scale(0.8);
          transform: scale(0.8);
          opacity: 0;
        }
      }

      .redBagBg {
        position: absolute;
        width: 65px;
        opacity: 0;
      }

      .redBagBgAni {
        opacity: 1;
        animation: redBagBgAniKf 3s linear infinite both;
      }

      @keyframes redBagBgAniKf {
        100% {
          transform: rotate(1turn);
        }
      }

      .redBag {
        position: absolute;
        width: 50px;
      }

      .redBagAni2 {
        animation: redBagAni2Kf 3s ease infinite both;
      }

      @keyframes redBagAni2Kf {
        10% {
          -webkit-transform: scale(1.1) rotate(0);
          transform: scale(1.1) rotate(0);
        }

        12% {
          -webkit-transform: scale(1) rotate(0);
          transform: scale(1) rotate(0);
        }

        15% {
          -webkit-transform: scale(1.2) rotate(0);
          transform: scale(1.2) rotate(0);
        }

        20% {
          -webkit-transform: scale(1) rotate(-5deg);
          transform: scale(1) rotate(-5deg);
          -webkit-transform-origin: bottom;
          transform-origin: bottom;
        }

        40% {
          -webkit-transform: scale(1) rotate(5deg);
          transform: scale(1) rotate(5deg);
          -webkit-transform-origin: bottom;
          transform-origin: bottom;
        }

        60% {
          -webkit-transform: scale(1) rotate(-5deg);
          transform: scale(1) rotate(-5deg);
          -webkit-transform-origin: bottom;
          transform-origin: bottom;
        }

        80% {
          -webkit-transform: scale(1) rotate(5deg);
          transform: scale(1) rotate(5deg);
          -webkit-transform-origin: bottom;
          transform-origin: bottom;
        }
      }
    }

    .closeBtn {
      position: absolute;
      top: 1px;
      color: #ccc;
      right: 10px;
    }

    .redBagTxt1 {
      position: absolute;
      width: 260px;
      height: 90px;
      bottom: -20px;
      background-size: 260px 90px;
      transform: scale(0.28);
      overflow: hidden;
      background-image: url("@/assets/pc/chat/red_title_1.png");
    }

    .redBagTxtLoopAni {
      animation: redBagTxtLoopAniKf 1.5s linear infinite both;
    }

    @keyframes redBagTxtLoopAniKf {
      60% {
        transform: scale(0.29);
      }
    }

    .redBagTxt2 {
      position: absolute;
      width: 260px;
      height: 90px;
      bottom: -20px;
      background-size: 260px 90px;
      transform: scale(0.28);
      overflow: hidden;
      background-image: url("@/assets/pc/chat/red_title_2.png");
    }

    .mask {
      width: 90px;
      height: 85px;
      background-color: #fff;
      opacity: 0.5;
      transform: translateX(-90px);
      animation: maskAniKf 1s linear 2s infinite both;
    }

    @keyframes maskAniKf {
      100% {
        transform: translateX(160px);
      }
    }
  }

  .setting {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 4.266667vw;
    background-color: #293356;
    z-index: 1000;

    .header {
      height: 50px;

      :deep(.van-nav-bar__arrow) {
        margin-right: 1.066667vw;
        font-size: 5.333333vw;
      }
    }

    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 11.733333vw;
      color: #fff;
      position: relative;

      .listLeft {
        text-align: left;
        flex: 1 1 0%;
        font-size: 3.733333vw;
        font-weight: 400;
        color: #fff;
        height: 5.333333vw;
        line-height: 5.333333vw;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .listRight {
        display: flex;
        flex: 1 1 0%;
        justify-self: end;
        text-align: right;
        justify-content: flex-end;
        height: 5.333333vw;
        line-height: 5.333333vw;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .levelImg {
          height: 5.333333vw;
        }
      }
    }

    .list:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: rgba(144, 162, 220, 0.5);
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
  }

  .emoji-layout {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
    width: 340px;
    height: 410px;
    padding: 2px 4px;
    overflow: auto;
    background: #fff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);

    .group {
      padding: 5px 12px 0;
      color: #80868a;
      font-size: 14px;
    }

    .item {
      display: inline-block;
      width: 28px;
      height: 28px;
      padding: 4px;
      font-size: 28px;
      cursor: pointer;
      transition: all 0.3s;
    }

    .item:hover {
      transform: scale(1.5);
    }
  }
}

.pinned-dialog {
  :deep(.van-popup) {
    background-color: #fff !important;
    opacity: 0.95;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 2.133333vw;

    .van-dialog__content {
      max-height: 600px;
      overflow: auto;
      padding: 2.666667vw;
    }

    .van-dialog__header {
      padding-top: 0;
    }
  }

  .img-wp {
    text-align: center;

    img {
      max-width: 85.333333vw;
      max-height: 120vw;
      width: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  .msg {
    font-size: 3.733333vw;
    margin: 1.6vw 3.2vw;
    line-height: 4.266667vw;
    word-break: break-all;
    text-align: center;
  }
}

.upload-dialog {
  :deep(.van-popup) {
    background-color: #fff !important;
    opacity: 0.95;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 2.133333vw;
  }

  .upload-img {
    text-align: center;
    max-width: 100%;
    max-height: 80%;
  }

  .upload-input {
    padding: 0 5.333333vw;
    background: 0 0;
    text-align: center;
  }
}

.bet-info-dialog {
  :deep(.van-popup) {
    background-color: #fff !important;
    width: 96%;
    height: 350px;
    opacity: 0.95;
    background: #fbfbfb;
    border-radius: 2.133333vw;
  }

  .orderPopup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #1b233d;

    .header {
      text-align: center;
      line-height: 14.933333vw;
      height: 14.933333vw;
      width: 100%;
      font-size: 4.8vw;
      color: #1b233d;
      box-shadow: inset 0 -0.5px 0 0 rgba(144, 162, 220, 0.5);
    }

    .body {
      padding: 6.4vw;

      .item {
        font-size: 3.466667vw;
        color: #1b233d;
        font-weight: 400;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2.133333vw;

        .item1 {
          width: 60%;
        }

        span {
          margin-right: 4.266667vw;
        }

        .table {
          border: 1px solid #1b233d;
          border-radius: 1.066667vw;
          width: 100%;
          border-spacing: 0;

          thead {
            font-size: 3.466667vw;
            border-bottom: 1px solid #1b233d;
            color: #1b233d;

            tr {
              border-bottom: none;
            }

            tr:last-child td {
              border-bottom: none;
            }
          }

          td,
          th {
            border-right: 1px solid #1b233d;
            border-bottom: 1px solid #1b233d;
            padding: 2.666667vw;
            text-align: center;
            vertical-align: middle;
          }
        }

        .info {
          color: #21e06b;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0 6.4vw;
      margin-bottom: 6.4vw;

      .btn {
        flex: 1;
        height: 10.666667vw;
        line-height: 10.666667vw;
        border: 1px solid #1b233d;
        border-radius: 1.066667vw;
        font-size: 3.733333vw;
        color: #1b233d;
        text-align: center;
        font-weight: 400;
      }

      .confim {
        margin-left: 6.4vw;
        background-image: linear-gradient(90deg, #7146ff, #4a69ff);
        color: #fff;
        border: none;
      }
    }

    :deep(.van-cell) {
      height: 8.533333vw;
      line-height: 8.533333vw;
      border: 1px solid #1b233d;
      border-radius: 1.066667vw;
      padding: 0 1.333333vw;
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      color: #323233;
      font-size: 3.733333vw;
      background-color: #fff;

      .van-cell__value--alone {
        color: #323233;
        text-align: left;
      }

      .van-field__control {
        font-size: 3.733333vw;
        color: #90a2dc;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}

.bet-type-dialog {
  :deep(.van-popup) {
    background-color: #fff !important;
    width: 96%;
    height: 470px;
    opacity: 0.95;
    background: #fbfbfb;
    border-radius: 2.133333vw;
  }

  .orderPopup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #1b233d;

    .header {
      text-align: center;
      line-height: 14.933333vw;
      height: 14.933333vw;
      width: 100%;
      font-size: 4.8vw;
      color: #1b233d;
      box-shadow: inset 0 -0.5px 0 0 rgba(144, 162, 220, 0.5);
    }

    .body {
      padding: 3.2vw 3.2vw 0;

      .item {
        font-size: 15px;
        color: #1b233d;
        font-weight: 400;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;

        .gameLable {
          width: 100px;
          text-align: right;
        }

        span {
          margin-right: 16px;
        }
      }
    }

    .trendBox {
      text-align: center;
      padding: 0 2.666667vw;

      .kenoTableData {
      }

      .whiteBg {
        background: #fff;
      }

      .line {
        margin: 0 8vw;
        border-bottom: 1px solid rgba(144, 162, 220, 0.5);
      }

      .kenoTableTitle {
        display: flex;
        padding: 1.333333vw 3.2vw 0;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        :deep(.van-circle__text) {
          font-size: 12px;
        }

        .titles {
          display: flex;
          flex: 1;
          align-items: center;
          width: 30%;
          min-width: 30%;
          max-width: 30%;
          margin-bottom: 0;
          justify-content: center;

          .lable {
            background: #f94168;
            border: 1px solid;
            border-radius: 2px;
            font-size: 12px;
            color: #fff;
            text-align: center;
            padding: 5px;
            margin-right: 8px;
          }

          :deep(.van-circle) {
            position: relative;
            width: 9.333333vw;
            height: 9.333333vw;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }

      .tableData {
        padding: 5px 0;
        display: flex;
        justify-content: flex-end;

        .tableItem {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;

          .tableDataList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-left: 3px;
            flex: 1;

            .list {
              margin-bottom: 4px;
              display: flex;
              position: relative;

              .items {
                width: 16px;
                height: 16px;
                font-size: 10px;
                color: #fff;
                flex: 1;
                font-weight: 400;
                display: inline-block;
                text-align: center;
                line-height: 18px;
                position: relative;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .playOdd {
      .playOddList {
        display: flex;
        padding: 10px 12px 0;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;

        .titles {
          display: flex;
          flex: 1;
          align-items: center;
          width: 30%;
          height: 48px;
          min-width: 30%;
          max-width: 30%;
          margin-bottom: 16px;
          justify-content: center;

          .items {
            padding: 5px;
            display: flex;
            height: 48px;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            background: #9fb4f9;
            border-radius: 29px;
            border: 2px solid #c3d0fb;
            width: 100%;
            cursor: pointer;

            .lable {
              font-size: 16px;
              color: #fbfbfb;
              text-align: center;
            }

            .odd {
              font-size: 12px;
              color: #fbfbfb;
              text-align: center;
            }
          }

          .active {
            background-image: linear-gradient(135deg, #7146ff, #4a69ff);
            border: 2px solid #a891ff;
          }
        }
      }
    }

    .timeIssue {
      height: 40px;
      background: rgba(0, 0, 0, 0.05);
      display: flex;
      padding: 0 24px;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .timeCountDown {
        width: 102px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ff375f;
        border-radius: 15px;

        .icon-icon_alarm {
          font-size: 15px;
          color: #ff375f !important;
        }

        .close {
          font-size: 12px;
          color: #ff375f !important;
        }
      }

      .issueNum {
        font-size: 15px;
        color: #1b233d;
        // margin-left: 10px;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      margin-bottom: 24px;

      .btn {
        flex: 1;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #fbfbfb;
        text-align: center;
        font-weight: 400;
        width: 96px;
        background: #78849e;
        border-radius: 4px;
        cursor: pointer;
      }

      .price {
        flex: 2;
        width: 96px;
        margin: 0 10px;
      }

      .van-cell {
        height: 10.666667vw;
        line-height: 10.666667vw;
        border: 1px solid #1b233d;
        border-radius: 1.066667vw;
        padding: 0 1.333333vw;
      }

      :deep(.van-field__control) {
        font-size: 3.733333vw;
        color: #90a2dc;
        text-align: center;
        font-weight: 400;
      }

      .colse {
        background: #78849e;
      }

      .confim {
        width: 156px;
        background-image: linear-gradient(90deg, #7146ff, #4a69ff);
        color: #fff;
        border: none;
        flex: 2;
      }
    }
  }
}
</style>