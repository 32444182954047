<template>
  <div
    id="scrollDiv"
    v-if="columData"
    style="
      overflow: scroll;
     position:absolute;
     top:13.333333vw;
     left: 0;
     right: 0;
      background-color: #2f395f;
    "
    :style="
      showJS
        ? 'bottom:calc(13.333333vw + 292px);'
        : 'bottom:13.333333vw;'
    "
  >
    <topHeader :selectLo="columData"></topHeader>
    <div
      class="flexs_sb"
      style="
        background-color: #2f395f;
        padding: 0 4.266667vw;
        height: 24.533333vw;
        position: relative;
        box-shadow: inset 0 0 0 0 rgba(144, 162, 220, 0.3);
      "
    >
      <div class="flexs_r" style="width: 100%">
        <img
          :src="$img('lottery/' + columData.LotteryCode + '.png')"
          alt=""
          style="width: 72px"
        />
        <div style="margin-left: 14px; color: #aac5f2; width: 100%">
          <div class="flexs_sb">
            <div style="font-size: 4vw; color: #fff; font-weight: 700">
              {{ columData.LotteryName }}
            </div>
            <div
              style="
                width: 24.533333vw;
                line-height: 5.333333vw;
                text-align: center;
                font-size: 3.2vw;
                border-radius: 3.333333vw;
                color: #fff;
                background-image: linear-gradient(135deg, #70f570, #54e62e);
                box-shadow: 0 0 0.533333vw 0 rgba(0, 0, 0, 0.05);
              "
            >
              Đang mở kèo
            </div>
          </div>

          <div style="margin-top: 3px; height: 15px">
            <i v-if="recordData.length"> {{ getCurrentQi() }}&nbsp; </i>
          </div>

          <div class="flexs_sb" style="margin-top: 8px">
            <div
              class="flexs_r_c"
              style=""
              v-if="getBigTime().indexOf(':') >= 0"
            >
              <div class="num">{{ getBigTime().substr(0, 1) }}</div>
              <div class="num" style="">
                {{ getBigTime().substr(1, 1) }}
              </div>
              <div class="" style="color: white; font-size: 12.5px">:</div>
              <div class="num" style="">
                {{ getBigTime().substr(3, 1) }}
              </div>
              <div class="num" style="">
                {{ getBigTime().substr(4, 1) }}
              </div>
              <div class="" style="color: white; font-size: 12.5px">:</div>
              <div class="num" style="">
                {{ getBigTime().substr(6, 1) }}
              </div>
              <div
                class="num"
                :class="isRed() ? 'countDownWarning' : ''"
                style=""
              >
                {{ getBigTime().substr(7, 1) }}
              </div>
            </div>
            <div
              class="flexs_r_c"
              style=""
              v-if="getBigTime().indexOf(':') < 0"
            >
              <div class="" style="color: #aac5f2; font-size: 9px">
                {{ getBigTime() }}
              </div>
            </div>

            <div class="moreDataBox">
              <i
                class="iconfont icon-icon_lotteryinfo"
                @click="showInfo = true"
              ></i>
              <div class="dataBtn" @click.stop="showOrClose()">
                <span class="iconfont icon-icon_lorreryhistory"></span>
                Kết quả
                <span
                  class="iconfont icon-icon_drop_down_solid"
                  :class="showRecord ? 'isOpenList' : ''"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="line"></div>
    <div
      class="flexs_sb"
      v-if="columData.AreaCode == 'North'"
      style="height: 10.666667vw; padding: 0 4.266667vw"
    >
      <div
        style="
          font-size: 3.466667vw;
          width: 23.466667vw;
          color: white;
          text-align: center;
        "
      >
        Kỳ
      </div>
      <div class="flexs_r_c">
        <div
          class="kyBtn"
          style=""
          @click="kySelectIndex = 0"
          :class="kySelectIndex == 0 ? 'kySe' : 'kyNo'"
        >
          Số
        </div>
        <div
          class="kyBtn"
          style="margin-left: 15px"
          @click="kySelectIndex = 1"
          :class="kySelectIndex == 1 ? 'kySe' : 'kyNo'"
        >
          Tài Xỉu
        </div>
        <div
          class="kyBtn"
          style="margin-left: 15px"
          @click="kySelectIndex = 2"
          :class="kySelectIndex == 2 ? 'kySe' : 'kyNo'"
        >
          Chẵn lẻ
        </div>
        <div
          class="kyBtn"
          style="margin-left: 15px"
          @click="kySelectIndex = 3"
          :class="kySelectIndex == 3 ? 'kySe' : 'kyNo'"
        >
          Tổng
        </div>
      </div>
    </div>

    <div class="line"></div>
    <div
      id="recordList"
      :class="showRecord ? 'recordPlace' : 'recordNoOpen'"
      v-if="columData.AreaCode != 'Vip'"
    >
      <div
        v-for="(item, index) in recordData"
        @click="openInfo(recordData[index])"
        :style="index>0?'border-bottom: 1px solid rgba(144, 162, 220, 0.3);':''"
        style="
          height: 52px;
          
          padding: 0 4.266667vw;
          position: relative;
        "
        class="flexs_sb"
      >
        <div style="font-size: 11px; color: white; font-weight: 600">
          {{ recordData[index].Number }}
        </div>


        
        <div class="flexs_r_c"   >
          <div class="flexs_r_c" v-if="kySelectIndex==0">
            <div class="resultNum">
              {{ recordData[index].OpenCode.L0[0].substr(0, 1) }}
            </div>
            <div class="resultNum">
              {{ recordData[index].OpenCode.L0[0].substr(1, 1) }}
            </div>
            <div class="resultNum">
              {{ recordData[index].OpenCode.L0[0].substr(2, 1) }}
            </div>
            <div class="resultNum">
              {{ recordData[index].OpenCode.L0[0].substr(3, 1) }}
            </div>
            <div class="resultNum">
              {{ recordData[index].OpenCode.L0[0].substr(4, 1) }}
            </div>
            <div
              class="resultNum"
              v-if="
                columData.AreaCode == 'South' || columData.AreaCode == 'Central'
              "
            >
              {{ recordData[index].OpenCode.L0[0].substr(5, 1) }}
            </div>
          </div>
          <div class="flexs_r_c" v-else-if="kySelectIndex==1">
            <div  class="tag" style="margin-left: 2vw;" :class="parseInt(recordData[index].OpenCode.L0[0].substr(index1, 1)) > 5 ? 'redTag' : 'blueTag'"  v-for="(item1, index1) in recordData[index].OpenCode.L0[0].length" :key="item1">
              {{ parseInt(recordData[index].OpenCode.L0[0].substr(index1, 1)) > 5 ? 'Tài' : 'Xỉu' }}
             </div>
           
          </div>
          <div class="flexs_r_c" v-else-if="kySelectIndex==2">
            <div  class="tag" style="margin-left: 2vw;"  :class="parseInt(recordData[index].OpenCode.L0[0].substr(index1, 1)) % 2==0 ? 'blueTag' : 'redTag'"  v-for="(item1, index1) in recordData[index].OpenCode.L0[0].length" :key="item1">
              {{ parseInt(recordData[index].OpenCode.L0[0].substr(index1, 1)) % 2 == 0 ? 'Chẵn' : 'Lẻ' }}
            </div>
           
          </div>
          <div class="flexs_sb" style="width:200px;" v-else-if="kySelectIndex==3">
            <div class="all tag">{{ getSum(recordData[index].OpenCode.L0[0]) }}</div>
            <div class="tag" style="margin-left: 2vw;"  :class="getSum(recordData[index].OpenCode.L0[0]) > 10 ? 'redTag' : 'blueTag'">
                {{ getSum(recordData[index].OpenCode.L0[0]) > 10 ? 'Tài' : 'Xỉu' }}
            </div>

            <div class="tag" style="margin-left: 2vw;"  :class="getSum(recordData[index].OpenCode.L0[0]) % 2 == 0 ? 'blueTag' : 'redTag'">
                {{ getSum(recordData[index].OpenCode.L0[0]) % 2 == 0 ? 'Chẵn' : 'Lẻ' }}
            </div>
           
          </div>
         
        </div>
      </div>
    </div>
    <div
      v-else
      id="recordList"
      :class="showRecord ? 'recordPlaceVip' : 'recordNoOpenVip'"
    >
      <div
       @click="openInfo(item)"
        style="
          height: 100px;

          border-bottom: 1px solid #aac5f2;

          position: relative;
        "
        v-for="(item, index) in recordData"
      >
        <div style="margin: 0 16px; padding-top: 2.666667vw">
          <div class="flexs_sb">
            <div
              style="
                font-size: 3.733333vw;
                color: #8696cc;
                line-height: 5.333333vw;
                font-weight: 500;
              "
            >
              Kỳ {{ recordData[index].Number }}
            </div>
            <div class="classification">
              <div class="classItem small">
                {{ getCurrentType(0, index) == "big" ? "Tài" : "Xỉu" }}
              </div>
              <div class="classItem odd">
                {{ getCurrentType(1, index) == "double" ? "Chẵn" : "Lẻ" }}
              </div>
              <div class="classItem down">
                {{
                  getCurrentType(2, index) == "top"
                    ? "Trên"
                    : getCurrentType(2, index) == "center"
                    ? "Hoà"
                    : "Dưới"
                }}
              </div>
              <div class="classItem water">
                {{
                  getCurrentType(3, index) == "jin"
                    ? "Kim"
                    : getCurrentType(3, index) == "mu"
                    ? "Mộc"
                    : getCurrentType(3, index) == "shui"
                    ? "Thủy"
                    : getCurrentType(3, index) == "huo"
                    ? "Hỏa"
                    : "Thổ"
                }}
              </div>
            </div>
          </div>
          <div class="resultList">
            <div class="resultNums">
              <ul class="codeList">
                <li v-for="item in vipRecord" class="code">
                  <span class="vipNum">{{ item }}</span>
                </li>
              </ul>
            </div>
            <div class="resultSum">{{ getCurrentType(4, index) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showRecord" @click="toHistory()" class="toHistoryData" style="">
      Xem thêm
      <span class="iconfont icon-icon_more_white_16"></span>
    </div>
    <div v-if="columData.AreaCode == 'Vip'">
      <VTable
        v-if="recordData.length"
        :data="recordData"
        :playData="playData"
      ></VTable>
    </div>

    <div v-else>
      <div class="line"></div>
      <div v-if="columData.AreaCode != 'Vip'" class="sub-lottery">
        <div class="show-all" @click="showPop = true">
          <span>Cách chơi</span
          ><i class="van-icon van-icon-arrow-down"><!----></i>
        </div>
        <div class="sub-lottery-item-wp" v-if="playData.SubPlayCode.indexOf('FUNNY')>=0">
          <div  
          class="subPlay subPlaySe"
        >
        Lô 2 Số Giải ĐB
        </div>
        </div>
        <div class="sub-lottery-item-wp" v-else>
          <div
            v-for="(item, index) in playTopData.SubPlays"
            @click="playChangge(item,playTopData)"
            class="subPlay"
            :class="playData == item ? 'subPlaySe' : ''"
          >
            {{ item.SubName }}
          </div>
        </div>
      </div>
      
      <div v-if="playData.SubPlayCode.indexOf('TS') < 0">
        <div
          style="height: 50px; "
          class="flexs_c"
        >
          <div
            class="flexs_r_c"
            style="margin: 0 auto; border: 1px solid #e9cfa4;padding: 0.533333vw;border-radius: 6.666667vw;"
            :style="'width:' + 3 * 25.33333 + 'vw'"
          >
            <div
              v-for="(item, index) in funcArr"
              @click="funcIndex = index"
              class="funcBtn"
              
             
              :style="
                index == funcArr.length - 1 && funcIndex != funcArr.length - 1
                  ? 'border-right:none;'
                  : ''
              "
              :class="{
                funcSe: index == funcIndex,
                funCNo: index != funcIndex,
                funcBtn1: funcArr.length == 3, funcBtn2: funcArr.length == 2,funcBtn3: funcArr.length == 1
              }"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div
          style="
            height: 33.5px;
            padding: 0 10px;
            color: #e9cfa4;
            flex-direction: row-reverse;
          "
          class="flexs_sb"
        >
          <div style="font-size: 14.5px">
            Tỉ lệ :{{ playData.Odds / 1000 }}
          </div>
        </div>
        <div v-if="funcArr[funcIndex] == 'Chọn Số'">
          <div
            v-if="playData.SubPlayCode.indexOf('FUNNY') >= 0"
            style="margin-top: 8px; padding-bottom: 20px"
          >
            <div
              style="
              width: 100%;
              line-height: 8.533333vw;
              height: 8.533333vw;
              background: #35416d;
              font-size: 3.733333vw;
              color: #fff;
              text-align: center;
              font-weight: 400;
              margin-top: 2.666667vw;
              "
            >
              
              {{ getFunnyDXDS('North') }}
            </div>
            <div
            style="padding: 1.333333vw 2.133333vw 0"
            class="flexs_sb"
            >
              <div
                class="colum playNolmal canClick"
                @click="
                  tsClick(
                    playData.SubPlayCode,
                    'T',
                    'Kèo đôi',
                    getFunnyDXDSODD(playTopData.SubPlays) * 1000
                  )
                "
                :style="
                  containTS(playData.SubPlayCode + 'T')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style=" margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Tài</div>
                {{ getFunnyDXDSODD(playTopData.SubPlays) }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="
                  tsClick(
                    playData.SubPlayCode,
                    'X',
                    'Kèo đôi',
                    getFunnyDXDSODD(playTopData.SubPlays) * 1000
                  )
                "
                :style="
                  containTS(playData.SubPlayCode + 'X')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style=" margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Xỉu</div>
                {{ getFunnyDXDSODD(playTopData.SubPlays) }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="
                  tsClick(
                    playData.SubPlayCode,
                    'L',
                    'Kèo đôi',
                    getFunnyDXDSODD(playTopData.SubPlays) * 1000
                  )
                "
                :style="
                  containTS(playData.SubPlayCode + 'L')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style=" margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Lẻ</div>
                {{ getFunnyDXDSODD(playTopData.SubPlays) }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="
                  tsClick(
                    playData.SubPlayCode,
                    'C',
                    'Kèo đôi',
                    getFunnyDXDSODD(playTopData.SubPlays) * 1000
                  )
                "
                :style="
                  containTS(playData.SubPlayCode + 'C')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style=" margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Chẵn</div>
                {{ getFunnyDXDSODD(playTopData.SubPlays) }}
              </div>
            </div>


            <div
            style="
            width: 100%;
            line-height: 8.533333vw;
            height: 8.533333vw;
            background: #35416d;
            font-size: 3.733333vw;
            color: #fff;
            text-align: center;
            font-weight: 400;
           
            "
          >
          Số Đơn
          </div>
            <div
              class=""
              style="
                padding: 10px 10px;
                margin-top: 0px;
              
              "
            >
              <div class="flexs_r_c" style="margin-left: -2%; flex-wrap: wrap">
                <div
                  class="fiveBtn canClick"
                  v-for="(item, index) in 100"
                  style="margin-left: 2%; margin-top: 10px; font-size: 10px"
                  @click="
                    tsClick(
                      'FUNNY_NUMBER',
                      index,
                      'Số Đơn',
                      getFunnyNumberODD(playTopData.SubPlays) * 1000
                    )
                  "
                  :style="
                    containTS('FUNNY_NUMBER' + index)
                      ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                      : ''
                  "
                >
                  <div style="margin-top: 8px; font-size: 16px;margin-bottom:4px;">
                    {{ index < 10 ? "0" + index : index }}
                  </div>
                  {{ getFunnyNumberODD(playTopData.SubPlays) }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
              <div v-if="showPlay">
                <group
            
                v-for="(item, index) in playData.CodeLength"
                :key="index"
                :title="getGroupTitle(index)"
                :recordData="recordData"
                :selectSub="playData"
              ></group>
              </div>
          </div>
         
        </div>
        <div v-if="funcArr[funcIndex] == 'Chọn  Nhanh'">
          <fastChoose
            :recordData="recordData"
            :selectSub="playData"
          ></fastChoose>
        </div>

        <div v-if="funcArr[funcIndex] == 'Nhập Số'">
          <random :recordData="recordData" :selectSub="playData"></random>
        </div>
      </div>
      <div v-else-if="playData.SubPlayCode.indexOf('TS') >= 0" style="">
        <div>
          <div
            style="
            width: 100%;
            line-height: 8.533333vw;
            height: 8.533333vw;
            background: #35416d;
            font-size: 3.733333vw;
            color: #fff;
            text-align: center;
            font-weight: 400;
            margin-top: 2.666667vw;
            "
          >
            Kèo đôi
          </div>
          <div
          style="padding: 1.333333vw 2.133333vw 0"
          class="flexs_sb"
          >
            <div
              class="colum playNolmal canClick"
              @click="
                tsClick(
                  playData.SubPlayCode,
                  'T',
                  playData.SubName,
                  playData.Odds
                )
              "
              :style="
                containTS(playData.SubPlayCode + 'T')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
              "
            >
              <div style=" margin-top: 0px;
              font-size: 3.733333vw;
              font-weight: 600;
              margin-bottom: 4px;">Tài</div>
              {{ playData.Odds / 1000 }}
            </div>
            <div
              class="colum playNolmal canClick"
              @click="
                tsClick(
                  playData.SubPlayCode,
                  'X',
                  playData.SubName,
                  playData.Odds
                )
              "
              :style="
                containTS(playData.SubPlayCode + 'X')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
              "
            >
              <div style=" margin-top: 0px;
              font-size: 3.733333vw;
              font-weight: 600;
              margin-bottom: 4px;">Xỉu</div>
              {{ playData.Odds / 1000 }}
            </div>
            <div
              class="colum playNolmal canClick"
              @click="
                tsClick(
                  playData.SubPlayCode,
                  'L',
                  playData.SubName,
                  playData.Odds
                )
              "
              :style="
                containTS(playData.SubPlayCode + 'L')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
              "
            >
              <div style=" margin-top: 0px;
              font-size: 3.733333vw;
              font-weight: 600;
              margin-bottom: 4px;">Lẻ</div>
              {{ playData.Odds / 1000 }}
            </div>
            <div
              class="colum playNolmal canClick"
              @click="
                tsClick(
                  playData.SubPlayCode,
                  'C',
                  playData.SubName,
                  playData.Odds
                )
              "
              :style="
                containTS(playData.SubPlayCode + 'C')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
              "
            >
              <div style=" margin-top: 0px;
              font-size: 3.733333vw;
              font-weight: 600;
              margin-bottom: 4px;">Chẵn</div>
              {{ playData.Odds / 1000 }}
            </div>
          </div>

          <div v-if="playData.SubPlayCode == 'TS_SUM_DX_DS'">
            <div
              style="
              width: 100%;
              line-height: 8.533333vw;
              height: 8.533333vw;
              background: #35416d;
              font-size: 3.733333vw;
              color: #fff;
              text-align: center;
              font-weight: 400;
              margin-top: 2.666667vw;
              "
            >
              Số đầu
            </div>
            <div
            style="padding: 1.333333vw 2.133333vw 0"
            class="flexs_sb"
            >
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_5', 'T', 'Số đầu', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_5' + 'T')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Tài</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_5', 'X', 'Số đầu', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_5' + 'X')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Xỉu</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_5', 'L', 'Số đầu', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_5' + 'L')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Lẻ</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_5', 'C', 'Số đầu', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_5' + 'C')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Chẵn</div>
                {{ playData.Odds / 1000 }}
              </div>
            </div>
            <!-- <div
              class="flexs_r_c"
              style="
                padding: 0 10px;
                flex-wrap: wrap;
                margin-left: -2%;
                margin-top: -10px;
              "
            >
              <div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 2%;margin-top: 10px;" @click="tsClick('TS_DX_DS_5',index,'Số đầu')" :style="containTS('TS_DX_DS_5'+index)?'background-color: #173e83;':'background-color: #4a5fa9;'">
								<div style="margin-top: 8px;">{{index}}</div>
								9.99
							</div>
            </div> -->

            <div
              style="
              width: 100%;
              line-height: 8.533333vw;
              height: 8.533333vw;
              background: #35416d;
              font-size: 3.733333vw;
              color: #fff;
              text-align: center;
              font-weight: 400;
              margin-top: 2.666667vw;
              "
            >
              Số hai
            </div>
            <div
            style="padding: 1.333333vw 2.133333vw 0"
              class="flexs_sb"
            >
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_4', 'T', 'Số hai', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_4' + 'T')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Tài</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_4', 'X', 'Số hai', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_4' + 'X')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Xỉu</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_4', 'L', 'Số hai', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_4' + 'L')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Lẻ</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_4', 'C', 'Số hai', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_4' + 'C')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Chẵn</div>
                {{ playData.Odds / 1000 }}
              </div>
            </div>
            <!-- <div
              class="flexs_r_c"
              style="
                padding: 0 10px;
                flex-wrap: wrap;
                margin-left: -2%;
                margin-top: -10px;
              "
            >
              <div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 2%;margin-top: 10px;" @click="tsClick('TS_DX_DS_4',index,'Số hai')" :style="containTS('TS_DX_DS_4'+index)?'background-color: #173e83;':'background-color: #4a5fa9;'">
								<div style="margin-top: 8px;">{{index}}</div>
								9.99
							</div>
            </div> -->

            <div
              style="
              width: 100%;
              line-height: 8.533333vw;
              height: 8.533333vw;
              background: #35416d;
              font-size: 3.733333vw;
              color: #fff;
              text-align: center;
              font-weight: 400;
              margin-top: 2.666667vw;
              "
            >
              Số ba
            </div>
            <div
            style="padding: 1.333333vw 2.133333vw 0"
              class="flexs_sb"
            >
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_3', 'T', 'Số ba', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_3' + 'T')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Tài</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_3', 'X', 'Số ba', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_3' + 'X')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Xỉu</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_3', 'L', 'Số ba', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_3' + 'L')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Lẻ</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_3', 'C', 'Số ba', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_3' + 'C')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Chẵn</div>
                {{ playData.Odds / 1000 }}
              </div>
            </div>
            <!-- <div
              class="flexs_r_c"
              style="
                padding: 0 10px;
                flex-wrap: wrap;
                margin-left: -2%;
                margin-top: -10px;
              "
            >
              <div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 2%;margin-top: 10px;"  @click="tsClick('TS_DX_DS_3',index,'Số ba')" :style="containTS('TS_DX_DS_3'+index)?'background-color: #173e83;':'background-color: #4a5fa9;'">
								<div style="margin-top: 8px;">{{index}}</div>
								9.99
							</div>
            </div> -->

            <div
              style="
              width: 100%;
              line-height: 8.533333vw;
              height: 8.533333vw;
              background: #35416d;
              font-size: 3.733333vw;
              color: #fff;
              text-align: center;
              font-weight: 400;
              margin-top: 2.666667vw;
              "
            >
              Số bốn
            </div>
            <div
            style="padding: 1.333333vw 2.133333vw 0"
              class="flexs_sb"
            >
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_2', 'T', 'Số bốn', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_2' + 'T')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Tài</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_2', 'X', 'Số bốn', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_2' + 'X')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Xỉu</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_2', 'L', 'Số bốn', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_2' + 'L')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Lẻ</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_2', 'C', 'Số bốn', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_2' + 'C')
                    ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                    : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Chẵn</div>
                {{ playData.Odds / 1000 }}
              </div>
            </div>
            <!-- <div
              class="flexs_r_c"
              style="
                padding: 0 10px;
                flex-wrap: wrap;
                margin-left: -2%;
                margin-top: -10px;
              "
            >
              <div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 2%;margin-top: 10px;"  @click="tsClick('TS_DX_DS_2',index,'Số bốn')" :style="containTS('TS_DX_DS_2'+index)?'background-color: #173e83;':'background-color: #4a5fa9;'">
								<div style="margin-top: 8px;">{{index}}</div>
								9.99
							</div>
            </div> -->

            <div
              style="
              width: 100%;
              line-height: 8.533333vw;
              height: 8.533333vw;
              background: #35416d;
              font-size: 3.733333vw;
              color: #fff;
              text-align: center;
              font-weight: 400;
              margin-top: 2.666667vw;
              "
            >
              Số năm
            </div>
            <div
            style="padding: 1.333333vw 2.133333vw 0"
              class="flexs_sb"
            >
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_1', 'T', 'Số năm', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_1' + 'T')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Tài</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_1', 'X', 'Số năm', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_1' + 'X')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Xỉu</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_1', 'L', 'Số năm', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_1' + 'L')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Lẻ</div>
                {{ playData.Odds / 1000 }}
              </div>
              <div
                class="colum playNolmal canClick"
                @click="tsClick('TS_DX_DS_1', 'C', 'Số năm', playData.Odds)"
                :style="
                  containTS('TS_DX_DS_1' + 'C')
                  ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
                  : ''
                "
              >
                <div style="margin-top: 0px;
                font-size: 3.733333vw;
                font-weight: 600;
                margin-bottom: 4px;">Chẵn</div>
                {{ playData.Odds / 1000 }}
              </div>
            </div>
            <!-- <div
              class="flexs_r_c"
              style="
                padding: 0 10px;
                flex-wrap: wrap;
                margin-left: -2%;
                margin-top: -10px;
              "
            >
              <div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 2%;margin-top: 10px;" @click="tsClick('TS_DX_DS_1',index,'Số năm')" :style="containTS('TS_DX_DS_1'+index)?'background-color: #173e83;':'background-color: #4a5fa9;'">
								<div style="margin-top: 8px;">{{index}}</div>
								9.99
							</div>
            </div> -->
          </div>

          <div v-else>
            <!-- <div
							style="background-color: #1b2c5e;height: 32px;text-align: center;line-height: 32px;color: white;font-size: 10px;margin-top: 0px;">
							Số đầu
						</div> -->
            <!-- <div class="flexs_r_c" style="padding: 0 10px;flex-wrap: wrap;margin-left: -2%;margin-top: 10px;">
							<div class="fiveBtn playNolmal canClick" @click="tsClick(playData.SubPlayCode,index,'Số đầu')" :style="containTS(playData.SubPlayCode+index)?'background-color: #173e83;':'background-color: #4a5fa9;'" v-for="(item,index) in 10" 
								style="margin-left: 2%;margin-top: 10px;">
								<div style="margin-top: 8px;">{{index}}</div>
								9.99
							</div>
						
						</div> -->
          </div>

          <div class="flexs_r" style="margin-top: 0px">
            <div
              class="bottomBtn canClick"
              @click="bottomClick(0)"
              :class="bottomIndex == 0 ? 'bottomSe' : 'bottomNol'"
            >
              Tài xỉu
            </div>
            <div
              class="bottomBtn canClick"
              @click="bottomClick(1)"
              :class="bottomIndex == 1 ? 'bottomSe' : 'bottomNol'"
            >
              Chẵn lẻ
            </div>
          </div>
          <div v-if="bottomIndex == 0" style="position: relative">
            <div class="flexs_sb" style="margin-top: 13px; padding: 0 50px">
              <div
                style="
                  width: 35px;
                  height: 20px;
                  text-align: center;
                  line-height: 20px;
                  border-radius: 2.5px;
                  color: white;
                  font-size: 8px;
                  background-color: #f94168;
                "
              >
                Tài
              </div>
              <progress-bar
                style="margin-left: 5px"
                :target="getPersent(0)"
                :colorTop="'#f94168'"
                :percentageColor="'white'"
                :colorBg="'transparent'"
                :percentageSize="10"
                :textSize="7"
                :sizeOut="26"
                :sizeIn="26"
                :total="100"
                :encumbrances="0"
              />

              <div
                style="
                  width: 35px;
                  height: 20px;
                  text-align: center;
                  line-height: 20px;
                  border-radius: 2.5px;
                  color: white;
                  font-size: 8px;
                  background-color: #26a1ff;
                "
              >
                Xỉu
              </div>

              <progress-bar
                style="margin-left: 5px"
                :target="getPersent(1)"
                :colorTop="'#26a1ff'"
                :percentageColor="'white'"
                :colorBg="'transparent'"
                :percentageSize="10"
                :textSize="7"
                :sizeOut="26"
                :sizeIn="26"
                :total="100"
                :encumbrances="0"
              />
            </div>

            <div
              style="
                margin-top: 10px;
                padding-bottom: 20px;
                justify-content: center;
                align-items: center;
              "
              class="flexs_r"
            >
              <div v-for="(item, index) in 16" :key="item">
                <div v-for="item1 in bigSmallArr[index]" :key="item1">
                  <div
                    class="corner"
                    v-if="item1 == 'big'"
                    style="
                      margin-top: 2px;
                      margin-left: 2px;
                      background-color: #f94168;
                    "
                  >
                    T
                  </div>
                  <div
                    class="corner"
                    v-else
                    style="
                      margin-top: 2px;
                      margin-left: 2px;
                      background-color: #26a1ff;
                    "
                  >
                    X
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="bottomIndex == 1" style="position: relative">
            <div class="flexs_sb" style="margin-top: 13px; padding: 0 50px">
              <div
                style="
                  width: 35px;
                  height: 20px;
                  text-align: center;
                  line-height: 20px;
                  border-radius: 2.5px;
                  color: white;
                  font-size: 8px;
                  background-color: #f94168;
                "
              >
                Lẻ
              </div>
              <progress-bar
                style="margin-left: 5px"
                :target="getPersent(2)"
                :colorTop="'#f94168'"
                :percentageColor="'white'"
                :colorBg="'transparent'"
                :percentageSize="10"
                :textSize="7"
                :sizeOut="26"
                :sizeIn="26"
                :total="100"
                :encumbrances="0"
              />

              <div
                style="
                  width: 35px;
                  height: 20px;
                  text-align: center;
                  line-height: 20px;
                  border-radius: 2.5px;
                  color: white;
                  font-size: 8px;
                  background-color: #26a1ff;
                "
              >
                Chẵn
              </div>

              <progress-bar
                style="margin-left: 5px"
                :target="getPersent(3)"
                :colorTop="'#26a1ff'"
                :percentageColor="'white'"
                :colorBg="'transparent'"
                :percentageSize="10"
                :textSize="7"
                :sizeOut="26"
                :sizeIn="26"
                :total="100"
                :encumbrances="0"
              />
            </div>

            <div
              style="
                margin-top: 10px;
                padding-bottom: 20px;
                justify-content: center;
                align-items: center;
              "
              class="flexs_r"
            >
              <div v-for="(item, index) in 16" :key="item">
                <div v-for="item1 in doubleSingleArr[index]" :key="item1">
                  <div
                    class="corner"
                    v-if="item1 == 'single'"
                    style="
                      margin-top: 2px;
                      margin-left: 2px;
                      background-color: #f94168;
                    "
                  >
                    L
                  </div>
                  <div
                    class="corner"
                    v-else
                    style="
                      margin-top: 2px;
                      margin-left: 2px;
                      background-color: #26a1ff;
                    "
                  >
                    C
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="recordData.length && columData.AreaCode == 'Vip'">
      <check
        v-show="showJS"
        :originLottry="columData"
        :selectSub="playTopData.SubPlays[0]"
        :originColum="playTopData"
        :currentQi="getCurrentQi()"
        @close="showJS = false"
      >
      </check>
    </div>
    <div v-if="recordData.length && columData.AreaCode != 'Vip' && playData">
      <check
        v-show="showJS"
        :originLottry="columData"
        :selectSub="playData"
        :originColum="playTopData"
        :currentQi="getCurrentQi()"
        @close="showJS = false"
      >
      </check>
    </div>

    <vipInfo
      :info="getInfo()"
      v-if="showInfo"
      @close="showInfo = false"
    ></vipInfo>

    <rewardHistory
      :recordData="selectRecord"
      :type="columData.AreaCode"
      :subData="playData"
      @close="showRecordInfo = false"
      v-if="showRecordInfo"
    ></rewardHistory>

    <van-popup v-model:show="showPop" position="bottom">
      <div class="cur-all-lottery">
        <i class="van-icon van-icon-arrow-down"><!----></i>
        <div  class="playsBox" v-for="(item,index) in Plays[columData.AreaCode]">
          <p  class="playName">
            {{ item.Name }}
            <img :src="$img('lottery/hot.png')" v-if="item.PlayCode=='FUNNY'||item.PlayCode=='TS'" style="width:34px;margin-left:10px;" alt="">

            <!---->
          </p>


          <ul v-if="item.PlayCode=='FUNNY'">
            <li  @click="playChangge(item.SubPlays[0],item)" :class="playData==item.SubPlays[0]?'active':''" >Lô 2 Số Giải ĐB</li>
            
          </ul>
          <ul v-else>
            <li  @click="playChangge(item1,item)" :class="playData==item1?'active':''" v-for="(item1,index1) in getNoBCZH(item.SubPlays)">{{item1.SubName}}</li>
            
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import log from "@/utils/logger";
import { mqant_lottery } from "@/components/protocol/mqantlib";

import group from "@/views/mobile/components/lottery/group.vue";
import fastChoose from "@/views/mobile/components/lottery/fastChoose.vue";
import random from "@/views/mobile/components/lottery/random.vue";
import check from "@/views/mobile/components/lottery/check.vue";
import ProgressBar from "@/views/mobile/components/lottery/ProgressBar.vue";
import VTable from "@/views/mobile/components/lottery/VTable.vue";
import vipInfo from "@/views/mobile/components/lottery/vipInfo.vue";
import rewardHistory from "@/views/mobile/components/lottery/rewardHistory.vue";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";

import { bus, busName } from "@/components/core/bus";
export default {
  data() {
    return {
      wallet: null,
      account: "",
      columData: null,
      recordData: "",
      kySelectIndex: 0,
      showRecord: false,
      funcIndex: 0,
      playData: null,
      showJS: false,
      selectTS: [],
      bottomIndex: 0,
      timer: "",
      funcArr: ["Chọn Số", "Nhập Số", "Chọn  Nhanh"], //选择号码 输入号码 快选
      playTopData: null,
      showInfo: false,
      selectRecord: null,
      showRecordInfo: false,
      selectSubId: "",
      vipRecord: [],
      selectSubIndex: 0,
      showPop: false,
      showPlay:true
    };
  },
  components: {
    group,
    fastChoose,
    random,
    check,
    ProgressBar,
    VTable,
    vipInfo,
    rewardHistory,
    topHeader,
  },
  props: {
    configData: {},
    lotteryData: {},
  },
  computed: {
    ...mapGetters("tcp_lottery", [
      "isConnect",
      "Lotteries",
      "Plays",
      "Timestamp",
    ]),
    ...mapGetters("tcp", ["isLogged"]),
  },
  mounted() {
    var that = this;
    // emitter.on("playChange", (val) => {
    //   that.playData = val;
    //   that.configFuc();
    //   that.showJS = false;
    //   that.selectTS = [];
    // });
    // emitter.on("VipChange", (val) => {
    //   that.columData = val.data;
    //   that.getRecord();
    // });

    bus.on("showBet", (val) => {
      that.showJS = true;
      //that.handleScrollBottom()
    });
    bus.on("allClean", (val) => {
      that.showJS = false;
     // that.handleScrollTop()
      that.selectTS = [];
    });

    bus.off('noticeOpen')

  
    
    bus.on("noticeOpen", (data) => {
      if (that.columData.LotteryCode.indexOf(data.LotteryCode) >= 0) {
        that.recordData = [data.Record].concat(that.recordData);
        that.conutVipRecord();
      }
    });

    if (this.$route.query) {
      var lottertCode = this.$route.query.lottertCode;
      if (this.$route.query.subPlayCode) {
        var selectSubId = this.$route.query.subPlayCode;
        for (var key in this.Plays) {
          var arr = this.Plays[key];
          for (var obj of arr) {
            var subPlays = obj.SubPlays;
            for (var obj2 of subPlays) {
              if (obj2.SubPlayCode == selectSubId) {
                this.playData = obj2;
                this.playTopData = obj;
                break;
              }
            }
          }
        }
      }
      for (var key in this.Lotteries) {
        var arr = this.Lotteries[key];
        for (var i = 0; i < arr.length; i++) {
          var obj = arr[i];
          if (obj.LotteryCode == lottertCode) {
            this.columData = obj;
            if (!this.playData) {
              var plays =  this.Plays[this.columData.AreaCode]
              plays = plays.sort((a, b) => a.PlaySort - b.PlaySort)
      
      for(var i=0;i<plays.length;i++){
        var subPlays = plays[i].SubPlays
        plays[i].SubPlays = subPlays.sort((a, b) => a.SubPlaySort - b.SubPlaySort);  
      }
      
              this.playData = plays[0].SubPlays[0];
              this.playTopData = plays[0];
              if (this.columData.AreaCode == "Vip") {
                this.playData = this.Plays[this.columData.AreaCode];
                // this.playTopData = this.Plays[this.columData.AreaCode][0];
              }
            }
          }
        }
      }
    } else {
      this.$router.push({ name: "home" });
    }

    // this.columData = JSON.parse(this.$route.params.data);
    // this.playData = JSON.parse(this.$route.params.playData);
    // if (this.$route.params.playTopData) {
    //   this.playTopData = JSON.parse(this.$route.params.playTopData);
    //   this.setPlayTopData1(this.playTopData);
    // }
    if (!this.isConnect) {
      this.$router.push({ name: "home" });
      return;
    }

    var that = this;

    that.getRecord();
    if (this.columData.AreaCode != "Vip") {
      this.configFuc();
    }
  },
  created() {},
  beforeRouteLeave(to, from, next) {
    next();
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    getNoBCZH(val){
				var arr = []
				for(var obj of val){
					if(obj.SubPlayCode.indexOf('BCZH_10')<0){
						arr.push(obj)
					}
				}
				return arr
			},
    getSum(code) {
            var sum = 0
            for (var i = 0; i < code.length; i++) {
                var num = parseInt(code.substr(i, 1))
                sum += num
            }
            return sum

        },
    toHistory(){
      this.$router.push({name:'rewardHistory',query:{
        LotteryCode:this.columData.LotteryCode
      }})
    },
    playChangge(val,val2){
      this.playData = val
      this.playTopData = val2
      this.showPop = false
      this.configFuc();
      this.showPlay = false
      var that = this
      bus.emit('freshRecord',val)
      setTimeout(function() {
				that.showPlay = true
        
      }, 50);
      this.bottomClick(this.bottomIndex)

      this.showJS = false;
      this.selectTS = [];
    },
    showOrClose() {
      this.showRecord = !this.showRecord;
      if (!this.showRecord) {
        document.getElementById("recordList").scrollTop = 0;
      }
    },
    conutVipRecord() {
      var index = 0;
      this.vipRecord = [];
      var that = this;
      var timer = setInterval(() => {
        if (!that.recordData.length) {
          clearInterval(timer); //停止
          return;
        }
        that.vipRecord.push(that.recordData[0].OpenCode.L0[index]);
        index++;
        if (index == 20) {
          clearInterval(timer); //停止
          return;
        }
        // //console.log('ddddss',that.vipRecord)
      }, 50);
    },
    isRed() {
      if (this.getBigTime().indexOf(":") >= 0) {
        var second = parseInt(this.getBigTime().split(":")[2]);
        var hour = parseInt(this.getBigTime().split(":")[0]);
        var minute = parseInt(this.getBigTime().split(":")[1]);

        if (second <= 10 && hour == 0 && minute == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    getTitle() {
      return "Chi tiết " + parseInt(this.selectRecord.Number);
    },
    openInfo(val) {
      this.selectRecord = val;
      this.showRecordInfo = true;
    },
    getInfo() {
      if (this.columData.AreaCode != "Vip") {
        return this.playData.Description;
      } else {
        return this.playData[0].SubPlays[0].Description;
      }
    },
    // 滚动到顶部
    handleScrollTop() {
      this.$nextTick(() => {
        let scrollElem = document.getElementById('scrollDiv') 
        scrollElem.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
    // 滚动到底部
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = document.getElementById('scrollDiv') 
        scrollElem.scrollTo({ top: 6692, behavior: "smooth" });
      });
    },

    getGroupTitle(index) {
      var arr = ["Đơn Vị", "Chục", "Trăm", "Ngàn"];
      arr = arr.splice(0, this.playData.CodeLength);
      return arr[this.playData.CodeLength - 1 - index];
    },
    subChange(item) {},
    getPersent(type) {
      //0大 1小 2单 3双
      var arr;
      if (type == 0 || type == 1) {
        arr = this.bigSmallArr;
      } else if (type == 2 || type == 3) {
        arr = this.doubleSingleArr;
      }

      var count = 0;
      var total = 0;

      for (var i = 0; i < (arr.length >= 16 ? 16 : arr.length); i++) {
        var tmpArr = arr[i];

        for (var j = 0; j < tmpArr.length; j++) {
          var str = tmpArr[j];
          total++;

          if (type == 0) {
            if (str == "big") {
              count++;
            }
          } else if (type == 1) {
            if (str == "small") {
              count++;
            }
          } else if (type == 2) {
            if (str == "single") {
              count++;
            }
          } else if (type == 3) {
            if (str == "double") {
              count++;
            }
          }
        }
      }

      return (count / total) * 100;
    },
    getFunnyDXDSODD(subplays) {
      for (var obj of subplays) {
        if (obj.SubPlayCode == "FUNNY_DX_DS") {
          return obj.Odds / 1000;
        }
      }
    },
    getFunnyNumberODD(subplays) {
      for (var obj of subplays) {
        if (obj.SubPlayCode == "FUNNY_NUMBER") {
          return obj.Odds / 1000;
        }
      }
    },
    tsClick(str, code, sub, odd) {
      var contain = false;
      var newStr = str + code;
      for (var i = 0; i < this.selectTS.length; i++) {
        if (this.selectTS[i] == newStr) {
          contain = true;
          this.selectTS.splice(i, 1);

          break;
        }
      }
      if (!contain) {
        this.selectTS.push(newStr);
      }

      var num;
      if (code == "T") {
        num = "Tài";
      } else if (code == "X") {
        num = "Xỉu";
      } else if (code == "L") {
        num = "Lẻ";
      } else if (code == "C") {
        num = "Chẵn";
      } else {
        num = code;
        if (str.indexOf("FUNNY") >= 0) {
          num = num < 10 ? "0" + num : num;
        }
      }

      var obj = {
        num: num,
        Odds: odd,
        betType: "Tap",
        SubPlayCode: str ? str : this.playData.SubPlayCode,
        SubName: sub,

        UnitBetAmount: 1000,
      };
      bus.emit("BET", obj);
    },
    containTS(str) {
      //var contain = false
      for (var item of this.selectTS) {
        if (str == item) {
          return true;
        }
      }
      return false;
    },
    bottomClick(index) {
      this.bottomIndex = index;
      var type1 = this.highType == "sum" ? "0" : "1";
      if (this.bottomIndex == 0) {
        this.bigSmallArr = this.countTSData(type1, 0);
      } else {
        this.doubleSingleArr = this.countTSData(type1, 1);
      }
    },
    getFunnyDXDS(type){
      var arr = this.Plays[type]


      for(var obj of arr){
        if(obj.PlayCode=="FUNNY"){
          var subPlays = obj.SubPlays
          for(var obj2 of subPlays){
            if(obj2.SubPlayCode=="FUNNY_DX_DS"){
              return obj2.SubName
        }
          }
        }
       
      }
    },
    getFunnyNumber(type){
      var arr = this.Plays[type]

      for(var obj of arr){
        if(obj.PlayCode=="FUNNY"){
          var subPlays = obj.SubPlays
          for(var obj2 of subPlays){
            if(obj2.SubPlayCode=="FUNNY_NUMBER"){
              return obj2.SubName
        }
          }
        }
       
      }
    },
    countTSData(type, type2) {
      var data = this.recordData;
      var index = 0;
      var indexArr = [];
      var tmpArr = [];
      var lastArr = [];

      var lastStr;
      for (var i = 0; i < data.length; i++) {
        var num = data[i].OpenCode.L0[0];
        tmpArr.push(num);
      }
      for (var num of tmpArr) {
        var str;
        if (type == 0) {
          var total = 0;
          for (var i = 0; i < num.length; i++) {
            total += parseInt(num[i]);
          }

          if (type2 == 0) {
            if (total >= 23) {
              str = "big";
            } else {
              str = "small";
            }
          } else {
            if (total % 2 == 0) {
              str = "double";
            } else {
              str = "single";
            }
          }
          if (lastStr == str) {
            if (indexArr.length < 6) {
              indexArr.push(str);
              lastArr[index] = indexArr;
            } else {
              indexArr = [];
              index++;
              indexArr.push(str);
            }
          } else {
            indexArr = [];
            if (lastStr) {
              index++;
            }
            indexArr.push(str);
          }
          lastArr[index] = indexArr;

          lastStr = str;
        } else {
          var sub = this.playData.SubPlayCode.substr(this.playData.SubPlayCode.length-1, 1);
         // console.log('yyyy',this.playData.SubPlayCode)
          var tmpNum = num.substr(5 - sub, 1);

          if (type2 == 0) {
            if (tmpNum >= 5) {
              str = "big";
            } else {
              str = "small";
            }
          } else {
            if (tmpNum % 2 == 0) {
              str = "double";
            } else {
              str = "single";
            }
          }
          if (lastStr == str) {
            if (indexArr.length < 6) {
              indexArr.push(str);
              lastArr[index] = indexArr;
            } else {
              indexArr = [];
              index++;
              indexArr.push(str);
            }
          } else {
            indexArr = [];
            if (lastStr) {
              index++;
            }
            indexArr.push(str);
          }
          lastArr[index] = indexArr;

          lastStr = str;
        }
      }
      if (lastArr.length > 16) {
        lastArr = lastArr.slice(0, 16);
      }
      var arrs = [];
      for (var i = lastArr.length - 1; i >= 0; i--) {
        arrs.push(lastArr[i]);
      }

      return arrs;
    },
    configFuc() {
      this.funcArr = ["Chọn Số", "Nhập Số", "Chọn  Nhanh"];
      this.funcIndex = 0;
      if (this.playData.SubPlayCode.indexOf("TS") >= 0) {
        this.bigSmallArr = this.countTSData(0, 0);
      }

      if (this.playData.SubPlayCode.indexOf("BZ") >= 0) {
        this.funcArr = ["Chọn Số", "Nhập Số", "Chọn  Nhanh"];
      }
      if (
        this.playData.SubPlayCode.indexOf("FUNNY") >= 0 ||
        this.playData.SubPlayCode.indexOf("4D") >= 0
      ) {
        this.funcArr = ["Chọn Số", "Nhập Số"];
        this.funcIndex = 0;
      }
      if (
        this.playData.SubPlayCode.indexOf("ZH") >= 0 ||
        this.playData.SubPlayCode.indexOf("BCZH") >= 0
      ) {
        this.funcArr = ["Nhập Số", "Chọn  Nhanh"];
        this.funcIndex = 1;
      }
      if (this.playData.SubPlayCode.indexOf("1D") >= 0) {
        this.funcArr = ["Chọn Số"];
        this.funcIndex = 0;
      }
      if (this.playData.SubPlayCode == "BZ_C4") {
        this.funcArr = ["Chọn Số", "Nhập Số"];
      }
    },
    getCurrentType(type, index) {
      for (var i = 0; i < this.recordData.length; i++) {
        var total = 0;
        var arr = this.recordData[i].OpenCode.L0;
        if (index >= 0) {
          var arr = this.recordData[index].OpenCode.L0;
        }
        var topCount = 0;
        var bottomCount = 0;

        for (var num of arr) {
          total += parseInt(num);
          if (num > 0 && num < 41) {
            topCount++;
          } else if (num >= 41) {
            bottomCount++;
          }
        }
        if (type == 0) {
          if (total >= 811) {
            return "big";
          } else {
            return "small";
          }
        } else if (type == 1) {
          if (total % 2 == 0) {
            return "double";
          } else {
            return "single";
          }
        } else if (type == 2) {
          if (topCount > 10) {
            return "top";
          } else if (bottomCount > 10) {
            return "bottom";
          } else {
            return "center";
          }
        } else if (type == 3) {
          if (total >= 210 && total <= 695) {
            return "jin";
          } else if (total >= 696 && total <= 763) {
            return "mu";
          } else if (total >= 764 && total <= 855) {
            return "shui";
          } else if (total >= 856 && total <= 923) {
            return "huo";
          } else if (total >= 924 && total <= 1410) {
            return "tu";
          }
        } else {
          return total;
        }
      }
    },
    isSingle(num) {
      if (num % 2 == 0) {
        return false; //'Chẵn'
      } else {
        return true; //'Lẻ'
      }
    },
    isBig(num) {
      if (num > 4) {
        return true; //'Tài'
      } else {
        return false; //'Xỉu'
      }
    },
    getBigTime() {
      var second = this.columData.Countdown;
      if (this.columData.Countdown && this.columData.Intervals == 0) {
        var hour = parseInt(second / 3600);
        var minute = parseInt((second - hour * 3600) / 60);
        var second2 = second - hour * 3600 - minute * 60;
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minute < 10) {
          minute = "0" + minute;
        }
        if (second2 < 10) {
          second2 = "0" + second2;
        }
        if (hour < 24) {
          return hour + ":" + minute + ":" + second2;
        } else {
          if (this.columData.AreaCode == "North") {
            return this.$t("下注结束");
          }
        }
      }

      if (this.columData.Intervals == 0) {
        var OpenTime = this.columData.OpenTime;

        var date =
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate() +
          " ";
        OpenTime = date + OpenTime;

        var end = Date.parse(new Date(OpenTime));

        var now = this.Timestamp ? this.Timestamp : Date.parse(new Date());

        var msec = end - now;

        var hasOut = false;
        if (msec < 0) {
          //console.log('KKKKKKKK',item.OpenTime)
          hasOut = true;

          date =
            new Date(now).getFullYear() +
            "-" +
            (new Date(now).getMonth() + 1) +
            "-" +
            (new Date(now).getDate() + 1) +
            " ";
          var newOpenTime = date + this.columData.OpenTime;

          end = Date.parse(new Date(newOpenTime));

          now = this.Timestamp ? this.Timestamp : Date.parse(new Date());
          msec = end - now;
        }

        let hr = parseInt((msec / 1000 / 60 / 60) % 24);
        hr = hr < 10 ? "0" + hr : hr;

        let min = parseInt((msec / 1000 / 60) % 60);
        min = min < 10 ? "0" + min : min;

        let sec = parseInt((msec / 1000) % 60);
        sec = sec < 10 ? "0" + sec : sec;

        if (
          this.columData.AreaCode == "South" ||
          (this.leftIndex1 == this.columData.AreaCode) == "Central"
        ) {
          var time = this.Timestamp ? new Date(this.Timestamp) : new Date();
          var day = time.getDay();

          var WeekNumber = this.columData.WeekNumber; //this.lotteryConfig.Lotteries[this.titleArr[this
          //.leftIndex1].title][index].WeekNumber
          var WeekNumberStr = WeekNumber + "";
          if (WeekNumberStr.indexOf(",") >= 0) {
            var weekStr = "";
            for (var i = 0; i < WeekNumberStr.split(",").length; i++) {
              var newWeekNum = parseInt(WeekNumberStr.split(",")[i]);
              if (
                newWeekNum - day <= -1 ||
                newWeekNum - day > 1 ||
                (hasOut && newWeekNum - day == 0) ||
                (newWeekNum - day == 1 && !hasOut)
              ) {
                if (newWeekNum != 0) {
                  weekStr += "Thứ" + (newWeekNum + 1);
                } else {
                  weekStr += "Chủ nhật";
                }
                weekStr += " ";
              } else {
                return hr + ":" + min + ":" + sec;
              }
            }
            return weekStr;
          } else {
            if (
              WeekNumber - day <= -1 ||
              WeekNumber - day > 1 ||
              (hasOut && WeekNumber - day == 0) ||
              (WeekNumber - day == 1 && !hasOut)
            ) {
              if (WeekNumber != 0) {
                return "Thứ" + (WeekNumber + 1);
              } else {
                return "Chủ nhật";
              }
            } else {
              return hr + ":" + min + ":" + sec;
            }
          }

          //if(hr<24)
        }

        return hr + ":" + min + ":" + sec;
      }

      var hour = parseInt(second / 3600);
      var minute = parseInt((second - hour * 3600) / 60);
      var second2 = second - hour * 3600 - minute * 60;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second2 < 10) {
        second2 = "0" + second2;
      }
      return hour + ":" + minute + ":" + second2;
    },

    getCurrentQi() {
      if (
        (this.columData.AreaCode == "Central" ||
          this.columData.AreaCode == "South") &&
        this.columData.Intervals == 0
      ) {
        if (this.columData.Countdown == 999999) {
          return this.$t("下注结束");
        }
        var now = this.Timestamp ? this.Timestamp : Date.parse(new Date());
        var next = now + this.columData.Countdown * 1000;
        var year = new Date(next).getFullYear();
        var month = new Date(next).getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        var date = new Date(next).getDate();

        if (date < 10) {
          date = "0" + date;
        }

        return year + "" + month + date;
      } else {
        return parseInt(this.recordData[0].Number) + 1;
      }
    },
    syncTime(val) {
      this.Timestamp = val.Timestamp * 1000;
      for (var key in val.CountDownTime) {
        if (key == this.columData.LotteryCode) {
          this.columData.Countdown = val.CountDownTime[key];
        }
      }
    },
    getImg() {
      if (
        (this.columData.AreaCode == "South" ||
          this.columData.AreaCode == "Central") &&
        this.columData.Intervals == 0
      ) {
        return require("@/assets/" +
          this.columData.LotteryName.toUpperCase() +
          ".png");
      }

      return require("@/assets/" + this.columData.LotteryCode + ".webp");
    },
    getRecord() {
      var that = this;
      var obj = {
        LotteryCode: this.columData.LotteryCode,
        limit: this.columData.AreaCode != "Vip" ? 100 : 50,
      };
      mqant_lottery.request(topic.record, obj, function (data, topicName, msg) {
        that.recordData = data.Data;
        that.conutVipRecord();
        //console.log(topicName + " : " + JSON.stringify(data));
        if (!that.recordData[0].OpenCode) {
          that.recordData.splice(0, 1);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.num {
  display: inline-block;
  margin-right: 0.533333vw;
  width: 4.266667vw;
  height: 5.333333vw;
  line-height: 5.333333vw;
  background: #fff;
  border-radius: 0.8vw;
  color: #ff0f84;
  text-align: center;
}
.countDownWarning {
  box-sizing: border-box;
  color: #f94168 !important;
  display: inline-block;
  -webkit-animation: countDownHeartAni 1s ease infinite both;
  animation: countDownHeartAni 1s ease infinite both;
}
@keyframes countDownHeartAni {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
.kyBtn {
  width: 14.933333vw;
  height: 6.4vw;
  line-height: 6.4vw;
  border-radius: 3.2vw;
  font-size: 3.466667vw;
  text-align: center;
  font-weight: 400;
}
.kySe {
  background-color: #90a2dc;
  color: #1b233d;
}
.kyNo {
  color: #90a2dc;
  background-color: #384369;
}
.resultNum {
  border-radius: 50%;
  width: 8.533333vw;
  margin-left: 2vw;
  height: 8.533333vw;
  line-height: 8.533333vw;
  background: url("@/assets/mobile/home/result-ball.png")
    no-repeat 50%/100%;
  color: #292b31;
  text-align: center;
  font-size: 3.2vw;
  font-weight: 500;
}
.tag {
  width: 9.866667vw;
  height: 5.333333vw;
  border-radius: 3px;
  font-size: 3.2vw;
  line-height: 5.333333vw;
  text-align: center;
  color: white;
}
.blueTag {
  background-color: #26a2ff;
}
.all {
  background-color: #26a2ff;
  color: white;
}
.redTag {
  background-color: #f94168;
}
.numVip {
  width: 21px;
  height: 21px;
  text-align: center;
  line-height: 21px;
  border-radius: 10.5px;
  font-size: 12px;
  color: white;
  border: 1px solid white;
  margin-left: 3px;
  margin-top: 3px;
  background-color: #3752f5;
}
.funcBtn {

  text-align: center;

  line-height: 34px;
  border-radius: 17px;
  font-size: 3.733333vw;
  font-weight:600;
  
}
.funcBtn1{
  width: 95px;
  height: 34px;
}
.funcBtn2{
  width: 142.5px;
  height: 34px;
}
.funcBtn3{
  width: 285px;
  height: 34px;
}


.funcSe {
  background-color: #e9cfa4;
  color: #293356;
}
.funCNo {
  background-color: transparent;
  color: #e9cfa4;
}
.fourbtn {
  width: 22vw;
  height: 48px;
  text-align: center;
  border-radius: 3px;
  color: white;
  font-size: 10px;
}
.fiveBtn {
  width: 18%;
  height: 48px;
  text-align: center;
  color: white;
  
  border-radius: 3px;
  font-size: 10px;
  background-color:#35416d;
}
.playNolmal {
  background-color: #4a5fa9;
}
.bottomBtn {
  height: 32px;
  width: 50%;
  line-height: 32px;
  color: white;
  font-size: 10px;
  text-align: center;
}

.bottomSe {
  background-color: #90a2dc;
}

.bottomNol {
  background-color: rgb(27, 35, 61);
}
.corner {
  width: 17px;
  height: 17px;
  border-radius: 8.5px;
  color: white;
  font-size: 10px;
  text-align: center;
  line-height: 17px;
}
.moreDataBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon-icon_lotteryinfo {
    margin-right: 2.666667vw;
    color: #e9cfa4;
    font-size: 4.8vw;
  }
  .dataBtn {
    display: inline-block;
    height: 5.866667vw;
    line-height: 5.866667vw;
    border: 1px solid #e9cfa4;
    border-radius: 1.066667vw;
    font-size: 3.2vw;
    color: #e9cfa4;
    text-align: center;
    font-weight: 400;
    padding: 0 1.066667vw;

    span {
      vertical-align: middle;
    }
  }
}
.iconfont {
  font-size: 3.733333vw;
  position: relative;
  top: -1px;
  display: inline-block;
  transition: all 0.3s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.isOpenList {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.line {
  margin: auto;
  width: calc(100% - 8.53333vw);
  height: 1px;
  background: rgba(144, 162, 220, 0.3);
}
.balls-wp {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.recordNoOpen {
  overflow: hidden;
  height: 52px;
}
.recordNoOpenVip {
  overflow: hidden;
  height: 100px;
}

.recordPlaceVip {
  animation: heightVip 0.3s;
  overflow: auto;
  height: 200px;
  white-space: nowrap;
}
@keyframes heightVip {
  from {
    height: 100px;
  }
  to {
    height: 200px;
  }
}

.recordPlace {
  animation: height 0.3s;
  overflow: auto;
  height: 200px;
  white-space: nowrap;
}
@keyframes height {
  from {
    height: 52px;
  }
  to {
    height: 200px;
  }
}

.classification {
  display: flex;
  justify-content: space-around;
  .classItem {
    height: 5.333333vw;
    font-size: 3.2vw;
    text-align: center;
    line-height: 5.333333vw;
    margin-left: 1.066667vw;
    border-radius: 0.533333vw;
    padding: 0 2.133333vw;
    vertical-align: middle;
    color: #8fa1dc;
    background: #28314f;
    box-shadow: inset 0 -1px 0.533333vw 0 #8e9fda,
      inset 0 1px 0.533333vw 0 #8d9ed9;
  }
}
.resultList {
  display: flex;
  margin-top: 1.333333vw;
  height: 15.466667vw;
  overflow: hidden;
  justify-content: space-between;
  .resultSum {
    box-sizing: border-box;
    width: 9.866667vw;
    height: 14.4vw;
    background-image: linear-gradient(136deg, #7146ff, #4a69ff);
    box-shadow: 0 0.533333vw 1.333333vw 0 rgba(98, 54, 255, 0.25);
    border: 1px solid hsla(0, 0%, 100%, 0.5);
    border-radius: 1.066667vw;
    font-size: 3.733333vw;
    color: #fff;
    margin-left: 2.666667vw;
    text-align: center;
    line-height: 14.4vw;
  }
  .resultNums {
    flex: 1;
    display: flex;
    height: 58px;
    justify-content: space-around;
    .codeList {
      flex: 1;
      height: 58px;
      display: flex;
      flex-wrap: wrap;
      .code {
        flex: 1;
        width: 24px;
        height: 24px;
        min-width: 10%;
        max-width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.333333vw;
        .vipNum {
          box-sizing: border-box;
          display: inline-block;
          width: 6.4vw;
          height: 6.4vw;
          line-height: 6.4vw;
          background: url("@/assets/mobile/home/result-ball.png")
            no-repeat 50%/120%;
          font-size: 3.2vw;
          color: #292b31;
          border-radius: 50%;
          text-align: center;
        }
      }
    }
  }
}
.toHistoryData {
  height: 8.533333vw;
  line-height: 8.533333vw;
  background: #35416d;
  font-size: 3.2vw;
  color: #99acf1;
  text-align: center;
  font-weight: 400;
  box-shadow: inset 0 0.5px 0 0 rgba(144, 162, 220, 0.5);
  .iconfont {
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}

.sub-lottery {
  display: flex;
  align-items: center;
  height: 13.866667vw;
  margin: 0 4.266667vw;
  background: #2f395f;
  .sub-lottery-item-wp {
    width: calc(100% - 32vw);
    overflow-x: scroll;
    white-space: nowrap;
  }
  .sub-lottery-item-wp::-webkit-scrollbar {
    display: none;
  }
  .sub-lottery-item-wp {
    /* 隐藏滚动条 */
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .show-all {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.133333vw;
    width: 25.066667vw;
    height: 7.466667vw;
    line-height: 7.466667vw;
    color: #8b9dd6;
    border: 1px solid #8b9dd6;
    border-radius: 3.733333vw;
    font-size: 3.2vw;
    margin-right: 6.933333vw;
    .van-icon {
      padding: 0;
    }
  }
}
.subPlay {
  padding: 0 2.666667vw;
  max-width: 45.333333vw;
  background: #384369;
  border-radius: 14px;
  height: 28px;
  line-height: 28px;

  color: #90a2dc;
  display: inline-block;
  font-size: 3.2vw;

  margin-right: 2.666667vw;
}
.subPlaySe {
  background: linear-gradient(135deg, #7146ff, #4a69ff) !important;
  color: #fff !important;
}
.cur-all-lottery {
  padding: 4.266667vw;
  height: 104.533333vw;
  overflow-y: scroll;
  background: #2f395f;
  position: relative;
  .playName{
    height: 5.333333vw;
    line-height: 5.333333vw;
    color: #fff;
    font-size: 4vw;
    font-weight: 500;
    margin-bottom: 3.2vw;
    display:flex;
    flex-direction:row;
    align-items:center;
}
  .playsBox{
    padding-bottom: 2.133333vw;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      li {
        position: relative;
        width: 27.733333vw;
        height: 6.4vw;
        line-height: 6.4vw;
        border-radius: 3.2vw;
        color: #90a2dc;
        background: #384369;
        text-align: center;
        font-size: 3.2vw;
        font-weight: 500;
        margin: 0 2.75vw 3.2vw 0;
    }
    li.active {
      color: #fff;
      background: linear-gradient(135deg,#7146ff,#4a69ff);
    }
  }
  }
  .van-icon-arrow-down {
    position: absolute;
    top: 4.266667vw;
    right: 4.266667vw;
    padding: 0;
    color: #8b9dd6;
    font-size: 4.266667vw;
  }
}
.colum {
  flex: 1;
  height: 13.066667vw;
  margin-top: 3.2vw;
  margin: 1.6vw;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #35416d;
  border-radius: 1.066667vw;
}
</style>
<style>
.flexs_r_c {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexs_sb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flexs_r {
  display: flex;
  flex-direction: row;
}
</style>
