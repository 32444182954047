<template>
  <div>
    <h1 style="text-align: center;"><strong>TÍNH TRÁCH NHIỆM</strong></h1>
    <h1 style="text-align: left;"><strong>QUY ĐỊNH VỀ ĐỘ TUỔI</strong></h1>
    <p><span style="">Zbet668 quảng bá cá cược có trách nhiệm và phản
        đối mạnh mẽ các thành viên dưới tuổi vị thành niên sử dụng phần mềm của chúng tôi để giải trí.
        Đồng thời, với cương vị là công ty cá cược chuyên nghiệp, chúng tôi luôn ngăn ngừa và phòng
        chống gian lận cờ bạc. Nếu phát hiện thành viên nào dưới 18 tuổi tham gia chơi, chúng tôi sẽ thu
        hồi lại tất cả các khoản tiền trúng thưởng và có quyền đóng tài khoản thành viên ngay lập
        tức.</span></p>
    <p><span style="">Net Nancy - phần mềm ngăn chặn trẻ em xem nội
        dung trang web không phù hợp: www.netnancy.com</span></p>
    <p><span style="">CYBERsitter- phần mềm cho phép các bậc phụ huynh
        khóa các trang web riêng mà họ muốn : www.cybersitter.com</span></p>
    <p><span style=""> </span></p>
    <p><span style="">Zbet668 muốn đem đến sự hài lòng cho thành viên
        trong việc cung cấp dịch vụ chơi game trực tuyến. Nếu thành viên lo ngại rằng cờ bạc sẽ ảnh
        hưởng nghiêm trọng đến cuộc sống của mình hoặc của người khác, chúng tôi khuyên thành viên
        nên:</span></p>
    <p><span style="">· Khi đăng nhập vào hệ thống của chúng tôi, đừng
        để trẻ vị thành niên theo dõi.</span></p>
    <p><span style="">· Nếu thành viên muốn lưu giữ hoạt động của mình
        trên hệ thống trang web của chúng tôi, hãy nhớ sử dụng một mật khẩu để khóa máy tính, điện
        thoại.</span></p>
    <p><span style="">· Mỗi thành viên có trách nhiệm phải lưu giữ tài
        khoản Zbet668 và mật khẩu ở nơi an toàn.</span></p>
    <p><span style="">· Vui lòng sử dụng phần mềm bảo vệ độ tuổi trên
        máy tính của thành viên để hạn chế trẻ vị thành niên truy cập vào các trang web cụ thể và sử
        dụng các chương trình có liên quan.</span></p>
    <p><span style="">· Không chia sẻ thông tin về thẻ tín dụng hoặc
        tài khoản với trẻ vị thành niên.</span></p>
    <p><span style="">· Hãy quan sát trẻ vị thành niên xung quanh trước
        khi đăng nhập vào phần mềm Zbet668 từ máy tính của người khác hoặc từ một địa điểm từ xa (
        internet không dây, sân bay, khách sạn, hoặc các địa điểm công cộng khác)</span></p>
    <h3><strong>TRÁCH NHIỆM VÀ TRUNG THỰC</strong></h3>
    <p><span style="">· Zbet668 cam kết nâng cao dịch vụ và cam kết với
        khách hàng để hoàn thành trách nhiệm cao nhất, bao gồm tính trung thực, minh bạch, hợp pháp và
        các khía cạnh khác.</span></p>
    <p><span style="">· Nếu thành viên có bất kỳ thắc mắc về cá cược có
        trách nhiệm, vui lòng liên hệ bộ phận dịch vụ khách hàng 24/7 của chúng tôi. Chúng tôi cam kết
        365 ngày trong năm luôn hỗ trợ kĩ thuật và câu hỏi có liên quan về dịch vụ một cách nhanh chóng
        nhất.</span></p>
    <h3><strong>QUY ĐỊNH:</strong></h3>
    <p><span style="">· Chủ tài khoản có nghĩa vụ bảo mật thông tin về
        tên truy cập và mật khẩu của mình, và không nên cho phép bên thứ ba bất kì tham gia vào trò chơi
        của trang web này dưới bất kì hình thức nào, và sử dụng tên truy cập và mật khẩu của mình. Nếu
        không, người chủ tài khoản đó phải chịu trách nhiệm pháp lý.</span></p>
    <p><span style="">· Thành viên nên xác nhận rằng các trò chơi trực
        tuyến này tuân theo luật địa phương trước khi tiếp tục chơi.</span></p>
    <p><span style="">· Thời gian hiển thị trên trang web Zbet668 là
        theo thời gian GMT+8.</span></p>
    <p><span style="">· Trang web này chỉ phù hợp cho khách hàng đủ
        điều kiện đặt cược và phải đủ từ 18 tuổi trở lên.</span></p>
    <p><span style="">· Zbet668 có quyền từ chối hoặc không chấp nhận
        bất kì quyền nào của thành viên đăng nhập hoặc tham gia vào tất cả các trò chơi trên trang web
        này dưới bất cứ hình thức nào một cách gian dối và không chính xác.</span></p>
    <p><span style="">· Đôi khi, thảm họa xảy ra hoặc sự xâm nhập và
        xâm hại của con người khiến trang web thiệt hại dữ liệu. Do đó, thành viên nên cố gắng giữ lại
        hoặc in dữ liệu để tiện cho việc theo dõi và khiếu nại.</span></p>
    <p><span style="">· Zbet668 không nhất thiết lưu giữ các sửa đổi cho
        các quy tắc xuất bản đầu tiên.</span></p>
    <p><span style="">· Zbet668 luôn có quyết định cuối cùng trong bất
        cứ trường hợp nào.</span></p>
    <p> </p>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>