<template>
    <div class="serviceFloat">
        <div class="service-wp serviceFloat">
            <div class="service">
                <div v-show="!aniMoveDir" class="service-item-wp">
                    <div class="itemsContent">
                        <!-- <div class="themes">
                            <span class="themeBtn black">
                            </span>
                            <span class="themeBtn white"></span>
                        </div> -->
                        <div class="service-item" @click="toUrl(lobbyInfo.CustomerInfo.customerLiveChat)">
                            <i class="iconfont icon-icon_customer_service">
                            </i>
                            <span>CSKH</span>
                        </div>
                        <div class="service-item chatroom" @click="jumpToChat()">
                            <div class="el-badge">
                                <i class="iconfont icon-icon_nav_chatroom_nor"></i>
                                <sup v-if="redDot.num > 0 && redDot.state"
                                    class="el-badge__content el-badge__content--undefined is-fixed" style="">{{
                                        redDot.num > 99 ? "99+" : redDot.num }}</sup>
                            </div>
                            <span>Phòng chat</span>
                        </div>
                        <el-popover placement="left" trigger="hover" :width="200" popper-class="my-popover-class">
                            <template #reference>
                                <div class="service-item">
                                    <i class="iconfont icon-icon_send"></i>
                                    <span>Telegram</span>
                                </div>
                            </template>
                            <div class="tg-class" @click="toUrl(lobbyInfo.CustomerInfo.customerTelegram)">
                                <img class="left" :src="$img('home/icon_tg.png')" alt="">
                                <div class="right">
                                    <span>Telegram</span>
                                    <span>{{ lobbyInfo.CustomerInfo.customerTelegram }}</span>
                                </div>
                            </div>
                        </el-popover>

                        <el-popover placement="left" trigger="hover" :width="200" popper-class="my-popover-class">
                            <template #reference>
                                <div class="service-item">
                                    <img style="margin-top:6px;" :src="$img('home/facebook.png')" alt="">

                                    <div style="margin-top:3px;">facebook</div>
                                </div>
                            </template>
                            <div class="tg-class" @click="toUrl(lobbyInfo.CustomerInfo.customerFaceBook)">
                                <div class="right">
                                    <span>FaceBook</span>
                                    <span>{{ lobbyInfo.CustomerInfo.customerFaceBook }}</span>
                                </div>
                            </div>
                        </el-popover>

                        <el-popover placement="left" trigger="hover" :width="200" popper-class="my-popover-class">
                            <template #reference>
                                <div class="service-item">
                                    <img style="margin-top:6px;" :src="$img('home/youjian.png')" alt="">

                                    <div style="margin-top:3px;">Gmail</div>
                                </div>
                            </template>
                            <div class="tg-class" @click="copyUrl(lobbyInfo.CustomerInfo.customerMail)">
                                <div class="right">
                                    <span>{{ $t('官方邮箱') }}</span>
                                    <span>{{ lobbyInfo.CustomerInfo.customerMail }}</span>
                                </div>
                            </div>
                        </el-popover>

                        <el-popover placement="left" trigger="hover" :width="200" popper-class="my-popover-class">
                            <template #reference>
                                <div class="service-item">
                                    <img style="margin-top:6px;" :src="$img('home/dianhua.png')" alt="">

                                    <div style="margin-top:3px;">Phone</div>
                                </div>
                            </template>
                            <div class="tg-class" @click="copyUrl(lobbyInfo.CustomerInfo.customerTel[0])">
                                <div class="right">
                                    <span>{{ $t('客服热线') }}</span>
                                    <span>{{ lobbyInfo.CustomerInfo.customerTel[0] }}</span>
                                </div>
                            </div>
                        </el-popover>

                        <el-popover placement="left" trigger="hover" :width="450" popper-class="my-popover-class">
                            <template #reference>
                                <div class="service-item">
                                    <i class="iconfont icon-icon_floatbar_phone"></i>
                                    <span>TẢI APP</span>
                                </div>
                            </template>

                            <div class="qrcode-class">
                                <div class="qrcode-item">
                                    <vue-qr class="qr-img" :text="lobbyInfo.VersionGet.ios.UrlPath" :size="140"
                                        :margin='10'></vue-qr>
                                    <span>TẢI XUỐNG IOS</span>
                                </div>
                                <div class="qrcode-item">
                                    <vue-qr class="qr-img" :text="lobbyInfo.VersionGet.android.UrlPath" :size="140"
                                        :margin='10'></vue-qr>
                                    <span>TẢI XUỐNG ANDROID</span>
                                </div>
                            </div>
                        </el-popover>
                        <!-- <div class="service-item" @click="jumpToTop()">
                            <i class="iconfont icon-icon_download_btn"></i>
                            <span>Về đầu</span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="btns">
                <div class="x-btn" :class="aniMoveDir ? 'move_left' : 'move_right'" @click="aniMoveDir = !aniMoveDir">
                    <i class="iconfont " :class="aniMoveDir ? 'icon-icon_unfold' : 'icon-icon_fold'"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGetters, useActions } from '@/store/hooks/storeState/index'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { ElMessage } from "element-plus";

import log from '@/utils/logger';
import { ref } from 'vue';
import { bus } from '../../../components/core/bus';
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { chatList, redDot } = useGetters("chat", ["chatList", "redDot"]);
let aniMoveDir = ref(false);

const toUrl = (url) => {
    window.open(url)
}

function copyUrl(text) {
    if (!text) {
        return;
    }
    // 模拟 输入框
    let cInput = document.createElement("input");
    cInput.value = text;
    document.body.appendChild(cInput);
    // 选取文本框内容
    cInput.select();

    // 执行浏览器复制命令
    // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
    // Input要在正常的编辑状态下原生复制方法才会生效
    document.execCommand("copy");

    ElMessage({
        type: "success",
        message: "Sao chép thành công",
    });
    // 复制成功后再将构造的标签 移除
    document.body.removeChild(cInput);
}

function jumpToChat() {
    bus.emit(bus.event.chatState, true);
}
</script>

<style lang="scss" scoped>
.serviceFloat {
    position: fixed;
    width: 100px;
    right: 0;
    z-index: 100001;
    top: 50%;
    -webkit-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);

    .service {
        // min-height: 485px;
        width: 0;

        .service-item-wp {
            background-color: rgba(0, 0, 0, .4);
            padding: 20px 14px 1px;
            width: 100px;
            background: rgba(27, 35, 61);
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
            border-radius: 8px;
            z-index: 10;
            cursor: pointer;

            .itemsContent {
                transition: all .3s ease-in-out;

                .themes {
                    height: 22px;
                    width: 100%;
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-around;

                    .themeBtn {
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        border-radius: 10px;
                        border: 1px solid #a1a1a1;
                        transition: all .2s;
                    }

                    .black {
                        background-color: #000;
                    }

                    .white {
                        background-color: #fff;
                    }
                }

                .service-item {
                    background: rgba(60, 62, 66, .9);
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3), inset 0 3px 3px 0 hsla(0, 0%, 100%, .11), 0 2px 4px 0 rgba(0, 0, 0, .3);
                    border: none;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 16px;
                    width: 70px;
                    height: 70px;
                    border-radius: 12px;
                    position: relative;

                    i {
                        margin: 10px 18px 4px;
                        text-align: center;
                    }

                    .iconfont {
                        margin-top: 6px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 28px;
                        display: inline-block;
                        border-radius: 22px;
                        color: #fff;
                        border: 1px solid #fff;
                        background: linear-gradient(137deg, #a98fff 1%, #4a69ff 99%);
                    }

                    span {
                        font-size: 12px;
                    }

                    .el-badge {
                        margin: 0 0 4px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        vertical-align: middle;
                        text-align: center;

                        i {
                            margin: 0;
                            margin-top: 6px;
                            text-align: center;
                        }

                        .el-badge__content.is-fixed {
                            position: absolute;
                            top: 0;
                            right: 10px;
                            -webkit-transform: translateY(-50%) translateX(100%);
                            transform: translateY(-50%) translateX(100%);
                        }
                    }
                }

                .service-item:hover {
                    background-image: linear-gradient(137deg, #a98fff 1%, #4a69ff 99%);
                    color: #fff;
                }

                .chatroom {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }

    .btns {
        position: absolute;
        right: 0;
        width: 100px;

        .x-btn {
            background-color: rgba(0, 0, 0, .4);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            color: #fff;
            cursor: pointer;
            width: 100px;
            height: 48px;
            position: absolute;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
            border-radius: 8px;
            background: rgba(27, 35, 61, .5);

            i {
                color: #fff;
                opacity: .5;
                width: 24px;
                height: 24px;
                font-size: 24px;
            }
        }

        .move_left {
            -webkit-animation-name: move_left;
            animation-name: move_left;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }

        @keyframes move_left {
            0% {
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
                border-radius: 8px 0 0 8px;
                -webkit-transform: translateX(48px);
                transform: translateX(48px);
            }

            100% {
                width: 100px;
                height: 48px;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
                border-radius: 8px;
            }
        }

        .move_right {
            -webkit-animation-name: move_right;
            animation-name: move_right;
            -webkit-animation-duration: .5s;
            animation-duration: .5s;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
        }

        @keyframes move_left {
            0% {
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
                border-radius: 8px;
                right: 0;
            }

            100% {
                width: 38px;
                height: 76px;
                right: 0;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .3);
                border-radius: 8px 0 0 8px;
            }
        }
    }
}
</style>

<style lang = "scss">
.my-popover-class {
    padding: 0 !important;
    margin: 0 !important;

    .tg-class {
        padding: 10px 10px !important;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .left {

            img {
                width: 26px;
                height: 22px;
            }
        }

        .right {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            color: #fff;

            >span {
                font-size: 16px;
            }

            span+span {
                font-size: 14px;
                color: #4a69ff;
            }
        }
    }

    .qrcode-class {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 200px;
        border-radius: 8px;

        .qrcode-item:nth-child(1) {
            background: #528eff;
            margin-right: 10px;
        }

        .qrcode-item:nth-child(2) {
            background: #08a9d0;
        }

        .qrcode-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 200px;
            padding: 10px 10px 0;
            border-radius: 15px 15px 15px 15px;
            cursor: pointer;
            transition: all .2s;

            .qr-img {
                background: #fff;
                border-radius: 4px;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 40px;
                color: #fff;
                font-size: 14px;
                white-space: nowrap;
            }
        }

        .qrcode-item:hover {
            /* transform: scale(1.01); */
        }
    }
}
</style>