<template>
  <div>
    <h1 style="text-align: center;"><strong>HỢP TÁC ĐẠI LÝ</strong></h1>
    <p style="text-align: left;"><span style="">Chương trình đại
        lý:</span></p>
    <p style="text-align: left;"><span style="">10 ƯU ĐIỂM, CHƯƠNG
        TRÌNH ĐỐI TÁC - ĐẠI LÝ DUY NHẤT TRONG NGÀNH:</span></p>
    <p style="text-align: left;"><span style="">1. Độc quyền thanh toán
        hoa hồng đại lý theo ngày dựa trên tổng cược hợp lệ của thành viên.</span></p>
    <p style="text-align: left;"><span style="">2. Hoa hồng tối thiểu
        cho phương án cổ tức trực tiếp theo tháng là 50% (nghĩa là thành viên thua 100 và đại lý sẽ chia
        50)</span></p>
    <p style="text-align: left;"><span style="">3. Cơ chế phân cấp đại
        lý độc quyền duy nhất tại Zbet668. Cấp càng cao, càng có nhiều đặc quyền (Đại lý / Đối tác / Cổ
        đông)</span></p>
    <p style="text-align: left;"><span style="">4. Miễn phí ở khách sạn
        hạng sao (đi lại tự do-đối tác ở-Zbet668 chi trả)</span></p>
    <p style="text-align: left;"><span style="">5. Xe đưa đón miễn phí
        (vui lòng thương lượng với nhân viên phụ trách về khu vực đón khách)</span></p>
    <p style="text-align: left;"><span style="">6. Cung cấp hỗ trợ ưu
        đãi cho các đối tác-đại lý để phát triển tốt hơn nhiều thành viên hơn.</span></p>
    <p style="text-align: left;"><span style="">7. Mô hình hợp tác đa
        dạng (đối tác đại lý lựa chọn linh hoạt phương án thanh toán hoa hồng).</span></p>
    <p style="text-align: left;"><span style="">8. Yêu cầu đại lý đơn
        giản dễ dàng, chỉ với 2 thành viên hơp lệ cũng có thể nhận mức hoa hồng 60%.</span></p>
    <p style="text-align: left;"><span style="">9. Sản xuất các quảng
        cáo, áp phích, hình ảnh động và video sáng tạo mới nhất cho các đối tác đại lý mỗi tháng</span>
    </p>
    <p style="text-align: left;"><span style="">10.Tận hưởng hiệu ứng
        thương hiệu (đầu tư quảng cáo cố định dài hạn như:FaceBook,TIKTOK,IG,GOOGLE,YouTube)</span></p>
    <p style="text-align: left;"><span style="">Chúng tôi cung cấp cho
        bạn sự hỗ trợ nhiều mặt và một kế hoạch đại lý Với chính sách chi trả hoa hồng mới của Zbet668,
        quý đại lý sẽ được nhận hoa hồng vào cuối ngày và trả trực tiếp vào tài khoản game, có thể rút
        bất cứ lúc nào và không cần ràng buộc số vòng cược. Và các phương án hoa hồng thấp nhất là
        50%.</span></p>
    <p style="text-align: left;"><span style="">HỖ TRỢ ĐẠI LÝ- MIỄN PHÍ
        Ở KHÁCH SẠN, XE LIMOUSIN ĐƯA ĐÓN, HỖ TRỢ KHUYẾN MÃI (dịch vụ đầu tiên trong toàn mạng
        lưới)</span></p>
    <p style="text-align: left;"><span style="">LIÊN HỆ TRỰC TIẾP:
        Telegram : @dailyzbet hoặc Tel: 0523168168 để được hỗ trợ hoàn thành thủ tục đăng ký, sau khi
        được thông qua sẽ có nhân viên mở tài khoản đại lý và trang web liên kết cho bạn.</span></p>
    <p> </p>

  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>