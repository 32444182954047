<template>
  <div style="position: relative; padding-bottom: 00px; height: auto">
    <div style="width: 1400px; margin: 35px auto 0 auto">
      <img :src="$img('vip/banner_VIP.png')" alt="" style="width: 100%" />
    </div>
    <div style="position: relative; z-index: 9; padding-bottom: 100px">
      <div class="choose flexs_r_c" style="
          margin: 0 auto;
          text-align: center;
          width: 826px;
          margin-top: 62px;
        ">
        <img @click="left()" class="canClick" :src="$img('vip/img_zuo.png')" alt="" style="width: 23px; position: relative; margin-right: 126px" />
        <el-carousel ref="swiper" :loop="false" :interval="0" height="236px" indicator-position="none" arrow="never" style="width: 552px">
          <el-carousel-item v-for="item in vipBGs" :key="item">
            <div style="position: relative">
              <img :src="$img('vip/' + item.img)" alt="" style="width: 100%" />
              <div style="
                  position: absolute;
                  bottom: 60px;
                  left: 60px;
                  text-align: left;
                ">
                <!-- lobbyInfo.ActivityVipConf[0]['Vip'+(index1+1)] -->
                <div class="whiteWord bigSize" style="color: #f3f3f3">
                  {{ $t("积分") }} >={{
                    $tools.formatNum(
                      lobbyInfo.ActivityVipConf.tableConf[0][
                        "Vip" + selectIndex
                      ]
                    )
                  }}
                </div>
                <div class="whiteWord bigSize" style="color: #f3f3f3; margin-top: 10px">
                  {{ $t("存款") }} >={{
                    $tools.formatNum(
                      lobbyInfo.ActivityVipConf.tableConf[1][
                        "Vip" + selectIndex
                      ]
                    )
                  }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <img @click="right()" class="canClick" :src="$img('vip/img_you.png')" alt="" style="width: 23px; position: relative; margin-left: 126px" />
      </div>
      <div class="flexs_r_c" style="
          width: 305px;
          margin: 0 auto;
          margin-top: 0px;
          position: relative;
          z-index: 9999;
        ">
        <div v-for="(item, index) in vipBGs" class="indicator" :class="selectIndex == index ? 'select' : 'nolmal'"></div>
      </div>

      <div style="
          width: 1400px;
          margin: 0 auto;
          margin-top: 100px;
          padding: 0 20px;
        ">
        <div style="text-align: center; margin-top: 40px">
          <img :src="$img('vip/ul1.png')" alt="" style="margin-right: 15px; width: 254px; vertical-align: middle" />
          <span style="color: rgb(233, 216, 140); vertical-align: middle" class="bigSize">{{ $t("账户总览") }}</span>
          <img :src="$img('vip/ur1.png')" alt="" style="width: 254px; margin-left: 15px; vertical-align: middle" />
        </div>
        <div v-html="
            lobbyInfo.ActivityVipConf.vipConf &&
            lobbyInfo.ActivityVipConf.vipConf.ContentPc
          " style="
            margin-top: 106px;
            white-space: break-spaces;
            word-break: break-all;
          "></div>
        <div style="position: relative; width: 100%; margin: 40px auto">
          <div class="flexs_r_c" style="
              width: 100%;
              background: linear-gradient(to right, #4f3ab7, #9032b8);
            ">
            <div v-for="(item, index) in 7" :class="{ borderRight: index != titleArr.length - 1 }" class="header middleSize boldSize">
              {{ titleArr[index] }}
            </div>
          </div>

          <div style="width: 100%; background-color: red" class="flexs_r" v-if="lobbyInfo">
            <div class="flexs_colum borderRight" style="width: calc(14.28% + 1px)">
              <div class="colum middleSize" style="color: rgb(233, 216, 140)" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{ "VIP " + index1 }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(14.28% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  $tools.formatNum(
                    lobbyInfo.ActivityVipConf.tableConf[0]["Vip" + index1]
                  )
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(14.28% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  $tools.formatNum(
                    lobbyInfo.ActivityVipConf.tableConf[1]["Vip" + index1]
                  )
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(14.28% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  $tools.formatNum(
                    lobbyInfo.ActivityVipConf.tableConf[2]["Vip" + index1]
                  )
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(14.28% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  $tools.formatNum(
                    lobbyInfo.ActivityVipConf.tableConf[3]["Vip" + index1]
                  )
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(14.28% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  $tools.formatNum(
                    lobbyInfo.ActivityVipConf.tableConf[4]["Vip" + index1]
                  )
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(14.28% - 0px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                <div v-if="index1 < 6">-</div>
                <div v-else>✓</div>
              </div>
            </div>
          </div>
        </div>

        <div style="position: relative; width: 100%; margin: 40px auto">
          <div class="flexs_r_c" style="
              width: 100%;
              background: linear-gradient(to right, #4f3ab7, #9032b8);
            ">
            <div v-for="(item, index) in 8" :class="{ borderRight: index != titleArr2.length - 1 }" class="header middleSize boldSize" style="width: calc(12.5% + 1px)">
              {{ titleArr2[index] }}
            </div>
          </div>

          <div style="width: 100%; background-color: red" class="flexs_r" v-if="lobbyInfo">
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" style="color: rgb(233, 216, 140)" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{ "VIP " + index1 }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  lobbyInfo.ActivityVipConf.tableConf[7]["Vip" + index1] + "%"
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  lobbyInfo.ActivityVipConf.tableConf[8]["Vip" + index1] + "%"
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  lobbyInfo.ActivityVipConf.tableConf[6]["Vip" + index1] + "%"
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  lobbyInfo.ActivityVipConf.tableConf[5]["Vip" + index1] + "%"
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  lobbyInfo.ActivityVipConf.tableConf[9]["Vip" + index1] + "%"
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  lobbyInfo.ActivityVipConf.tableConf[12]["Vip" + index1] + "%"
                }}
              </div>
            </div>
            <div class="flexs_colum borderRight" style="width: calc(12.5% + 1px)">
              <div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
                {{
                  lobbyInfo.ActivityVipConf.tableConf[10]["Vip" + index1] + "%"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VxeTablePropTypes } from "vxe-table";

export default {
  components: {},

  data() {
    return {
      selectIndex: 0,
      titleArr: [
        this.$t("贵宾级别"),
        this.$t("有效投注"),
        this.$t("总充值"),
        this.$t("有效投注仍然存在"),
        this.$t("升级奖金"),
        this.$t("每月奖金"),
        this.$t("贵宾护理专员"),
      ],
      titleArr2: [
        this.$t("贵宾级别"),
        this.$t("老虎机"),
        this.$t("捕鱼"),
        this.$t("体育"),
        this.$t("真人视讯"),
        this.$t("棋牌"),
        "ZWIN",
        this.$t("斗鸡"),
      ],
      headerType: [
        "BetNeed",
        "ChargeNeed",
        "KeepGradeNeed",
        "VipGetGold",
        "WeekGet",
      ],
      vipBGs: [
        {
          img: "VIP0.png",
        },
        {
          img: "VIP1.png",
        },
        {
          img: "VIP2.png",
        },
        {
          img: "VIP3.png",
        },
        {
          img: "VIP4.png",
        },
        {
          img: "VIP5.png",
        },
        {
          img: "VIP6.png",
        },
        {
          img: "VIP7.png",
        },
        {
          img: "VIP8.png",
        },
        {
          img: "VIP9.png",
        },
        {
          img: "VIP10.png",
        },
        {
          img: "VIP11.png",
        },
        {
          img: "VIP12.png",
        },
        {
          img: "VIP13.png",
        },
        {
          img: "VIP14.png",
        },
        {
          img: "VIP15.png",
        },
        {
          img: "VIP16.png",
        },
        {
          img: "VIP17.png",
        },
        {
          img: "VIP18.png",
        },
        {
          img: "VIP19.png",
        },
        {
          img: "VIP20.png",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("global", ["lobbyInfo"]),
  },
  created() { },
  methods: {
    left() {
      if (this.selectIndex > 0) {
        this.selectIndex -= 1;
      }
      this.$refs.swiper.prev();
    },
    right() {
      //console.log(33333)
      if (this.selectIndex < 20) {
        this.selectIndex += 1;
      }
      //console.log(this.selectIndex)
      this.$refs.swiper.next();
    },
  },
};
</script>

<style scoped lang="scss">
.indicator {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 5px;
}

.select {
  background-color: #e9a12e;
}

.nolmal {
  background-color: #2f2f2f;
}

.header {
  color: white;
  width: 14.28%;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.colum {
  // width: 100%;
  color: white;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.borderRight {
  border-right: 1px solid black;
}

.double {
  background-color: #17171e;
}

.single {
  background-color: #101113;
}
</style>
<style>
table {
  border-top: 1px solid white;
  border-left: 1px solid white;
}

table td,
table th {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  padding: 3px 5px;
}

table th {
  border-bottom: 1px solid white;
  text-align: center;
}
</style>
