<template>
  <div class="chat">
    <div class="content-container">
      <div class="header" v-drag>
        <div>
          <i class="iconfont icon-icon_nav_chatroom_nor leftI"></i>
          <span>Phòng chat</span>
        </div>
        <div>
          <i class="iconfont icon-icon_customer_service leftI serviceI" @click="toUrl(lobbyInfo.CustomerInfo.customerLiveChat)"></i>
          <i class="iconfont icon-icon_close_dark closeI" @click="close"></i>
        </div>
      </div>
      <div class="notice">
        <div class="noticeBar">
          <div class="icon">
            <i class="iconfont icon-icon_announcement"></i>
            <span>Thông báo:</span>
          </div>
          <marquee behavior="scroll" direction="left" truespeed scrollamount="3">
            <span v-for="item in noticeObj.content">
              <span>{{ item }}&nbsp;&nbsp;&nbsp;</span>
            </span>
          </marquee>
        </div>
      </div>
      <div v-if="pinnedList.length > 0 && pinnedObj.show" class="pinned">
        <div class="pinned-msg-bar">
          <div class="label">Ghim :</div>
          <div class="msg-wp">
            <div v-for="(item, index) in pinnedList" class="msg-item-wp">
              <div class="img-wp" @click="pinnedItemClick(item)">
                <img :src="item.appendInfo.normalImg">
              </div>
            </div>
            <div class="iconfont icon-icon_close_24" @click="pinnedObj.show = false"></div>
          </div>
        </div>
      </div>
      <div class="body" id="bodyId" @scroll="bodyScrollHandler" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
        <ul v-if="!loading" id="chatLogListId">
          <li v-for="(item, index) in chatList">
            <span id="li-flag-id" v-text="setLiFlag(item)" style="display:none"></span>
            <div v-if="(item.msgType == msgType.MSG_TEXT || item.msgType == msgType.MSG_IMAGE)" class="chatLogItem">
              <div class="msgItem" :class="isSelf(item) ? 'userMsg' : ''">
                <div class="avatar">
                  <div>
                    <img v-if="isSystem(item.fromUid) || !item.fromUser.Avatar" :src="require('@/assets/pc/chat/mb2p.png')" class="avatar">
                    <img v-else :src="require('@/assets/common/avatar/' + item.fromUser.Avatar + '.png')" class="avatar">
                  </div>
                </div>
                <div class="msgContent level" :class="item.vipLevel <= 8 ? 'level' + item.vipLevel : 'level8'">
                  <div class="nameImg">
                    <template v-if="isSystem(item.fromUid)">
                      <img src="@/assets/pc/chat/img_vip_gl_1.png" alt="" class="levelImg">
                      <img src="@/assets/pc/chat/img_vip_gl_2.png" alt="" class="levelImgAngle levelImgAngleGl">
                      <span class="nickname">Zbet668：</span>
                      <span class="message">{{
                                                item.content }}
                      </span>
                    </template>
                    <template v-else>
                      <img v-show="item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_1.png')" alt="" class="levelImg">
                      <img v-show="!isSelf(item) && item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_2.png')" alt="" class="levelImgAngle">
                      <span class="nickname">{{ formatName(item.fromUser.NickName) }}：</span><span class="message">{{
                                                    item.content }}
                      </span>
                    </template>
                  </div>
                  <div class="message" v-if="item.msgType == msgType.MSG_IMAGE">
                    <el-image :src="item.appendInfo.normalImg" fit="cover" :preview-src-list="[item.appendInfo.normalImg]" :initial-index="0" :zoom-rate="1.2" />
                  </div>
                  <div class="shareOrder" v-if="item.msgType == msgType.MSG_LOTTERY_BET">
                    <div class="orderItem">
                      <div>
                        <div class="itemWrap">
                          <div class="item lotteyName">{{
                                                        getLotteyName(item.appendInfo)
                                                    }}
                          </div>
                          <div class="item">{{ item.appendInfo.Number }}</div>
                          <div class="item play">
                            <span> Tên kiểu cược：</span>
                            <div>{{ getLotteyPlay(item.appendInfo) }}</div>
                          </div>
                          <div class="item itemContent">
                            <span> Đặt cược： </span>
                            <div>{{ getLotteyType(item.appendInfo.Code) }}</div>
                          </div>
                          <div class="item betNum">
                            <span> Số lần cược： </span>
                            <div class="greenText">{{ getLotteyBetCount(item.appendInfo.Code) }}
                            </div>
                          </div>
                          <div class="item betMoney">
                            <span> Tổng tiền cược： </span>
                            <div class="greenText">{{ item.appendInfo.TotalAmount }}</div>
                          </div>
                        </div>
                        <el-button class="betBtn" @click="showBetInfoDialog(item.appendInfo)">
                          <span>Cược theo ngay</span>
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <p class="time">{{ $tools.formatDate(item.createAt, "yyyy-MM-dd") }}</p>
                </div>
              </div>
            </div>
            <div v-else-if="item.msgType == msgType.MSG_LOTTERY_BET" class="chatLogItem">
              <div class="msgItem" :class="isSelf(item) ? 'userMsg' : ''">
                <div class="avatar">
                  <div>
                    <img v-if="item.appendInfo.isRobot || !item.fromUser.Avatar" :src="require('@/assets/common/avatar/' + item.appendInfo.Avatar + '.png')" class="avatar">
                    <img v-else :src="require('@/assets/common/avatar/' + item.fromUser.Avatar + '.png')" class="avatar">
                  </div>
                </div>
                <div class="msgContent level" :class="(item.appendInfo.isRobot ? item.appendInfo.vipLevel : item.vipLevel) <= 8 ? 'level' + (item.appendInfo.isRobot ? item.appendInfo.vipLevel : item.vipLevel) : 'level8'">
                  <div v-if="item.appendInfo.isRobot" class="nameImg">
                    <img v-show="item.appendInfo.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.appendInfo.vipLevel + '_1.png')" alt="" class="levelImg">
                    <img v-show="!isSelf(item) && item.appendInfo.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.appendInfo.vipLevel + '_2.png')" alt="" class="levelImgAngle">
                    <span class="nickname">{{ formatName(item.appendInfo.NickName) }}：</span><span class="message">{{
                                                item.content }}
                    </span>
                  </div>
                  <div v-else class="nameImg">
                    <img v-show="item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_1.png')" alt="" class="levelImg">
                    <img v-show="!isSelf(item) && item.vipLevel > 0" :src="require('@/assets/common/vip/vip' + item.vipLevel + '_2.png')" alt="" class="levelImgAngle">
                    <span class="nickname">{{ formatName(item.fromUser.NickName) }}：</span><span class="message">{{
                                                item.content }}
                    </span>
                  </div>
                  <div class="message" v-if="item.msgType == msgType.MSG_IMAGE">
                    <el-image :src="item.appendInfo.normalImg" fit="cover" :preview-src-list="[item.appendInfo.normalImg]" :initial-index="0" :zoom-rate="1.2" />
                  </div>
                  <div class="shareOrder" v-if="item.msgType == msgType.MSG_LOTTERY_BET">
                    <div class="orderItem">
                      <div>
                        <div class="itemWrap">
                          <div class="item lotteyName">{{
                                                        getLotteyName(item.appendInfo)
                                                    }}
                          </div>
                          <div class="item">{{ item.appendInfo.Number }}</div>
                          <div class="item play">
                            <span> Tên kiểu cược：</span>
                            <div>{{ getLotteyPlay(item.appendInfo) }}</div>
                          </div>
                          <div class="item itemContent">
                            <span> Đặt cược： </span>
                            <div>{{ getLotteyType(item.appendInfo.Code) }}</div>
                          </div>
                          <div class="item betNum">
                            <span> Số lần cược： </span>
                            <div class="greenText">{{ getLotteyBetCount(item.appendInfo.Code) }}
                            </div>
                          </div>
                          <div class="item betMoney">
                            <span> Tổng tiền cược： </span>
                            <div class="greenText">{{ item.appendInfo.TotalAmount }}</div>
                          </div>
                        </div>
                        <el-button class="betBtn" @click="showBetInfoDialog(item.appendInfo)">
                          <span>Cược theo ngay</span>
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <p class="time">{{ $tools.formatDate(item.createAt, "yyyy-MM-dd") }}</p>
                </div>
              </div>
            </div>
            <div v-else-if="item.msgType == msgType.MSG_REDEVNELOP_TIP" class="chatRedRecord">
              <span class="name">{{ formatName(item.appendInfo.nickName) }}&nbsp;</span>
              <span class="recordText2"> Chúc mừng bạn nhận được lì xì</span>
              <span class="redRecordMoney">{{ item.appendInfo.vndBalance }}</span>
            </div>
            <div v-else-if="item.msgType == msgType.MSG_LOTTERY_PLAY_RECOMMEND" class="changLong">
              <div class="chatLogItem">
                <div class="avatar">
                  <div>
                    <img v-if="isSystem(item.fromUid) || !item.fromUser.Avatar" :src="require('@/assets/pc/chat/mb2p.png')" class="avatar">
                    <img v-else :src="require('@/assets/common/avatar/' + item.fromUser.Avatar + '.png')" class="avatar">
                  </div>
                </div>
                <div class="main">
                  <div class="title">
                    <span class="lotteyName">{{
                                            getLotteyName(item.appendInfo)
                                        }}</span>
                    <span class="issue">{{ item.appendInfo.Number }}</span>
                  </div>
                  <div class="logItem">
                    <div class="playName">
                      <span class="names" tabindex="0">{{ getLotteyPlay(item.appendInfo)
                                            }}</span>
                      <span class="plays1">{{ item.appendInfo.Code }}</span>
                    </div>
                    <div class="logItemInfo">
                      <div class="openInfo"><span class="openIssue">{{ item.appendInfo.KeepNum
                                            }}Kỳ</span>
                        <span class="openState">liên tiếp</span>
                      </div>
                      <div class="timeInfo">
                        <span v-if="item.appendInfo.lessTime < 0" class="close">Đang đóng
                          kèo</span>
                        <i v-if="item.appendInfo.lessTime >= 0" class="iconfont icon-icon_alarm">
                          <span>{{ new Date(item.appendInfo.lessTime).Format("mm: ss")
                                                    }}</span>
                        </i>
                      </div>
                    </div>
                    <div class="playItem">
                      <span class="plays active" @click="showBetTypeDialog(item.appendInfo, 0)"> {{
                                                getBetTypeText(item.appendInfo)[0] }}
                      </span>
                      <span class="plays active" @click="showBetTypeDialog(item.appendInfo, 1)"> {{
                                                getBetTypeText(item.appendInfo)[1] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="nav">
          <span>Hiển thị đơn cược của tôi</span>
          <el-switch v-model="showMybet" @change="myBetSwitchChange($event)" class="ml-2" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #78788052" />
          <span>Chặn tất cả đơn cược</span>
          <el-switch v-model="stopAllBet" @change="allBetSwitchChange($event)" class="ml-2" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #78788052" />
        </div>
        <div>
          <div class="chatInput">
            <span class="sendPic">
              <el-upload @click="openUpload()" :disabled="!uploadObj.disabled" class="avatar-uploader" action="https://f.88bet88bet.com/group1/upload" :data="commitObj" list-type="picture" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <span class="commonIcon iconfont icon-icon_send_photo"></span>
              </el-upload>
            </span>
            <div class="inputWrap">
              <div placeholder="Nhập nội dung chat tại đây..." contenteditable="true" class="edit-div" id="ed-input" @keydown.enter.native.prevent="sendMsg()">
              </div>
              <span>
                <i class="iconfont icon-icon_send_emoji" @click="showEmoji = !showEmoji"></i>
              </span>
            </div>
            <span>
              <span class="sendMsg" @click="sendMsg()"> Gửi </span>
            </span>
          </div>
        </div>
        <div v-if="scroollObj.showBottonBtn" class="goBottomDiv">
          <i class="iconfont icon-icon_get_back"></i>
        </div>
      </div>
      <div v-if="redBagObj.show && redBag.state == 0" class="redBagFix">
        <div class="wrapper">
          <div class="item">
            <img :src="$img('chat/redBag4.png')" alt="">
            <div class="detail faildDetail">
              <div class="title">Xin lỗi</div>
              <div class="title faild"></div>
            </div>
            <div class="ball errorBall" @click='closeRedBagFix(redBag.state)'>
              <i class="iconfont icon-icon_floatbar_close"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="redBagObj.show && redBag.state == 1" class="redBagFix">
        <div class="wrapper">
          <div class="item">
            <img :src="$img('chat/redBag4.png')" alt="">
            <div class="detail">
              <div class="title">Chúc mừng bạn nhận được lì xì</div>
              <div class="money">{{ redBag.money }}</div>
            </div>
            <div class="ball errorBall" @click='closeRedBagFix(redBag.state)'>
              <i class="iconfont icon-icon_floatbar_close"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="redBagObj.show && redBag.state == 2" class="redBagFix">
        <div class="redWrap">
          <img :src="$img('chat/redBag3.png')" alt="" class="redBag3">
          <p class="redBagP">Nhận lì xì miễn phí</p>
          <div class="openBtn" @click="robRedBag()">
            <span> Mở </span>
            <img :src="$img('chat/red_quanquan.png')" alt="" class="redBag2">
          </div>
          <div class="ball" @click='closeRedBagFix(redBag.state)'>
            <i class="iconfont icon-icon_floatbar_close"></i>
          </div>
        </div>
      </div>
      <div v-if="redBag.show" class="redBagAniBox">
        <div class="aniBox" @click="openRedBag()">
          <img src="@/assets/pc/chat/red_eff_img.png" alt="" class="fireworks fireworksAni">
          <img src="@/assets/pc/chat/red_eff_bg.png" alt="" class="redBagBg redBagBgAni">
          <img src="@/assets/pc/chat/red_icon.png" alt="" class="redBag redBagAni2">
          <div class="redBagTxt1 redBagTxtLoopAni">
            <!-- <div class="mask"></div> -->
          </div>
          <div class="redBagTxt2 redBagTxtLoopAni">
            <!-- <div class="mask"></div> -->
          </div>
        </div>
        <div v-if="redBag.state == 1" class="closeBtn" @click='closeRedBagFix(redBag.state)'>
          <i class="iconfont icon-icon_close_24"></i>
        </div>
      </div>
      <div v-if="showEmoji" class="emoji-layout">
        <div>
          <p class="group">Smileys &amp; Emotion</p>
          <span v-for="(item, index) in chatEmojiList" class="item" @click="chooseEmoji(item)">{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="pinned-dialog">
    <el-dialog v-model="pinnedObj.showDialog" title="Ghim lên đầu">
      <div class="img-wp">
        <img :src="pinnedObj.data.appendInfo.normalImg" alt="">
      </div>
      <div class="msg">
        <span>{{ pinnedObj.data.content }}</span>
      </div>
    </el-dialog>
  </div>
  <div class="upload-dialog">
    <el-dialog v-model="uploadObj.showDialog" title="Xem trước hình ảnh" :close="handleClose">
      <div class="upload-div">
        <img class="upload-img" :src="uploadObj.localUrl" alt="">
      </div>
      <el-input class="input" v-model="uploadObj.input" placeholder="Thêm hình ảnh mô tả" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="uploadObj.showDialog = false">Huỷ</el-button>
          <el-button type="primary" @click="uploadToServe()">
            Gửi hình ảnh
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <div class="bet-info-dialog">
    <el-dialog v-model="betInfoObj.showDialog" title="Thông tin cược" :width="360" style="margin-top:20vh;border-radius: 8px;" @close="betInfoObj.showDialog = false">
      <div class="orderPopup">
        <div class="body">
          <div class="item">
            <span>{{ getLotteyName(betInfoObj.data) }}</span>
          </div>
          <div class="item">
            <span>{{ getLotteyPlay(betInfoObj.data) }}</span>
          </div>
          <div class="item">
            <table class="table">
              <thead>
                <tr>
                  <th>Đặt cược</th>
                  <th>Tiền cược</th>
                </tr>
              </thead>
              <tr>
                <td class="item1">{{ getLotteyType(betInfoObj.data.Code) }}</td>
                <td class="item2">
                  <el-input v-model="betInfoObj.money" type="number"></el-input>
                </td>
              </tr>
            </table>
          </div>
          <div class="item">
            <span>Số lần cược：</span>
            <div class="info">{{ getLotteyBetCount(betInfoObj.data.Code) }}</div>
          </div>
          <div class="item">
            <span>Tổng cược:</span>
            <div class="info">{{ betInfoObj.money }}</div>
          </div>
        </div>
        <div class="footer">
          <span class="btn cencel" @click="betInfoObj.showDialog = false">Huỷ</span>
          <span class="btn confim" @click="betLottey('betInfo')">Đặt cược
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
  <div class="bet-type-dialog">
    <el-dialog v-model="betTypeObj.showDialog" :draggable="true" title="Thông tin cược" :width="450" style="margin-top:20vh;border-radius: 8px;" @close="betTypeObj.showDialog = false">
      <div class="orderPopup">
        <div class="header"></div>
        <div class="body">
          <div class="item">
            <span class="gameLable">Tên trò chơi:</span>
            <span class="gameTitle">{{ getLotteyName(betTypeObj.data) }}</span>
          </div>
          <div class="item">
            <span class="gameLable">Kiểu cược:</span>
            <span class="gameTitle">{{ getLotteyPlay(betTypeObj.data) }}</span>
          </div>
        </div>
        <div class="trendBox">
          <div class="kenoTableData whiteBg">
            <div class="kenoTableTitle">
              <div class="titles circle0">
                <span class="lable" style="background: rgb(249, 65, 104);">{{
                                    getBetTypeText(betTypeObj.data)[0] }}</span>
                <el-progress type="circle" :width="50" :percentage="getLotteyPersent(betTypeObj.data.Code == 'big' ? 0 : 2)" :stroke-width="5" :color="'#F94168'">
                  <template #default="{ percentage }">
                    <span class="percentage-value">{{ getLotteyPersent(betTypeObj.data.Code == 'big' ? 0
                                            : 2) }}%</span>
                  </template>
                </el-progress>

              </div>
              <div class="titles circle1">
                <span class="lable" style="background: rgb(38, 161, 255);">{{
                                    getBetTypeText(betTypeObj.data)[1] }}</span>
                <el-progress type="circle" :width="50" :percentage="getLotteyPersent(betTypeObj.data.Code == 'small' ? 0
                                    : 3)" :stroke-width="5" color="rgb(38, 161, 255)">
                  <template #default="{ percentage }">
                    <span class="percentage-value">{{ getLotteyPersent(betTypeObj.data.Code == 'small' ?
                                            0
                                            : 3) }}%</span>
                  </template>
                </el-progress>
              </div>
            </div>
            <div class="tableData">
              <div class="tableItem">
                <div v-for="(item, index) in 18" class="tableDataList">
                  <div v-for="(item1, index1) in betTypeObj.betTypeData[index]" class="list">
                    <span v-if="item1 == 'big' || item1 == 'single'" class="items" style="border-color: rgb(249, 65, 104); background: rgb(249, 65, 104);">{{
                                                getBetTypeText(betTypeObj.data)[2] }}
                    </span>
                    <span v-else class="items" style="border-color: rgb(38, 161, 255); background: rgb(38, 161, 255);">{{
                                                getBetTypeText(betTypeObj.data)[3] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="playOdd">
          <div class="playOddList">
            <div class="titles">
              <div class="items" :class="betTypeObj.chooseIndex == 0 ? 'active' : ''" @click="betTypeObj.chooseIndex = 0">
                <span class="lable">{{ getBetTypeText(betTypeObj.data)[0] }}</span>
                <span class="odd">{{ getLotteyOdds(betTypeObj.data) }}</span>
              </div>
            </div>
            <div class="titles">
              <div class="items" :class="betTypeObj.chooseIndex == 1 ? 'active' : ''" @click="betTypeObj.chooseIndex = 1">
                <span class="lable">{{ getBetTypeText(betTypeObj.data)[1] }}</span>
                <span class="odd">{{ getLotteyOdds(betTypeObj.data) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="timeIssue">
          <!-- <div class="timeCountDown"> -->
          <!-- <i class="iconfont icon-icon_alarm" style="display: none;"></i> -->
          <!-- <span data-v-6550df68=""> 00: 00: 36 </span> -->
          <!-- <span class="close">Đang đóng kèo</span> -->
          <!-- </div> -->
          <div class="issueNum">
            <span>Kỳ:</span>
            <span>{{ betTypeObj.data.Number }}</span>
          </div>
        </div>
        <div class="footer">
          <div class="btn cencel" @click="betTypeObj.showDialog = false">
            Huỷ
          </div>
          <div class="price">
            <el-input v-model="betTypeObj.money"></el-input>
          </div>
          <div class="btn confim colse" @click="betLottey('betType')">Đặt cược</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { mqant_lottery } from "@/components/protocol/mqantlib";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import md5 from 'js-md5'
import drag from '@/directives/drag'
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { uuid } = useGetters("global", ["uuid"]);
let { isConnect } = useGetters("tcp_lottery", ["isConnect"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { info } = useGetters("tcp", ["info"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const { Lotteries, Plays, isGetConfig } = useGetters("tcp_lottery", ["Lotteries", "Plays", "isGetConfig"]
);
const { chatList, pinnedList } = useGetters("chat", ["chatList", "pinnedList"]);
const { redBag } = useGetters("chat", ["redBag"]);
const { setRedDot } = useMutations("chat", ["setRedDot"]);
const { chatConf } = useGetters("tcp", ["chatConf"]);
let commitObj = reactive({ scene: 'chatRoom/zbet', filename: "" });
const msgType = reactive({
  MSG_TEXT: "MSG_TEXT",      // 文字消息
  MSG_IMAGE: "MSG_IMAGE",   // 图片消息
  MSG_REDEVNELOP: "MSG_REDEVNELOP", // 红包推送
  MSG_REDEVNELOP_UPDATE: "MSG_REDEVNELOP_UPDATE",//红包更新
  MSG_LOTTERY_BET: "MSG_LOTTERY_BET",  // 下注消息
  MSG_LOTTERY_PLAY_RECOMMEND: "MSG_LOTTERY_PLAY_RECOMMEND", // 推荐下注玩法
  MSG_LOTTERY_PLAY: "MSG_LOTTERY_PLAY",// 玩法推荐
  MSG_REDEVNELOP_TIP: "MSG_REDEVNELOP_TIP"//红包消息
})
log.info('redBag---', redBag.value)
//表情
let showEmoji = ref(false);
const chatEmojiData = require("@/utils/emoji.json");
const chatEmojiList = reactive([]);
for (let i in chatEmojiData) {
  chatEmojiList.push(chatEmojiData[i].char);
}
//跑马灯
let noticeObj = reactive({
  content: ['✅Chào mừng quý khách đến với Zbet668 🎁Giật lì xì mỗi ngày tại phòng chat lên đến 6,8 Tỷ VND']
})
// if (localStorage.getItem('localMybetState') == null) {
//     //第一次未存储时默认为打开
//     localStorage.setItem('localMybetState', true);
// }
const showMybet = ref(chatConf.value.shareLotteryBet);
const stopAllBet = ref(JSON.parse(localStorage.getItem('stopAllbetState')));
//置顶图片
let pinnedObj = reactive({
  show: true,//默认打开，通过pinnedList来控制显示
  showDialog: false,
  data: {}
})
//上传图片
let uploadObj = reactive({
  showDialog: false,
  disabled: isLogged.value && info.value.VipLevel >= 2,
  input: '',
  localUrl: '',
  severUrl: '',
})
//下注信息
let betInfoObj = reactive({
  showDialog: false,
  data: {},
  money: '',
})
//下注大小单双信息
let betTypeObj = reactive({
  showDialog: false,
  data: {},
  betTypeData: [],
  doubleSingleData: [],
  money: '',
  chooseIndex: 0,
})
//滚动底部
let scroollObj = reactive({
  isScrollToBottom: true,//默认滚动到底部
  showBottonBtn: false,//下拉按钮
})
onMounted(() => {
  setRedDot(false);
})
onBeforeUnmount(() => {
  setRedDot(true);
  globelTimeDown && clearInterval(globelTimeDown);
})

bus.off(bus.event.chatListPush);
bus.on(bus.event.chatListPush, () => {
  nextTick(() => {
    refreshListAll();
    scrollToBottom();
  })
  if (!scroollObj.isScrollToBottom) return;
})
//红包
let redBagObj = reactive({
  show: false
});
bus.off(bus.event.chatNewRedbag);
bus.on(bus.event.chatNewRedbag, () => {
  redBagObj.show = false;
  // ElMessage.success('Tiền lì xì đây');
})
const loading = ref(true);
//彩票配置
let recordData = [];
let globelTimeDown = null;
if (isGetConfig.value) {
  getLottryConfig();
} else {
  bus.on('getLottery', () => {
    //获取到彩票票后才能显示
    getLottryConfig();
  })
}
globelTimeDown = setInterval(() => {
  for (let key in chatList.value) {
    let info = chatList.value[key];
    if (info.msgType == msgType.MSG_LOTTERY_PLAY_RECOMMEND) {
      let nowTime = new Date().getTime();
      let openTime = new Date(info.appendInfo.OpenTime).getTime();
      if (openTime - nowTime < 0) {
        info.appendInfo.lessTime = -1;
      } else {
        info.appendInfo.lessTime = openTime - nowTime;
      }
    }
  }
}, 1000)
function isSystem(fromUid) {
  return (fromUid == '' || fromUid == '000000000000000000000000');
}
function getLottryConfig() {
  loading.value = false;
  nextTick(() => {
    refreshListAll();
    scrollToBottom(true);
  })
}
function getLotteyName(item) {
  if (Object.keys(Lotteries.value).length <= 0) return;
  let seachList = Lotteries.value[item.AreaCode];
  let desObj = seachList.find(e => e.LotteryCode == item.LotteryCode)
  return desObj.LotteryName;
}
function getLotteyPlay(item) {
  if (Object.keys(Plays.value).length <= 0) return;
  let payStr1 = '';
  let payStr2 = '';
  let seachList = Plays.value[item.AreaCode];
  let desObj = seachList.find(e => e.PlayCode == item.PlayCode);
  payStr1 = `[${desObj.Name}]`
  let subPlayList = desObj.SubPlays;
  let desObj2 = subPlayList.find(e => e.SubPlayCode == item.SubPlayCode);
  payStr2 = desObj2.SubName;
  return payStr1 + payStr2;
}
function getLotteyOdds(item) {
  if (Object.keys(Plays.value).length <= 0) return;
  let seachList = Plays.value[item.AreaCode];
  let desObj = seachList.find(e => e.PlayCode == item.PlayCode);
  let subPlayList = desObj.SubPlays;
  let desObj2 = subPlayList.find(e => e.SubPlayCode == item.SubPlayCode);
  return desObj2.Odds / 1000;
}
function getLotteyType(item) {
  if (item == "big") {
    return "Tài";
  } else if (item == "small") {
    return "Xỉu";
  } else if (item == "single") {
    return "Lẻ";
  } else if (item == "pair") {
    return "Chẵn";
  } else if (item == "up") {
    return "Trên";
  } else if (item == "draw") {
    return "Hoà";
  } else if (item == "down") {
    return "Dưới";
  } else if (item == "jin") {
    return "Kim";
  } else if (item == "mu") {
    return "Mộc";
  } else if (item == "shui") {
    return "Thủy";
  } else if (item == "huo") {
    return "Hỏa";
  } else if (item == "tu") {
    return "Thổ";
  } else if (item == "bigSingle") {
    return "Tài Lẻ";
  } else if (item == "smallSingle") {
    return "Xỉu Lẻ";
  } else if (item == "bigPair") {
    return "Tài Chẵn";
  } else if (item == "smallPair") {
    return "Xỉu Chẵn";
  } else {
    return item;
  }

}

function getLotteyBetCount(item) {
  var regex = new RegExp('-', 'g');
  var result = item.match(regex);
  var count = !result ? 0 : result.length;
  return count + 1;
}
function openRedBag() {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  if (info.value.VipLevel < 1) {
    ElMessage.warning('cần vip1！');
    return;
  }
  redBagObj.show = true;
}
function closeRedBagFix(state) {
  redBagObj.show = false;
  //已领取过的弹窗关闭后不再显示
  if (state == 0 || state == 1) {
    redBag.value.show = false;
  }
}
function robRedBag() {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  if (info.value.VipLevel < 1) {
    ElMessage.warning('cần vip1！');
    return;
  }
  let params = {
    redEnvelopId: redBag.value.data.Oid,
  }
  log.info('robRedBag-params', params);
  mqant.request(topic.robRedEnvelop, params, function (data, topicName, msg) {
    log.info('robRedEnvelop--', data);
    if (data.Code == 0) {
      // ElMessage({
      //     message: data.ErrMsg,
      //     type: "success",
      // });
    } else {
      ElMessage({
        message: data.ErrMsg,
        type: "error",
      });
    }
  })
}
function scrollToBottom(bool = false) {
  nextTick(() => {
    let bodyElement = document.getElementById('bodyId');
    if (!bodyElement) return;
    if (bool) {
      bodyElement.scrollTo({ top: bodyElement.scrollHeight });
    } else {
      bodyElement.scrollTo({ top: bodyElement.scrollHeight, behavior: 'smooth' });
    }
  })
}
function bodyScrollHandler(event) {
  // log.info(event.target.scrollTop);
  // if (event.target.scrollTop > 150) {
  //     scroollObj.isScrollToBottom = false;
  //     scroollObj.showBottonBtn = true;
  // }
}
function textareaKeydown(event) {
  event.preventDefault() // 阻止浏览器默认换行操作
}
function setLiFlag(item) {
  //做一下下注单的标记，方便隐藏或显示
  let resultStr = 'normal';
  if (item.msgType == msgType.MSG_LOTTERY_BET) {
    resultStr = msgType.MSG_LOTTERY_BET;
  }
  return resultStr;
}
function isSelf(item) {
  let nickName = item.fromUser.NickName
  return nickName === userInfo.value.Account;
}

function formatName(name) {
  if (!name || name.length < 2) {
    return name;
  }
  if (name.length == 2) {
    return '*' + name[name.length - 1];
  }
  return name[0] + new Array(name.length - 1).join('*') + name[name.length - 1];
}
function myBetSwitchChange(event) {
  if (info.value.VipLevel < 1) {
    showMybet.value = !showMybet.value;
    ElMessage.warning('cần vip1！');
    return;
  }
  let params = {
    showLotteryBet: event
  }
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  mqant.request(topic.chatChangePush, params, function (data, topicName, msg) {
    if (data.Code == 0) {
      chatConf.value.shareLotteryBet = event;
    }
    else {
    }
  })

}
function allBetSwitchChange(event) {
  localStorage.setItem('stopAllbetState', event);
  refreshListAll();
  scrollToBottom(true);
}

function refreshListAll() {
  let element = document.getElementById('chatLogListId');
  if (!element) return;
  for (let i = 0; i < element.children.length; i++) {
    let info = element.children[i];
    let liFlag = info.firstChild;
    if (stopAllBet.value) {
      if (liFlag.outerText && liFlag.outerText == msgType.MSG_LOTTERY_BET) {
        info.style.display = 'none';
      } else {
        info.style.display = 'block';
      }
    } else {
      info.style.display = 'block';
    }
  }
}

function toUrl(url) {
  window.open(url);
}

function close() {
  bus.emit(bus.event.chatState, false);
}
function send(msgType, content, normalImg = '') {
  let params = {
    groupId: "chatRoom",
    msgType: msgType,
    content: content,
    msgId: md5(uuid + Date.parse(new Date()).toString()),
    appendInfo: {
      normalImg: normalImg,
    }
  }
  if (tools.HTTPIsBoolean(content)) {
    ElMessage.warning('Vui lòng nhập nội dung hợp lệ');
    return;
  }
  // log.info('chat-params=----', params);
  mqant.request(topic.chatSend, params, function (data, topicName, msg) {
    if (data.Code == 0) {

    } else {
      ElMessage.error(data.ErrMsg);
    }
    // log.info('chat-data=----', data);
  })
}
function sendMsg() {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    uploadObj.disabled = true;
    return;
  }
  if (info.value.VipLevel < 1) {
    ElMessage.warning('cần vip1！');
    return;
  }

  let textElement = document.getElementById('ed-input');
  let textStr = textElement.innerHTML;
  if (!textStr) {
    ElMessage.warning('Vui lòng nhập nội dung！');
    return;
  }
  send(msgType.MSG_TEXT, textStr);
  textElement.innerHTML = ''
}

function uploadToServe() {
  if (info.value.VipLevel < 2) {
    ElMessage.warning('cần vip2！');
    return;
  }
  send(msgType.MSG_IMAGE, uploadObj.input, uploadObj.severUrl);
  uploadObj.input = '';
  uploadObj.showDialog = false;
}

function chooseEmoji(item) {
  let textElement = document.getElementById('ed-input');
  textElement.innerHTML += item;
  showEmoji.value = false;
}

function openUpload() {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    uploadObj.disabled = true;
    return;
  }
  if (info.value.VipLevel < 2) {
    ElMessage.warning('cần vip2！');
    return;
  }
}
const handleAvatarSuccess = (
  response,
  uploadFile
) => {
  uploadObj.showDialog = true;
  uploadObj.severUrl = response;
  uploadObj.localUrl = URL.createObjectURL(uploadFile.raw);
}

const beforeAvatarUpload = (rawFile) => {
  if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/gif')) {
    ElMessage.error('Hình ảnh đại diện phải có định dạng!')
    return false
  } else if (rawFile.size / 1024 > 500) {
    ElMessage.error('Kích thước ảnh chân dung không thể vượt quá nửa triệu!')
    return false
  }
  commitObj.filename = rawFile.name;
  return true
}

function showBetInfoDialog(data) {
  betInfoObj.data = data;
  betInfoObj.money = data.TotalAmount;
  betInfoObj.showDialog = true;
}

async function showBetTypeDialog(data, index) {
  if (data.lessTime < 0) {
    ElMessage.warning('Hết hạn');
    return;
  };
  betTypeObj.data = data;
  betTypeObj.money = data.TotalAmount ? data.TotalAmount : '1000';
  betTypeObj.chooseIndex = index;
  await getLotteRecord(data);
  if (recordData.length <= 0) return;
  betTypeObj.betTypeData = changeTypeData(data, recordData);
  betTypeObj.showDialog = true;
}
function getBetTypeText(data) {
  let [title1, title2, title3, title4] = ['', '', '', ''];
  if (data.Code == "big" || data.Code == "small") {
    title1 = 'Tài';
    title2 = 'Xỉu';
    title3 = 'T';
    title4 = 'X';
  }
  else if (data.Code == "single" || data.Code == "pair") {
    title1 = 'Lẻ';
    title2 = 'Chẵn';
    title3 = 'L';
    title4 = 'C';
  }
  return [title1, title2, title3, title4];
}

function getLotteyPersent(type) {
  //0大 1小 2单 3双
  var arr = betTypeObj.betTypeData;
  var count = 0;
  var total = 0;

  for (var i = 0; i < (arr.length >= 16 ? 16 : arr.length); i++) {
    var tmpArr = arr[i];

    for (var j = 0; j < tmpArr.length; j++) {
      var str = tmpArr[j];
      total++;

      if (type == 0) {
        if (str == "big") {
          count++;
        }
      } else if (type == 1) {
        if (str == "small") {
          count++;
        }
      } else if (type == 2) {
        if (str == "single") {
          count++;
        }
      } else if (type == 3) {
        if (str == "double") {
          count++;
        }
      } else if (type == 4) {
        if (str == "top") {
          count++;
        }
      } else if (type == 5) {
        if (str == "center") {
          count++;
        }
      } else if (type == 6) {
        if (str == "bottom") {
          count++;
        }
      } else if (type == 7) {
        if (str == "jin") {
          count++;
        }
      } else if (type == 8) {
        if (str == "mu") {
          count++;
        }
      } else if (type == 9) {
        if (str == "shui") {
          count++;
        }
      } else if (type == 10) {
        if (str == "huo") {
          count++;
        }
      } else if (type == 11) {
        if (str == "tu") {
          count++;
        }
      }
    }
  }
  return Math.round((count / total) * 100);
}
function changeTypeData(data, recordData) {
  if (data.Code == 'big' || data.Code == 'small') {
    return getBigSmall(recordData)
  } else if (data.Code == 'single' || data.Code == 'pair') {
    return getDoubleSingle(recordData)
  }
}
function getBigSmall(data) {
  //>881大  1-40超过10个上 41-80>10个 下  和 1-40等于10
  var index = 0;
  var indexArr = [];
  var lastStr;
  var tmpArr = [];
  for (var i = 0; i < data.length; i++) {
    var total = 0;
    var arr = data[i].OpenCode.L0;

    for (var num of arr) {
      total += parseInt(num);
    }

    var str;
    if (total >= 811) {
      str = "big";
    } else {
      str = "small";
    }
    if (lastStr == str) {
      if (indexArr.length < 6) {
        indexArr.push(str);
        tmpArr[index] = indexArr;
      } else {
        indexArr = [];
        index++;
        indexArr.push(str);
        tmpArr[index] = indexArr;
      }
    } else {
      indexArr = [];
      if (lastStr) {
        index++;
      }
      indexArr.push(str);
      tmpArr[index] = indexArr;
    }
    lastStr = str;
  }

  if (tmpArr.length > 18) {
    tmpArr = tmpArr.slice(0, 18);
  }
  var arrs = [];
  for (var i = tmpArr.length - 1; i >= 0; i--) {
    arrs.push(tmpArr[i]);
  }

  return arrs;
}
function getDoubleSingle(data) {
  var index = 0;
  var indexArr = [];
  var tmpArr = [];

  var lastStr;
  for (var i = 0; i < data.length; i++) {
    var total = 0;
    var arr = data[i].OpenCode.L0;
    for (var num of arr) {
      total += parseInt(num);
    }

    var str;
    if (total % 2 == 0) {
      str = "double";
    } else {
      str = "single";
    }
    if (lastStr == str) {
      if (indexArr.length < 6) {
        indexArr.push(str);
        tmpArr[index] = indexArr;
      } else {
        indexArr = [];
        index++;
        indexArr.push(str);
      }
    } else {
      indexArr = [];
      if (lastStr) {
        index++;
      }
      indexArr.push(str);
    }
    tmpArr[index] = indexArr;

    lastStr = str;
  }
  if (tmpArr.length > 16) {
    tmpArr = tmpArr.slice(0, 16);
  }
  var arrs = [];
  for (var i = tmpArr.length - 1; i >= 0; i--) {
    arrs.push(tmpArr[i]);
  }
  return arrs;
}
function getLotteRecord(data) {
  return new Promise((resolve, reject) => {
    let obj = {
      LotteryCode: data.LotteryCode,
      limit: 100,
    };
    recordData = [];
    mqant_lottery.request(topic.record, obj, function (data, topicName, msg) {
      if (data.Code == 0) {
        recordData = data.Data;
        resolve(true);
      }
      else {
        reject();
      }
    })
  })
}
function betLottey(type) {
  let targetObj = {}
  if (type == 'betInfo') {
    targetObj = betInfoObj;
  } else if (type == 'betType') {
    targetObj = betTypeObj;
  }
  if (Number(targetObj.money) <= 0) {
    ElMessage.warning('Vui lòng nhập số tiền hợp lệ');
    return;
  }
  if (Number(targetObj.money) < targetObj.data.UnitBetAmount) {
    ElMessage.warning('Đặt cược tối thiểu' + targetObj.data.UnitBetAmount);
    return;
  }
  let obj = {
    TotalAmount: Number(targetObj.money),
    LotteryCode: targetObj.data.LotteryCode,
    SubPlayCode: targetObj.data.SubPlayCode,
    Code: targetObj.data.Code,
    UnitBetAmount: type == 'betType' ? Number(targetObj.money) : targetObj.data.UnitBetAmount,
    CProfit: targetObj.data.CProfit,
  }
  if (type == 'betType') {
    if (obj.Code == 'big' || obj.Code == 'small') {
      if (betTypeObj.chooseIndex == 0) {
        obj.Code == 'big';
      } else {
        obj.Code == 'small';
      }
    } else if (obj.Code == 'single' || obj.Code == 'pair') {
      if (betTypeObj.chooseIndex == 0) {
        obj.Code == 'single';
      } else {
        obj.Code == 'pair';
      }
    }
  }
  log.info("addBet2-obj--", obj);
  mqant_lottery.request(topic.addBet2, obj, function (data, topicName, msg) {
    log.info('topic.addBet2', data)
    if (data.Code == 0) {
      ElMessage.success(data.ErrMsg);
      betInfoObj.showDialog = false;
      betTypeObj.showDialog = false;
    }
    else {
      ElMessage.error(data.ErrMsg);
    }
  });
}
function pinnedItemClick(item) {
  pinnedObj.data = item;
  pinnedObj.showDialog = true;
}
</script>

<style lang="scss" scoped>
.chat {
  position: absolute;
  top: 120px;
  left: 1000px;
  width: 375px;
  height: 700px;
  z-index: 2000 !important;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #292e3b;

  .content-container {
    position: relative;
    width: 100% !important;
    height: 100% !important;

    .header {
      background: #1e242c;
      color: #fff;
      height: 50px;
      box-shadow: 0 0.33px 0 0 hsla(0, 0%, 100%, 0.15);
      line-height: 50px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      font-size: 16px;
      cursor: pointer;

      div {
        display: flex;
        align-items: center;

        span {
          margin-left: 8px;
        }

        .leftI {
          font-size: 24px;
        }

        .serviceI {
          margin-right: 20px;
        }

        .closeI {
          font-size: 19px;
        }
      }
    }

    .notice {
      background: #363d4e;
      color: #fff;
      position: absolute;
      top: 50px;
      z-index: 999;
      height: 28px;
      width: 100%;
      line-height: 28px;
      padding: 0 16px;
      font-size: 12px;

      .noticeBar {
        overflow: hidden;
        display: flex;
        align-items: center;
        width: 100%;

        .icon {
          display: flex;
          align-items: center;
          // width: 90px;
          font-size: 12px;
          color: #e9cfa4;

          i {
            width: 16px;
            font-size: 16px;
            color: #e9cfa4;
            margin-right: 5px;
          }

          span {
            white-space: nowrap;
          }
        }
      }
    }

    .pinned {
      position: absolute;
      width: 100%;
      z-index: 999;
      top: 78px;

      .pinned-msg-bar {
        display: flex;
        height: 50px;
        font-size: 12px;
        overflow: hidden;
        box-sizing: border-box;
        opacity: 0.9;
        cursor: pointer;
        background: linear-gradient(105deg, #7146ff, #4a69ff);

        .label {
          padding-left: 15px;
          display: flex;
          align-items: center;
          width: 70px;
          color: #e9cfa4;
          font-weight: 400;
        }

        .msg-wp {
          display: flex;
          align-items: center;
          width: calc(100% - 100px);
          overflow-x: auto;
          overflow-y: hidden;

          .msg-item-wp {
            margin-right: 10px;

            .img-wp {
              display: flex;
              align-items: center;
              padding: 5px 0;

              img {
                width: 34px;
                height: 34px;
                border-radius: 4px;
                margin-right: 4px;
              }
            }
          }

          .icon-icon_close_24 {
            position: absolute;
            right: 8px;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
          }
        }
      }
    }

    .body {
      background: #292e3b;
      width: 100%;
      position: absolute;
      top: 50px;
      bottom: 112px;
      padding: 80px 0 20px;
      color: #606266;
      font-size: 12px;
      overflow-y: auto;

      ul {
        li {
          margin: 25px 0;

          .chatLogItem {
            padding: 0 20px;

            .msgItem {
              position: relative;
              display: flex;
              width: 100%;

              .avatar {
                width: 40px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                margin-top: 2px;

                > div {
                  width: 32px;
                  height: 32px;
                  border-radius: 16px;
                  border: 1px solid hsla(0, 0%, 79.6%, 0.325);
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                img {
                  max-width: 32px;
                  max-height: 32px;
                  width: auto;
                  height: auto;
                }
              }

              .msgContent {
                position: relative;
                background: rgba(51, 255, 255, 0.1);
              }

              .level {
                border-radius: 8px;
                max-width: 260px;
                background: #34416d;
              }

              .level1 {
                background: rgba(51, 60, 102, 0.4);
              }

              .level2 {
                background: rgba(51, 255, 51, 0.1);
              }

              .level3 {
                background: rgba(51, 255, 255, 0.1);
              }

              .level4 {
                background: rgba(51, 119, 255, 0.1);
              }

              .level5 {
                background: rgba(255, 187, 51, 0.1);
              }

              .level6 {
                background: rgba(51, 85, 255, 0.1);
              }

              .level7 {
                background: rgba(255, 153, 51, 0.1);
              }

              .level8 {
                background: rgba(255, 119, 51, 0.1);
              }

              .level9 {
              }

              .nameImg {
                color: #8491a5;
                line-height: 22px;
                padding: 6px 20px 6px 6px;
                font-size: 14px;

                .levelImg {
                  height: 22px;
                  margin-right: 8px;
                  vertical-align: middle;
                }

                .levelImgAngle {
                  position: absolute;
                  right: -15px;
                  bottom: 0;
                  width: 32px;
                  height: 22px;
                }

                .levelImgAngleGl {
                  right: -11px;
                  width: 22px;
                  height: 22px;
                }

                .nickname {
                  color: #fff;
                }

                .message {
                  color: #b3ffff;
                  word-break: break-all;
                  word-wrap: break-word;
                  font-size: 12px;
                  text-align: left;
                }

                span {
                  vertical-align: middle;
                }
              }

              .message {
                word-break: break-all;
                word-wrap: break-word;
                font-size: 12px;
                text-align: left;

                :deep(.el-image) {
                  max-height: 140px;
                  padding: 0 10px 10px;

                  img {
                    max-height: 140px;
                    border-radius: 4px;
                    margin-top: 5px;
                  }
                }
              }

              .shareOrder {
                display: inline-block;
                width: 100%;

                .orderItem {
                  background: #363d4e;
                  color: #fff;
                  border-radius: 0 0 8px 8px;
                  font-size: 12px;
                  display: flex;
                  justify-content: space-around;
                  flex-direction: column;

                  .itemWrap {
                    padding: 10px;

                    .item {
                      display: flex;
                      align-items: center;
                      min-height: 20px;
                    }

                    .itemContent {
                      span {
                        display: inline-block;
                      }

                      div {
                        flex: 1;
                        word-break: break-all;
                        text-align: left;
                      }
                    }

                    .greenText {
                      color: #21e06b;
                    }
                  }
                }

                .betBtn {
                  width: 100%;
                  height: 28px px;
                  padding: 0;
                  background: #90a2dc;
                  color: #fff;
                  border-radius: 0 0 8px 8px;
                  border: 0;
                }
              }

              .time {
                color: rgba(132, 145, 165, 0.6);
                margin-top: 6px;
                position: absolute;
                bottom: -15px;
                left: 0;
                width: 100%;
              }

              p {
                letter-spacing: 0;
                line-height: 15px;
                font-weight: 400;
              }
            }

            .userMsg {
              flex-direction: row-reverse;

              .nameImg {
                flex-direction: row-reverse;
                border-radius: 8px 0 8px 8px;
                padding: 6px;
              }
            }
          }

          .changLong {
            margin: 6px 0;

            .chatLogItem {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0 !important;

              .avatar {
                width: 32px;
                align-self: flex-start;
                padding-top: 4px;

                img {
                  width: 32px;
                  height: 32px;
                  border-radius: 16px;
                }
              }

              .main {
                padding: 0 8px;
                display: inline-block;
                width: 100%;

                .title {
                  color: #8491a5;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  letter-spacing: 0;
                  line-height: 20px;
                  margin-bottom: 4px;
                  font-weight: 400;

                  .lotteyName {
                    height: 20px;
                    line-height: 20px;
                    margin-right: 5px;
                  }
                }

                .logItem {
                  background: #363d4e;
                  max-width: 264px;
                  word-wrap: break-word;
                  border-radius: 0 8px 8px 8px;
                  color: #fff;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  font-size: 14px;
                  line-height: 20px;
                  height: 146px;
                  width: 264px;

                  .playName {
                    background: #8491a5;
                    display: flex;
                    align-items: center;
                    height: 30px;
                    width: 100%;
                    border-radius: 0 8px 0 0;
                    padding: 0 12px;

                    .names {
                      font-size: 12px;
                      color: #292e3b;
                    }

                    .plays1 {
                      border: 0.83px solid #fd3;
                      border-radius: 10px;
                      font-size: 10px;
                      color: #ffd60a;
                      height: 20px;
                      line-height: 20px;
                      text-align: center;
                      padding: 0 10px;
                      margin-left: 5px;
                    }
                  }

                  .logItemInfo {
                    padding: 0 12px;
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    align-items: center;

                    .openInfo {
                      background: #292e3b;
                      height: 54px;
                      background: #1e2747;
                      border-radius: 10px;
                      width: 120px;
                      display: flex;
                      padding: 5px;
                      flex-direction: column;
                      justify-content: space-around;
                      align-items: center;

                      .openIssue {
                        font-size: 19px;
                        color: #ffd60a;
                      }

                      .openState {
                        font-size: 13px;
                        color: #ffd60a;
                        opacity: 0.7;
                      }
                    }

                    .timeInfo {
                      background: #292e3b;
                      width: 102px;
                      height: 30px;
                      border: 1px solid #ff375f;
                      border-radius: 15px;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      .close {
                        font-size: 12px;
                        color: #ff375f;
                      }

                      i {
                        margin-right: 5px;
                      }

                      .icon-icon_alarm {
                        font-size: 15px;
                        color: #ff375f;
                      }

                      span {
                        margin-left: 5px;
                      }
                    }
                  }

                  .playItem {
                    padding: 0 12px;
                    height: 40px;
                    border-top: 1px solid rgba(159, 180, 249, 0.1);
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .plays {
                      color: #fff;
                      background: #292e3b;
                      border-radius: 4px;
                      font-size: 14px;
                      min-width: 44px;
                      text-align: center;
                      opacity: 0.8;
                      cursor: pointer;
                    }

                    .plays:hover {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }

          .chatRedRecord {
            line-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            font-size: 13px;

            .name {
              color: #fada2f;
            }

            .recordText2 {
              color: #8491a5;
            }

            .redRecordMoney {
              color: #f92855;
            }
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;

      .nav {
        background: #363d4e;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 12px;
        width: 100%;
        height: 40px;

        span {
          margin-left: 12px;
          margin-right: 10px;
          min-width: 120px;
          white-space: nowrap;
          text-align: center;
        }

        :deep(.el-switch) {
          .el-switch__core {
            min-width: 30px;
            height: 14px;
          }
        }
      }

      .chatInput {
        height: 56px;
        display: flex;
        margin: 0 16px 16px;
        align-items: center;
        background: #fff;
        box-sizing: border-box;
        border-radius: 8px;

        .sendPic {
          cursor: pointer;
          display: inline-block;
          width: 46px;
          text-align: center;

          .iconfont {
            font-size: 24px;
            color: #90a2dc;
          }
        }

        .inputWrap {
          position: relative;

          #ed-input {
            border: 1px solid #dcdfe6;
          }

          .edit-div {
            overflow-y: auto;
            word-break: break-all;
            outline: none;
            -webkit-user-modify: read-write-plaintext-only;
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
            white-space: pre-wrap;
            text-align: left;
            font-size: 14px;
            padding: 5px 27px 5px 5px;
            width: 223px;
            height: 32px;
            line-height: 20px;
            background: #e7e7e7;
            border-radius: 16px;
            color: #000;
          }

          .edit-div[contenteditable="true"]:empty:before {
            content: attr(placeholder);
            display: block;
            line-height: 20px;
            color: #ccc;
          }

          .icon-icon_send_emoji {
            position: absolute;
            top: 6px;
            right: 9px;
            font-size: 20px;
            color: #90a2dc;
            cursor: pointer;
          }
        }

        .sendMsg {
          cursor: pointer;
          display: inline-block;
          width: 74px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          font-size: 15px;
          color: #6e87d8;
          font-weight: 600;
        }
      }

      .goBottomDiv {
        position: absolute;
        bottom: 130px;
        right: 16px;
        cursor: pointer;

        i {
          font-size: 32px;
          color: #5e6c9b;
        }
      }
    }

    .redBagFix {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      z-index: 9;
      justify-content: center;

      .redWrap {
        position: relative;
        text-align: center;

        .redBag3 {
          width: 90%;
        }

        .redBagP {
          min-width: 175.1px;
          text-align: center;
          position: absolute;
          left: 26%;
          top: 24%;
          font-size: 18px;
          background: linear-gradient(180deg, #fff6bb, #ffe87c);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }

        .openBtn {
          cursor: pointer;
          width: 78px;
          height: 78px;
          position: absolute;
          left: 40%;
          bottom: 9%;
          transition: all 200s linear;

          span {
            color: #fff6bb;
            font-size: 30px;
            position: absolute;
            top: 24px;
            left: 18px;
            user-select: none;
          }

          .redBag2 {
            width: 100%;
            height: 100%;
          }
        }
      }

      .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .item {
          width: 90%;
          position: relative;

          .faildDetail {
            top: 14% !important;
          }

          .detail {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 11%;

            .title {
              width: 156px;
              margin-left: 94px;
              font-size: 16px;
              text-align: center;
              background: linear-gradient(180deg, #fff6bb, #ffe87c);
              background-clip: text;
              -webkit-background-clip: text;
              color: transparent;
              font-weight: 600;
            }

            .money {
              margin-top: 10px;
              font-size: 26px;
              color: #fddfaf;
              text-align: center;
              line-height: 37px;
              font-weight: 600;
            }

            .faild {
              font-size: 16px;
              margin-top: 10px;
            }
          }
        }

        img {
          width: 100%;
        }
      }

      .ball {
        cursor: pointer;
        position: absolute;
        left: 44%;
        bottom: -18%;
        width: 47px;
        height: 47px;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        i {
          color: #fff;
          font-size: 22px;
        }
      }

      .errorBall {
        bottom: -54px;
        left: 146px;
      }
    }

    .redBagAniBox {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 20px;
      bottom: 230px;
      width: 90px;
      height: 90px;

      .aniBox {
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        .fireworks {
          transform: scale(0);
          opacity: 1;
          position: absolute;
          width: 90px;
        }

        .fireworksAni {
          animation: fireworksAniKf 1s ease-in both;
        }

        @keyframes fireworksAniKf {
          100% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
            opacity: 0;
          }
        }

        .redBagBg {
          position: absolute;
          width: 65px;
          opacity: 0;
        }

        .redBagBgAni {
          opacity: 1;
          animation: redBagBgAniKf 3s linear infinite both;
        }

        @keyframes redBagBgAniKf {
          100% {
            transform: rotate(1turn);
          }
        }

        .redBag {
          position: absolute;
          width: 50px;
        }

        .redBagAni2 {
          animation: redBagAni2Kf 3s ease infinite both;
        }

        @keyframes redBagAni2Kf {
          10% {
            -webkit-transform: scale(1.1) rotate(0);
            transform: scale(1.1) rotate(0);
          }

          12% {
            -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);
          }

          15% {
            -webkit-transform: scale(1.2) rotate(0);
            transform: scale(1.2) rotate(0);
          }

          20% {
            -webkit-transform: scale(1) rotate(-5deg);
            transform: scale(1) rotate(-5deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
          }

          40% {
            -webkit-transform: scale(1) rotate(5deg);
            transform: scale(1) rotate(5deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
          }

          60% {
            -webkit-transform: scale(1) rotate(-5deg);
            transform: scale(1) rotate(-5deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
          }

          80% {
            -webkit-transform: scale(1) rotate(5deg);
            transform: scale(1) rotate(5deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
          }
        }
      }

      .closeBtn {
        position: absolute;
        top: 1px;
        color: #ccc;
        right: 10px;
      }

      .redBagTxt1 {
        position: absolute;
        width: 260px;
        height: 90px;
        bottom: -20px;
        background-size: 260px 90px;
        transform: scale(0.28);
        overflow: hidden;
        background-image: url("@/assets/pc/chat/red_title_1.png");
      }

      .redBagTxtLoopAni {
        animation: redBagTxtLoopAniKf 1.5s linear infinite both;
      }

      @keyframes redBagTxtLoopAniKf {
        60% {
          transform: scale(0.29);
        }
      }

      .redBagTxt2 {
        position: absolute;
        width: 260px;
        height: 90px;
        bottom: -20px;
        background-size: 260px 90px;
        transform: scale(0.28);
        overflow: hidden;
        background-image: url("@/assets/pc/chat/red_title_2.png");
      }

      .mask {
        width: 90px;
        height: 85px;
        background-color: #fff;
        opacity: 0.5;
        transform: translateX(-90px);
        animation: maskAniKf 1s linear 2s infinite both;
      }

      @keyframes maskAniKf {
        100% {
          transform: translateX(160px);
        }
      }
    }

    .emoji-layout {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 70px;
      width: 340px;
      height: 410px;
      padding: 2px 4px;
      overflow: auto;
      background: #fff;
      border: 1px solid #dcdfe6;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);

      .group {
        padding: 5px 12px 0;
        color: #80868a;
        font-size: 14px;
      }

      .item {
        display: inline-block;
        width: 28px;
        height: 28px;
        padding: 4px;
        font-size: 28px;
        cursor: pointer;
        transition: all 0.3s;
      }

      .item:hover {
        transform: scale(1.5);
      }
    }
  }
}

.pinned-dialog {
  :deep(.el-dialog) {
    width: 500px;
    min-height: 160px;
    box-shadow: none;
    position: relative;
    margin: 0 auto 50px;
    margin-top: 15vh;
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;

    .el-dialog__body {
      padding: 20px;
      color: #90a2dc;
      font-size: 14px;
      word-break: break-all;
    }

    .el-dialog__close {
      font-size: 20px;
    }
  }

  :deep(.el-dialog__title) {
    line-height: 24px;
    font-size: 18px;
    color: #90a2dc;
  }

  .img-wp {
    text-align: center;

    img {
      max-width: 460px;
      max-height: 460px;
    }
  }

  .msg {
    font-size: 16px;
    margin: 8px 12px;
    color: #555;
    line-height: 20px;
    text-align: center;

    span {
    }
  }
}

.upload-dialog {
  :deep(.el-dialog) {
    width: 540px;
  }

  .upload-div {
    margin: 0px auto 20px auto;
    text-align: center;

    .upload-img {
      max-width: 500px;
      max-height: 300px;
    }
  }

  .input {
    width: 60%;
    margin: 0 auto;

    :deep(.el-input) {
    }
  }
}

.bet-info-dialog {
  .orderPopup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #1b233d;

    .body {
      .item {
        color: #1b233d;
        font-weight: 400;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;

        span {
          margin-right: 16px;
        }

        table {
          width: 100%;
          border-spacing: 0;
          border: 1px solid #1b233d;
          border-radius: 4px;
          border-collapse: collapse;

          thead {
            border-bottom: 1px solid #1b233d;
            color: #1b233d;

            tr {
              border-bottom: none;
            }
          }

          th {
            border-right: 1px solid #1b233d;
            border-bottom: 1px solid #1b233d;
            padding: 10px;
            text-align: center;
          }

          td {
            border-right: 1px solid #1b233d;
            padding: 10px;
            text-align: center;
          }

          tr:last-child td {
            border-bottom: none;
          }
        }

        .item1 {
          width: 60%;
        }

        .item2 {
          width: 40%;

          .el-input {
            position: relative;
            font-size: 14px;
            display: inline-block;
            width: 100%;
          }

          .el-input__inner:focus {
            outline: none;
            border-color: #e9cfa4;
          }

          .el-input__inner {
            -webkit-appearance: none;
            background-color: #fff;
            background-image: none;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            color: #90a2dc;
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: none;
            padding: 0 15px;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;
          }
        }

        .info {
          color: #21e06b;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .btn {
        flex: 1;
        height: 40px;
        line-height: 40px;
        border: 1px solid #1b233d;
        border-radius: 4px;
        color: #1b233d;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
      }

      .confim {
        margin-left: 24px;
        background-image: linear-gradient(90deg, #7146ff, #4a69ff);
        color: #fff;
        border: none;
      }
    }
  }
}

.bet-type-dialog {
  :deep(.el-dialog__body) {
    padding: 0 0 24px;
    color: #90a2dc;
    font-size: 14px;
    word-break: break-all;
  }

  :deep(.el-dialog__title) {
    text-align: center;
    line-height: 24px;
    font-size: 20px;
    color: #90a2dc;
  }

  .orderPopup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #1b233d;
    width: 450px !important;

    .header {
      text-align: center;
      color: #1b233d;
      height: 2px;
      box-shadow: inset 0 -0.5px 0 0 rgba(144, 162, 220, 0.5);
    }

    .body {
      padding: 24px 24px 12px;

      .item {
        font-size: 15px;
        color: #1b233d;
        font-weight: 400;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;

        .gameLable {
          width: 100px;
          text-align: right;
        }

        span {
          margin-right: 16px;
        }
      }
    }

    .trendBox {
      padding: 0 30px;

      .kenoTableData {
      }

      .whiteBg {
        background: 0 0;
      }

      .kenoTableTitle {
        display: flex;
        padding: 5px 12px 0;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .percentage-value {
          white-space: nowrap;
          font-size: 18px;
          color: rgb(96, 98, 102);
        }

        .titles {
          display: flex;
          flex: 1;
          align-items: center;
          width: 30%;
          min-width: 30%;
          max-width: 30%;
          margin-bottom: 0;
          justify-content: center;

          .lable {
            background: #f94168;
            border: 1px solid;
            border-radius: 2px;
            font-size: 12px;
            color: #fff;
            text-align: center;
            padding: 5px;
            margin-right: 8px;
          }
        }
      }

      .tableData {
        padding: 5px 0;
        display: flex;
        justify-content: flex-end;

        .tableItem {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;

          .tableDataList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-left: 5.5px;
            flex: 1;

            .list {
              margin-bottom: 4px;
              display: flex;
              position: relative;

              .items {
                width: 16px;
                height: 16px;
                font-size: 10px;
                color: #fff;
                flex: 1;
                font-weight: 400;
                display: inline-block;
                text-align: center;
                line-height: 18px;
                position: relative;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .playOdd {
      .playOddList {
        display: flex;
        padding: 10px 12px 0;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;

        .titles {
          display: flex;
          flex: 1;
          align-items: center;
          width: 30%;
          height: 48px;
          min-width: 30%;
          max-width: 30%;
          margin-bottom: 16px;
          justify-content: center;

          .items {
            padding: 5px;
            display: flex;
            height: 48px;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            background: #9fb4f9;
            border-radius: 29px;
            border: 2px solid #c3d0fb;
            width: 100%;
            cursor: pointer;

            .lable {
              font-size: 16px;
              color: #fbfbfb;
              text-align: center;
            }

            .odd {
              font-size: 12px;
              color: #fbfbfb;
              text-align: center;
            }
          }

          .active {
            background-image: linear-gradient(135deg, #7146ff, #4a69ff);
            border: 2px solid #a891ff;
          }
        }
      }
    }

    .timeIssue {
      height: 40px;
      background: rgba(0, 0, 0, 0.05);
      display: flex;
      padding: 0 24px;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .timeCountDown {
        width: 102px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ff375f;
        border-radius: 15px;

        .icon-icon_alarm {
          font-size: 15px;
          color: #ff375f !important;
        }

        .close {
          font-size: 12px;
          color: #ff375f !important;
        }
      }

      .issueNum {
        font-size: 15px;
        color: #1b233d;
        // margin-left: 10px;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      margin-bottom: 24px;

      .btn {
        flex: 1;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #fbfbfb;
        text-align: center;
        font-weight: 400;
        width: 96px;
        background: #78849e;
        border-radius: 4px;
        cursor: pointer;
      }

      .price {
        flex: 2;
        width: 96px;
        margin: 0 10px;
      }

      :deep(.el-input__wrapper) {
        text-align: center;

        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #90a2dc;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: none;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }

      .colse {
        background: #78849e;
      }

      .confim {
        width: 156px;
        background-image: linear-gradient(90deg, #7146ff, #4a69ff);
        color: #fff;
        border: none;
        flex: 2;
      }
    }
  }
}
</style>