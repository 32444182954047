<template>
  <div @click="handleClose()" class="wrap" style="width: 100%;height: 100%;position: fixed;top: 0;left: 0;z-index: 2000;background-color: rgba(0, 0, 0, .4);overflow:scroll;">
    <div style="width:90vw;margin:100px auto;margin-bottom:0;" @click.stop="" class="flexs_r_c">
      <div style="position:relative;">
        <div>
          <img @click="handleClose()" class="canClick" :src="$img('home/' + 'btn_gb.png')" alt="" style="width: 30px; position: absolute;right:0;top:-20px;z-index: 999;">
        </div>

        <img :src="$img('home/' + 'bg_db.png')" alt="" style="width: 100%;" />
        <div style="position:absolute;left:0;top:0;width:100%;">
          <div style="position: relative;padding:0 24px;margin-top:40px;text-align:center;">
            <div class="flexs_sb">
              <div style="text-align:center;">
                <div style="font-size:12px;zoom:calc(9/12);" class="gradient-text">{{ $t('彩金池') }}
                </div>
                <div class="num" style="margin-top:4px;margin-left:auto;margin-right:auto;">
                  <span style="font-size:12px;zoom:calc(9/12); position: absolute;left: 50%; top:50%;transform: translate(-50%,-50%)">{{
											$tools.formatNum2k(userData.awardPool)
										}}</span>
                </div>
              </div>
              <div style="text-align:center;">
                <div style="font-size:12px;zoom:calc(7/12);color:#ffcd7f">{{ $t('领取彩金所需投注') }}</div>
                <div style="width: 150px;height: 5px;background-color: #700a0e;border-radius: 5px;margin-top: 5px;overflow: hidden;border:2px solid #d48647;">
                  <div style="height: 5px;" class="gradient-line" :style="progressWidth"></div>
                </div>
                <div style="font-size:12px;zoom:calc(8/12);color:#ffd863;margin-top:4px;">
                  {{ $tools.formatNum2k(userData.curBet) }} <span style="color:#fff3f3;"> / {{
										$tools.formatNum2k(userData.goalBet) }}</span></div>
              </div>
              <div style="text-align:center;">
                <div style="font-size:12px;zoom:calc(9/12);" class="gradient-text">{{ $t('今日彩金') }}</div>
                <div class="num" style="margin-top:4px;margin-left:auto;margin-right:auto;">
                  <span style="font-size:12px;zoom:calc(9/12); position: absolute;left: 50%; top:50%;transform: translate(-50%,-50%)">{{
											$tools.formatNum2k(userData.dayAward)
										}}</span>

                </div>
              </div>
            </div>

            <div class="flexs_sb" style="padding:0 15px;margin-top:15px;">
              <div style="text-align:center;">
                <div style="font-size:12px;zoom:calc(9/12);color:#ffc287;">{{ $t('累计领取彩金') }}</div>
                <div class="num" style="margin-top:9px;color:white;margin-left:auto;margin-right:auto;">
                  <span style="font-size:12px;zoom:calc(9/12);position: absolute;left: 50%; top:50%;transform: translate(-50%,-50%) ">{{
											$tools.formatNum2k(userData.TotalAward) }}</span>
                </div>
              </div>

              <div style="position:relative;">
                <img :src="$img('home/' + 'btn_lingqu.png')" v-if="userData.activityStatus == 1" alt="" style="width: 51px;" />
                <img :src="$img('home/' + 'btn_lingqu_hui.png')" v-else alt="" style="width: 51px;" />
                <div v-if="userData.activityStatus == 1" @click="getMoney()" style="position:absolute;left:0;top:0px ;width:100%;text-align:center;height:100%;">
                  <div style="color:#ffefe5;font-size:12px;zoom:calc(10/12);">{{ getTime() }}</div>
                  <div style="color:#ffd283;font-size:12px;zoom:calc(10/12);">{{ $t('领取彩金') }}</div>
                </div>
                <div v-else style="position:absolute;font-size:12px;zoom:calc(10/12);left:0;top:0px ;width:100%;text-align:center;height:100%;">
                  <div class="cannotClick" style="color:#ffd283; height: 100%; ">
                    <span style="position: absolute;left: 50%;top: 45%;transform: translate(-50%,-50%);">{{
												$t('领取彩金')
											}}</span>
                  </div>
                </div>
              </div>
            </div>
            <img :src="$img('home/' + 'ding_img.gif')" alt="" style="	position: absolute;left: 50%;top:42px;transform: translate(-50%);height: 60px;" />
            <!-- <div class="flexs_r_c" style="padding:0 0px;margin-top:25px;flex-wrap:wrap;" v-if="listData">
							<div v-for="(item, index) in listData" :style="index % 4 > 0 ? 'margin-left:1.5%' : ''"
								style="position:relative;width:20%;" class="canClick" @click="selectIndex = index">
								<img :src="$img('home/' + 'xuanz_J.png')" v-if="selectIndex == index" alt=""
									style="width: 100%;" />
								<img :src="$img('home/' + 'wxuanz_J.png')" v-else alt="" style="width: 100%;" />

								<div style="font-size:12px;position:absolute;left:0;top:0px;width:100%;text-align:center;color:white;font-size:12px;line-height:26px;"
									:style="selectIndex == index ? 'top:0px' : ''">
									{{ $tools.formatNum2k(item.Charge) }}

								</div>
							</div>

						</div> -->
            <div class="rechage-input">
              <div v-for="(item, index) in listData" class="item" @click="selectIndex = index">
                <img :src="$img('home/' + 'xuanz_J.png')" v-if="selectIndex == index" alt="" style="width: 100%;height: 100%;" />
                <img :src="$img('home/' + 'wxuanz_J.png')" v-else alt="" style="width: 100%;height: 100%;" />

                <div style="font-size:12px;position:absolute;left:50%;top:50%; transform: translate(-50%,-50%); width:100%;text-align:center;color:white;font-size:12px;;zoom:calc(9/12);line-height:26px;">
                  {{ $tools.formatNum2k(item.Charge) }}

                </div>
              </div>
            </div>
            <div style="position:relative;margin-top:5px; width: 135px;margin-left:auto; margin-right:auto; " @click="toRecharge()" class="canClick">
              <img :src="$img('home/' + 'btn_cunr.png')" alt="" style="width: 135px;" />
              <div style="position:absolute;left:0;top:2px;color:#fcf4e4;width:100%;text-align:center;font-size:12px;">
                {{ $t('存入') }}
              </div>
            </div>
            <div style="margin-top:0px;">
              <img :src="$img('home/' + 'bt_title_img.png')" alt="" style="width: 90px;" />

            </div>

            <div class="table" style="border:1px solid #d48647;border-radius:2px;">
              <div class="flexs_r_c" style="border-bottom:0.5px solid #d48647">
                <div v-for="(item, index) in headerArr" class="header" :style="index != 4 ? 'border-right:0.5px solid #d48647' : ''" :key="index" style="font-size:12px;zoom:calc(8/12);">
                  {{ $t(item) }}
                </div>
              </div>

              <div class="flexs_r_c" v-for="(item, index) in listData" :key="index" :style="index != listData.length - 1 ? 'border-bottom:1px solid #d48647' : ''">
                <div class="colum" style="border-right:1px solid #d48647;">
                  <div style="font-size:12px;zoom:calc(8/12);">{{
										$tools.formatNum2k(item.Charge)
									}}</div>
                </div>
                <div class="colum" style="border-right:1px solid #d48647;">
                  <div style="font-size:12px;zoom:calc(8/12);">{{
										$tools.formatNum2k(item.TotalGet)
									}}</div>
                </div>
                <div class="colum" style="border-right:1px solid #d48647;">
                  <div style="font-size:12px;zoom:calc(8/12);">{{
										item.GetCnt
									}}</div>
                </div>
                <div class="colum" style="border-right:1px solid #d48647;">
                  <div style="font-size:12px;zoom:calc(8/12);">{{
										$tools.formatNum2k(item.DayGet)
									}}</div>
                </div>
                <div class="colum">
                  <div style="font-size:12px;zoom:calc(8/12);">{{ $tools.formatNum2k(item.DayBet) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div style="position:relative;margin-top:10px;width: 90vw;margin-left:5vw;">

      <img :src="$img('home/' + 'sm_db.png')" alt="" style="width:100%;height:600px;" />
      <div style="position:absolute;left:15px;top:65px;width:calc(100% - 30px);height:calc(100% - 85px); font-size:12px;zoom:calc(10/12);;overflow-y: auto; overflow-x: hidden;line-height:24px;font-weight:700;">
        <p style="text-align: justify;"><strong>※ Nội dung khuyến mãi</strong><span style="color: rgb(255, 199, 119);"> :</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Thành viên khi nạp tiền có thể
            nạp thông qua bát tụ bảo với số tiền tương ứng như bảng bên dưới, số tiền này có thể được sử dụng
            cho tất cả các trò chơi tại Zbet668, tiền nạp tính riêng rẻ từng đơn không tính tích lũy.</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Sau khi nạp tiền qua bát tụ bảo,
            tiền thưởng được tặng là 100% số tiền nạp. Tiền thưởng được chia thành chu kỳ 30 - 25 - 20 - 15 - 10
            ngày để nhận tiền. Để nhận được tiền thưởng tích lũy thành viên cần hoàn thành doanh số cược yêu cầu
            theo từng mức tiền nạp và tiền thưởng.</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Tổng tiền thưởng có thể được cộng
            dồn và tiền thưởng hàng ngày cũng có thể được cộng dồn đồng thời. </span></p>
        <p style="text-align: justify;"><strong>※ Ví du</strong><span style="color: rgb(255, 199, 119);">:</span>
        </p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Thành viên A, hôm nay nạp tiền
            qua bát tụ bảo có 2 đơn tiền là 3,000 điểm và 15,000 điểm, nhận được tổng điểm thưởng là 3,000 +
            15,000 = 18,000 điểm.</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Trong 25 ngày đầu tiên, thành
            viên A có thể nhận được 700 điểm thưởng mỗi ngày khi hoàn thành doanh số cược hợp lệ 72,000
            điểm.</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Trong 5 ngày tiếp theo, thành
            viên A có thể nhận 100 điểm thưởng mỗi ngày và chỉ cần hoàn thành 12,000 điểm doanh số cược hợp
            lệ.</span></p>
        <p style="text-align: justify;"><strong>※ Phương thức nhận thưởng</strong><span style="color: rgb(255, 199, 119);"> :</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Chọn Bát Tụ Bảo -&gt; chọn Số
            tiền muốn nạp và bấm Nạp Tiền ở sự kiện Bát Tụ Bảo -&gt; Sau khi nạp tiền thành công, hệ thống sẽ tự
            động hoàn tất tích lũy tiền thưởng ở Bát Tụ Bảo cho thành viên .</span></p>
        <p style="text-align: justify;"><strong>※ Chi tiết hoạt động</strong><span style="color: rgb(255, 199, 119);"> :</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">1. Tiền thưởng có thể được cộng
            dồn.</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">2. Nếu doanh số cược hợp lệ không
            được hoàn thành trong ngày, tiền thắng cược trong ngày cũng sẽ bị coi là không hợp lệ và bị khấu
            trừ.</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">3. Cược xổ số không được tính là
            cược hợp lệ của khuyến mãi</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">4. Tham gia sự kiện này sẽ không
            được nhận bất kỳ khuyến mãi nạp tiền khác của Zbet668.</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">4. Zbet668 bảo lưu quyền thay đổi,
            dừng hoặc hủy bỏ chương trình khuyến mãi này bất cứ lúc nào!</span></p>
        <p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">5. Tham gia nghĩa là bạn đã đồng ý
            với "Quy Tắc Và Điều Khoản Khuyến Mãi".</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { theme } from '@/views/theme'
import { bus, busName } from "@/components/core/bus";
import { http, topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import tools from '@/utils/tools'

export default {
  components: {

  },
  props: {

    data: {
      type: String,
      default: null
    }
  },
  setup() {

  },
  computed: {
    ...mapGetters("tcp", ["isLogged"]),

  },
  created() {
    bus.off('HD_ReceiveSportGuess')
    bus.on('HD_ReceiveSportGuess', (val) => {
      if (val.Code == 0) {
        that.$tools.toast(that.$t('领取成功') + '!');


      } else {
        that.$tools.toast(val.ErrMsg);


      }
    })
    this.getConfig()
    this.getUserConfig()
  },
  methods: {
    getConfig() {
      var that = this
      mqant.request(topic.getTreasureBowlConf, {}, function (data, topicName, msg) {
        that.listData = data.Data.conf
        //console.log('sdfsf',data)

      });
    },
    getTime() {
      var hour = Math.floor(this.second / 3600)

      var minute = Math.floor((this.second - hour * 3600) / 60)
      var second = this.second - hour * 3600 - minute * 60
      hour = hour < 10 ? '0' + hour : hour
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second

      return hour + ':' + minute + ':' + second

    },
    getUserConfig() {
      var that = this
      mqant.request(topic.getTreasureBowlUserInfo, {}, function (data, topicName, msg) {
        //that.listData = data.Data.conf
        console.log('sasd', data)

        that.userData = data.Data
        if (data.Data.goalBet != 0) {
          that.progressWidth.width = data.Data.curBet / data.Data.goalBet * 100 + '%'

        } else {
          that.progressWidth.width = "0%"
        }
        console.log('gggg', that.progressWidth.width)
        that.second = data.Data.remainTime

        if (that.timer) {
          clearInterval(this.that); // 在Vue实例销毁前，清除我们的定时器
        }
        that.timer = setInterval(() => {
          that.second--
        }, 1000)
      });
    },
    handleClose() {
      this.$emit('handleClose')
      if (this.timer) {
        clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
      }
    },
    getMoney() {
      var that = this
      mqant.request(topic.receiveTreasureBowl, {}, function (data, topicName, msg) {
        that.getUserConfig()

      });
    },
    toRecharge() {

      var that = this
      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {



        if (!data.Data.myDouDouBT.length) {
          that.$dialog.confirm({
            title: "Xin lưu ý",
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ',
            message:
              'Cần điền thông tin ngân hàng',
          })
            .then(() => {
              that.$router.push({ path: '/addBankCard' });
            })
            .catch(() => { });



        } else {
          that.$router.push({ name: 'recharge' })

          bus.emit('ChargeNum', that.listData[that.selectIndex].Charge / 1000);
          localStorage.setItem('ChargeNum', that.listData[that.selectIndex].Charge / 1000)

          that.handleClose()
        }
      });

    }

  },

  data() {
    return {
      progressWidth: {
        width: "0%"
      },
      selectIndex: 0,
      listData: null,
      headerArr: ['存入金额', '总彩金', '彩金领取周期', '每日彩金', '每日所需投注'],
      userData: {
        TotalAward: 0,
        activityStatus: 0,
        awardPool: 0,
        curBet: 0,
        dayAward: 0,
        goalBet: 0,
        remainTime: 0

      },
      second: 0,
      time: '00:00:00',
      timer: null
    };
  },
};
</script>

<style scoped lang="scss">
.table {
  margin-top: 5px;
}

.gradient-text {
  color: #ffc15f;
}

.num {
  position: relative;
  width: 60px;
  height: 14px;
  background-color: #5e0b19;
  box-shadow: 0px 1px 0px 0px rgba(209, 79, 79, 0.51);
  border-radius: 11px;
  text-align: center;
  color: #ffd86e;
}

.gradient-line {
  background: linear-gradient(to right, #e95800, #ffc658);
}

.colum {
  width: 20%;
  text-align: center;
  height: 15px;
  line-height: 15px;
  color: #ac3c28;
  background-color: #f3d8a3;
  font-size: 12px;
  font-weight: 700;
}

.header {
  width: 20%;
  text-align: center;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  color: #f9ce91;
  background-color: #b01e26;
  font-weight: 700;
}

strong {
  color: #ffc778;
  font-size: 12px;
}

.rechage-input {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;

  .item {
    position: relative;
    width: calc((100% - 12px) / 5);
    height: 30px;
  }
}

@media screen and (min-width: 400px) {
  .table {
    margin-top: 20px;
  }
}
</style>