<template>
  <div style="position: relative;">
    <div style="height: 50px;background-color:#292e3b ;color:white;font-size:20px;line-height: 50px;text-align: center;">
      ZBET Tải APP</div>
    <div style="width: 100%;text-align: center;position: relative;padding-bottom: 30px;" class="wrap">
      <img :src="$img('download/icon_zbt.png')" alt="" style="width: 150px;margin-top: 40px;">

      <div style="margin-top: 30px;font-size: 12px;font-weight: 800;">ZBET <span style="font-weight: 500;">( Phiên
          bản: 1.0.0 )</span></div>
      <div style="padding-top: 10px;margin: 20px auto;width: 250px;height: 230px;" class="wrap1">
        <div style="color: white;font-size: 18px;font-weight: 800;">Quét mã để cài App</div>
        <vue-qr :text="'https://zbet668.com/static/download/index.html'" :size="165" :margin='15' style="width: 180px;margin-top: 10px">
        </vue-qr>

      </div>
      <div style="line-height: 30px;font-size: 18px;margin-top: 20px;">Vui lòng dùng điện thoại quét mã phía trên
        để tải App<br>

        Hoặc dùng trình duyệt trên điện thoại để mở link:</div>
      <div style="width: 481px;height: 40px;line-height: 40px;text-align: center;background-color:#363d4f;margin: 30px auto;font-size: 22px;border-radius:10px;">https://zbet668.com/static/download/index.html</div>
      <div class="flexs_r_c" style="justify-content: center;margin-top: 15px;">
        <div class='canClick' @click='showIphone=true' style="background:linear-gradient(to right,#6d49ff,#555fff);width: 342px;text-align: center;line-height: 48px;height: 48px;border-radius: 24px;color:white;font-size: 24px;">Hướng dẫn APP trên Iphone</div>
        <div class='canClick' @click='showAndroid=true' style="background:linear-gradient(to right,#e7581a,#ef8711);margin-left: 15px;width: 342px;text-align: center;line-height: 48px;height: 48px;border-radius: 24px;color:white;font-size: 24px;">Hướng dẫn APP trên Android</div>
      </div>
    </div>
    <div @touchmove.prevent @mousewheel.prevent v-if="showIphone" style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 200;background-color: rgba(0, 0, 0, .7);text-align: center;border: 1px solid rgb(41, 52, 72);">
      <div style="width: 1400px;padding: 50px 20px;margin-top: 40px;background-color: #292e3b;margin: 100px auto;position: relative;">
        <img :src="$img('download/img_sm1.png')" alt="" style="width: 1200px;">

        <img @click='showIphone=false' class='canClick' :src="$img('download/img_gb.png')" alt="" style="width: 36px;position: absolute;right: 20px;top:10px;">

      </div>

    </div>
    <div @touchmove.prevent @mousewheel.prevent v-if="showAndroid" style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 200;background-color: rgba(0, 0, 0, .7);text-align: center;border: 1px solid rgb(41, 52, 72);">
      <div style="width: 1400px;padding: 30px 20px;margin-top: 40px;background-color: #292e3b;margin: 100px auto;position: relative;">
        <img :src="$img('download/img_sm2.png')" alt="" style="width: 1000px;">

        <img @click='showAndroid=false' class='canClick' :src="$img('download/img_gb.png')" alt="" style="width: 36px;position: absolute;right: 20px;top:10px;">

      </div>

    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import {
  mapGetters,
  mapActions,
  mapMutations
} from "vuex";
import bus from "@/utils/bus.js"
import {
  status,
  getApiLoginUrl,
  checkFactoryUrl
} from "@/components/protocol/api";

export default {
  components: {
    vueQr
  },
  data() {
    return {
      showIphone: false,
      showAndroid: false
    }
  },
  computed: {


  },

  created() {

  },
  methods: {



  },

};
</script>

<style lang="scss" scoped>
.wrap {
  background-image: url("@/assets/pc/download/bg_xiazai.png");
  background-size: 100% 100%;
}
.wrap1 {
  background-image: url("@/assets/pc/download/erwm_bg.png");
  background-size: 100% 100%;
}
</style>
