<template>
  <div class="header">
    <div class="navigation-bar">
      <div class="maxWidthNav">
        <div class="nav-top">
          <div class="nav-top-container">
            <div class="logo-box canClick" @click="toHome()">
              <img src="@/assets/common/logo.png" alt="" />
            </div>
            <div class="navigation-box">
              <div class="navigation-box-top">
                <!-- <button class="el-button btn-phoneLogin el-button--default">
                  <span>
                    <i class="iconfont icon-icon_handphone"></i>
                    <span>SĐT Đăng nhập</span>
                  </span>
                </button>
                <button class="el-button btn-navVideo el-button--default">
                  <span>
                    <i class="iconfont icon-icon_sexylady"></i>
                    <span>Sexy lady</span>
                  </span>
                </button> -->
                <button class="el-button btn-chat el-button--default" @click="jumpToChat()">
                  <span>
                    <i class="iconfont icon-icon_chat"></i>
                    <span>Phòng chat</span>
                  </span>
                </button>
                <div class="time-wrapper">
                  <span>{{ date.Format("yyyy-MM-dd hh:mm:ss") }}(GMT + 7) </span>
                </div>
              </div>
              <div v-if="!isLogged" class="navigation-box-bottom">
                <input v-model="account" class="accout-input" type="text" placeholder="Tài khoản" />
                <div class="password">
                  <input v-model="password" :type="passWordTypeStr" placeholder="6-15 ký tự chữ và số" />
                  <span class="el-input__suffix">
                    <span class="el-input__suffix-inner">
                      <i v-if="!isShowPassword" class="iconfont icon-icon_hide" @click="changePassWordState(true)"></i>
                      <i v-if="isShowPassword" class="iconfont icon-icon_show" @click="changePassWordState(false)"></i>
                    </span>
                  </span>
                </div>
                <button class="el-button btn-login el-button--default" @click="login()">
                  <span>
                    <i class="iconfont icon-icon_account"></i>
                    <span>Đăng nhập</span>
                  </span>
                </button>
                <button class="el-button btn-registe el-button--default" @click="openRegister()">
                  <span>
                    <i class="iconfont icon-icon_add_account"></i>
                    <span>Đăng ký</span>
                  </span>
                </button>
              </div>
              <div v-if="isLogged" class="navigation-box-bottom">
                <div class="user-nav">
                  <button class="el-button btn-charge el-button--default" @click="openUser('recharge', -1)">
                    <span>
                      <img class="icon" src="@/assets/pc/header/chongqian.svg" alt="" />
                      <span>Nạp tiền</span>
                    </span>
                  </button>
                  <!-- <button class="el-button btn-charge el-button--default">
                    <span>
                      <img class="icon" src="@/assets/pc/header/qianbao.svg" alt="" />
                      <span>Chuyển quỹ</span>
                    </span>
                  </button> -->
                  <button class="el-button btn-charge el-button--default" @click="openUser('drawMoney', -2)">
                    <span>
                      <img class="icon" src="@/assets/pc/header/quxian.svg" alt="" />
                      <span>Rút tiền</span>
                    </span>
                  </button>
                </div>
                <div class="user-wrapper" @click="openUser('personal', 0)" @mouseenter.prevent="showNav = true"
                  @mouseleave.prevent="showNav = false">
                  <div class="user-layout">
                    <img class="user-icon" :src="require('@/assets/common/avatar/' + userInfo.Avatar + '.png')" alt="" />
                    <div class="user-info">
                      <p>{{ userInfo.Account }}</p>
                      <p>
                        <span class="total_money">{{
                          $tools.formatNum2k(wallet.VndBalance)
                        }}</span>
                      </p>
                    </div>
                    <div class="user-refresh">
                      <i class="iconfont icon-icon_refresh_gold_12"></i>
                      <span class="el-icon-arrow-down"></span>
                    </div>
                  </div>
                  <div class="user-pop" v-show="showNav">
                    <ul>
                      <li @click.stop="openUser('personal', 0)">
                        <span>Thông tin cá nhân</span>
                        <i class="iconfont icon-icon_personal_data"></i>
                      </li>
                      <li @click.stop="openUser('record', 1)">
                        <span>Lịch sử cược</span>
                        <i class="iconfont icon-icon_top_up_record"></i>
                      </li>
                      <li @click.stop="openUser('betHistory', 3)">
                        <span>Doanh số cược</span>
                        <i class="iconfont icon-icon_stake"></i>
                      </li>
                      <li @click.stop="openUser('message', 4)">
                        <span>Hộp thư</span>
                        <i class="iconfont icon-icon_message_centre"></i>
                      </li>
                      <li @click.stop="openRouter('help')">
                        <span>Trung tâm trợ giúp</span>
                        <i class="iconfont icon-icon_help_centre"></i>
                      </li>
                      <li @click.stop="quitLogin">
                        <span>Đăng xuất</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-main">
          <div class="routerGroup">
            <el-tabs id="my-tabs" v-model="activeIndex" @tab-click="handleClick">
              <el-tab-pane v-for="(item, index) in tabList">
                <template #label :name="index">
                  <div class="navItemContainer" @mouseenter="setHoverIndex(index)" @mouseleave="setHoverIndex(-1)">
                    <img v-if="item.hot" class="hotLabel" :src="$img('header/hot_icon.png', pc)" alt="">
                    <div class="navItem">
                      <div class="icon">
                        <i :class="'iconfont' + ' ' + item.class"></i>
                      </div>
                      <div class="name">{{ item.name }}</div>
                    </div>
                  </div>
                </template>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div class="lottery-game-pop " :class="canShowPop ? 'showPop' : ''" @mouseenter="changeHoverState(true)"
        @mouseleave="changeHoverState(false)">
        <div class="lottery" v-show="lastHoverIndex == lotteryIndex">
          <lottertHeader></lottertHeader>
        </div>
      </div>
      <div class="other-game-pop" :class="canShowPop ? 'showPop' : ''" @mouseenter="changeHoverState(true)"
        @mouseleave="changeHoverState(false)">
        <div v-show="lastHoverIndex == luckyWinIndex" class="luckyWin">
          <swiper :modules="modules" @swiper="setVSwiperRef($event, luckyWinObj)"
            @slide-change="updateVSwiperIndex(luckyWinObj)" :slides-per-view="luckyWinObj.pageSize"
            :slides-per-group="luckyWinObj.pageSize" :space-between="8"
            :grid="{ fill: 'column', rows: luckyWinObj.grid }">
            <swiper-slide v-for="(item, index) in luckyWinObj.data" :key="item" @click="luckyWinItemClick(item)">
              <div class="navPlatItem">
                <div class="cover">
                  <div class="btn-play">
                    <div class="icon-play"></div>
                    <div class="btn-txt">PLAY NOW</div>
                  </div>
                </div>
                <div class="name">
                  <p>{{ item.GameName }}</p>
                </div>
                <div class="imgContainer">
                  <img :src="$img('luckyWin/icon/' + item.GameCode + '_2.png', 'pc')" alt="">
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" :class="isShowPrevDisable(luckyWinObj) ? 'swiper-button-disabled ' : ''"
            @click="prevVSwiperSlide(luckyWinObj)"></div>
          <div class="swiper-button-next" :class="isShowNextDisable(luckyWinObj) ? 'swiper-button-disabled ' : ''"
            @click="nextVSwiperSlide(luckyWinObj)"></div>
        </div>

        <div v-show="lastHoverIndex == liveCasinoIndex" class="liveCasino">
          <swiper :modules="modules" @swiper="setVSwiperRef($event, liveCasinoObj)"
            @slide-change="updateVSwiperIndex(liveCasinoObj)" :slides-per-view="liveCasinoObj.pageSize"
            :slides-per-group="liveCasinoObj.pageSize" :space-between="8">
            <swiper-slide v-for="(item, index) in liveCasinoObj.data" :key="item" @click="openFactory(item)">
              <div class="navPlatItem">
                <div class="content">
                  <div class="cover">
                    <div class="btn-play">
                      <div class="icon-play"></div>
                      <div class="btn-txt">Bắt đầu chơi</div>
                    </div>
                  </div>
                  <div class="liveContainer">
                    <div class="top">
                      <img :src="$img(`liveCasino/${item.Factory.FactoryName}_icon_3.png`)" alt="" class="foreground" />
                      <img :src="$img(`liveCasino/${item.Factory.FactoryName}_icon_1.png`)" alt="" class="croupier" />
                    </div>
                    <div class="bot">
                      <div class="logo">
                        <img :src="$img(`liveCasino/${item.Factory.FactoryName}_icon_2.png`)" class="aelive" />
                      </div>
                      <p>{{ item.Factory.FactoryShowName }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" :class="isShowPrevDisable(liveCasinoObj) ? 'swiper-button-disabled ' : ''"
            @click="prevVSwiperSlide(liveCasinoObj)"></div>
          <div class="swiper-button-next" :class="isShowNextDisable(liveCasinoObj) ? 'swiper-button-disabled ' : ''"
            @click="nextVSwiperSlide(liveCasinoObj)"></div>
        </div>

        <ul v-show="lastHoverIndex == sportIndex" class="sports-chess">
          <li class="gameItem last-row-item" v-for="(item, index) in sportObj.data" @click="openFactory(item)">
            <div class="navPlatItem">
              <div class="cover">
                <div class="btn-play">
                  <div class="icon-play"></div>
                  <div class="btn-txt">PLAY NOW</div>
                </div>
              </div>
              <p class="line"></p>
              <div class="name">
                <p>Phí hoàn tối đa 1.7%</p>
              </div>
              <div class="imgContainer">
                <img :src="$img(`sport/${item.Factory.FactoryName}_icon_1.png`)" alt="" class="main" />
                <img :src="$img(`sport/comon_nav_di.png`)" alt="" class="bot" />
                <img :src="$img(`sport/${item.Factory.FactoryName}_icon_2.png`)" alt="" class="logo" />
              </div>
            </div>
          </li>
        </ul>

        <ul v-show="lastHoverIndex == chessIndex" class="sports-chess">
          <li class="gameItem last-row-item" :class="item.Factory.Status !== 1 ? 'disabled' : ''"
            v-for="(item, index) in chessObj.data" @click="openChess(item)">

            <div class="navPlatItem">
              <div class="cover">
                <div class="btn-play">
                  <div class="icon-play"></div>
                  <div class="btn-txt">PLAY NOW</div>
                </div>
              </div>
              <p class="line"></p>
              <div class="name">
                <p>{{ item.Factory.FactoryShowName.toUpperCase() }}</p>
              </div>
              <div class="imgContainer">
                <img :src="$img(`chess/${item.Factory.FactoryName}_icon_1.png`)" alt="" class="main" />
                <img :src="$img(`chess/${item.Factory.FactoryName}_icon_2.png`)" alt="" class="bot" />
                <img :src="$img(`chess/${item.Factory.FactoryName}_icon_4.png`)" alt="" class="logo" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog width="400"  v-model="drawMoneyDialog.show" title="Thay đổi mật khẩu giao dịch" >
      <!-- <span class="closeBtn" @click="drawMoneyDialog.show = false">
        <i class="iconfont icon-icon_close_dark"></i>
      </span> -->
      <div class="dialogBody transactionPwdBody">
        <el-form ref="drawMoneyRef" :model="drawMoneyDialog" :rules="rules" label-position="top">
         
          <el-form-item label="Mật khẩu giao dịch mới" prop="password">
            <el-input v-model="drawMoneyDialog.password" placeholder="Mật khẩu rút tiền" type="password" />
          </el-form-item>
          <el-form-item label="Xác nhận lại mật khẩu giao dịch" prop="checkPass">
            <el-input v-model="drawMoneyDialog.checkPass" placeholder="Mật khẩu rút tiền" type="password" />
          </el-form-item>
        </el-form>
        <el-button style="width:100%;" class="el-button el-button--primary el-button--small" @click="drawMoneySumit">
          {{ $t("提交") }}</el-button>
      </div>
    </el-dialog>
    <el-dialog v-model="addBankDialog.show" width="400" title="Thêm thông tin ngân hàng" >
      <!-- <span class="closeBtn" @click="addBankDialog.show = false">
        <i class="iconfont icon-icon_close_dark"></i>
      </span> -->
      <div class="dialogBody transactionPwdBody">
        <el-form ref="addBankRef" :model="addBankDialog" :rules="rules" label-position="top">
          <el-form-item label="Chọn tên ngân hàng">
            <el-select v-model="addBankDialog.bankName" style="width:100%;">
              <el-option v-for="item in addBankDialog.list" :key="item" :value="item.BtName">{{ item.BtName
              }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Vui lòng nhập stk ngân hàng" prop="cardNum">
            <el-input v-model="addBankDialog.cardNum" placeholder="vui lòng nhập số tài khoản ngân hàng" />
          </el-form-item>
          <el-form-item label="Xin điền họ tên" prop="realName">
            <el-input v-model="userAll.userData.realName" :disabled="addBankDialog.isDisabled"
              style="cursor: not-allowed" placeholder="Xin điền họ tên" />
          </el-form-item>

          <el-form-item label="Vui lòng Mật khẩu rút tiền" prop="DoudouPwd">
            <el-input type="text" v-model="addBankDialog.DoudouPwd" style="cursor: not-allowed"
              placeholder="Vui lòng Mật khẩu rút tiền" />
          </el-form-item>
        </el-form>
        <el-button style="width:100%;" class="el-button el-button--primary el-button--small" @click="addBankSumit">
          {{ $t("提交") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, watch } from "vue";
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "../../../components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { http } from "@/components/protocol/api";
import { ElMessage } from "element-plus";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import lottertHeader from "@/views/pc/components/lottery/lottertHeader.vue";
import { topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { Pagination, Grid } from "swiper";
import "swiper/swiper.css";
import "swiper/css/grid";
import "swiper/css/pagination";
const modules = reactive([Pagination, Grid]);
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { uuid } = useGetters("global", ["globalState", "uuid"]);
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const { disConnect } = useActions("tcp", ["disConnect"]);
const { loginOutAll } = useActions('global', ['loginOutAll'])
const router = useRouter();
const curRouter = useRoute();
let date = ref(new Date());
let timeFuc = null;
let account = ref("");
let password = ref("");
let showNav = ref(false);
let activeIndex = ref("0");
let hoverIndex = ref(0);
let lastHoverIndex = ref(0);
let isShowPassword = ref(false);
let passWordTypeStr = ref("password");
let canShowPop = ref(false);

let drawMoneyDialog = reactive({
  show: false,
  oldDwPassword: "",
  password: "",
  checkPass: "",
});
let addBankDialog = reactive({
  show: false,
  list: [],
  selectIndex: 0,
  bankName: "",
  cardNum: "",
  realName: "",
  isDisabled: false,
  DoudouPwd: "",
});

let userAll = reactive({
  userData: {
    DoudouPwd: []
  },
});
let addBankRef = ref(null);
let drawMoneyRef = ref(null);
const [lotteryIndex, luckyWinIndex, liveCasinoIndex, sportIndex, chessIndex] = [
  ref(1),
  ref(2),
  ref(3),
  ref(4),
  ref(6),
];
let tabList = reactive([
  {
    class: "icon-icon_nav_home",
    name: "Trang chủ",
    router: "/",
  },
  {
    class: "icon-icon_nav_lottery",
    name: "Xổ số",
    router: "/lottery",
    showPop: true,
    hot: true,
  },
  {
    class: 'icon-icon_nav_luckywin',
    name: 'Zwin',
    router: '/Zwin',
    showPop: true,
    hot: true,
  },
  {
    class: "icon-icon_nav_casino",
    name: "Casino",
    router: "/liveCasino",
    showPop: true,
  },
  {
    class: "icon-icon_nav_sports",
    name: "Thể thao",
    router: "/sport",
    showPop: true,
  },
  {
    class: "icon-icon_nav_slots",
    name: "Nổ hũ",
    router: "/slot",
  },
  {
    class: "icon-icon_nav_chess",
    name: "Game bài",
    router: "/chess",
    showPop: true,
  },
  {
    class: "icon-icon_nav_fishing",
    name: "Bắn cá",
    router: "/fish",
  },
  {
    class: "icon-icon_nav_cockfighting",
    name: "Đá gà",
    router: "/cockFight",
  },
  {
    class: "icon-icon_nav_discounts",
    name: "Ưu đãi",
    router: "/activity",
  },
  {
    class: "icon-icon_nav_feature",
    name: "VIP",
    router: "/vip",
  },
  {
    class: "icon-icon_nav_help",
    name: "Đại lý",
    router: "/help",
  },
  {
    class: "icon-icon_download_btn",
    name: "Tải xuống",
    router: "/dowload",
  }
]);
onMounted(() => {
  timeFuc = setInterval(() => {
    date.value = new Date();
  }, 1000);
});
onBeforeUnmount(() => {
  timeFuc && clearTimeout(timeFuc);
});
const changeHoverState = (bool) => {
  //重置刷新canShowPop的状态，很重要
  canShowPop.value = bool;
};
const setHoverIndex = (index) => {
  if (tabList[index] && tabList[index].showPop) {
    canShowPop.value = true;
    lastHoverIndex.value = Number(index);//记录上一次的触摸index
  } else {
    canShowPop.value = false;
  }
  hoverIndex.value = Number(index);
};

bus.on('showHeader', () => {
  canShowPop.value = true
})

const openFactory = (item) => {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  if (item.Factory.FactoryName) {
    let url = getApiLoginUrl(item.Factory.FactoryName, "");
    $act.openPage(url);
  }
  canShowPop.value = false;
};

const openRouter = (name) => {
  router.push({
    path: "/" + name,
    query: {
      name: name,
    },
  });
  showNav.value = false;
}
const openUser = (name, index = 0) => {
  if (name == "recharge" || name == "drawMoney") {
    mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
      getBankList();
      userAll.userData = data.Data;
      addBankDialog.isDisabled = !data.Data.realName ? false : true

      if (!data.Data.DoudouPwd.length) {
        drawMoneyDialog.show = true;

        return
      }
      
      if (!data.Data.myDouDouBT.length) {
        addBankDialog.show = true;

      } else {
        router.push({
          path: "/userCenter/" + name,
          query: {
            name: name,
          },
        });
      }
      showNav.value = false;

    });
  }else {
    router.push({
    path: "/userCenter/" + name,
    query: {
      name: name,
    },
  });
  showNav.value = false;
  }

  
};

function getBankList() {
  mqant.request(topic.bankList, {}, function (data, topicName, msg) {
    addBankDialog.list = data.Data.douDouBtList;
    addBankDialog.bankName = data.Data.douDouBtList[0].BtName;

    // log.info('getBankList', data.Data)
  });
}

const addBankSumit = () => {
  addBankRef.value.validate((valid) => {
    if (valid) {
      let obj = {
        btName: addBankDialog.bankName,
        cardNum: addBankDialog.cardNum,
        accountName: userAll.userData.realName.toString(),
      };
      mqant.request(topic.bindBank, obj, function (data, topicName, msg) {
        if (data.Code == 0) {
          ElMessage({
            message: "Thiết lập thành công",
            type: "success",
          });
          addBankDialog.show = false;
        } else {
          ElMessage({
            message: data.ErrMsg,
            type: "error",
          });
        }
      });
    } else {
      return false;
    }
  });
};

const drawMoneySumit = () => {
  drawMoneyRef.value.validate((valid) => {
    if (valid) {
      let obj = {
        DoudouPwd: drawMoneyDialog.checkPass,
      };
      mqant.request(
        topic.updateDoudouPwd,
        obj,
        function (data, topicName, msg) {
          if (data.Code == 0) {
            ElMessage({
              message: "Thiết lập thành công",
              type: "success",
            });
            drawMoneyDialog.show = false;
            addBankDialog.show = true;
            drawMoneyDialog.password = '';
            drawMoneyDialog.oldDwPassword = '';
            drawMoneyDialog.checkPass = '';
            
          } else {
            ElMessage({
              message: data.ErrMsg,
              type: "erro",
            });
          }
        }
      );
    } else {
      return false;
    }
  });
};


function jumpToChat() {
  bus.emit(bus.event.chatState, true);
}

const openChess = (item) => {
  if (item.Factory.Status !== 1) {
    return;
  }

  if (item.Factory.FactoryName) {
    router.push({
      path: "/chess",
      query: {
        facName: item.Factory.FactoryName,
      },
    });
    activeIndex.value = chessIndex.value.toString();
    canShowPop.value = false;
  }
};
function luckyWinItemClick(item) {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  let url = getApiLoginUrl(item.FactoryName, item.GameCode);
  $act.openPage(url);
}
//处理一下选中路由
setSelectIndex();
function setSelectIndex() {
  let routerPah = curRouter.path;
  for (let key in tabList) {
    let info = tabList[key];
    if (info.router && info.router == routerPah) {
      activeIndex.value = key;
      break;
    }
  }
}
const changePassWordState = (bool) => {
  isShowPassword.value = bool;
  passWordTypeStr.value = !bool ? "password" : "text";
};
//luckywin
let luckyWin = getLuckyWinData();
let luckyWinObj = reactive({
  data: luckyWin,
  swiperRef: null,
  swiperIndex: 0,
  totalPage: luckyWin.length,
  pageSize: 7,
  grid: 1,
});
//视讯导航栏
let liveData = getFactorDataBytype('LiveCasino');
let liveCasinoObj = reactive({
  data: liveData,
  swiperRef: null,
  swiperIndex: 0,
  totalPage: liveData.length,
  pageSize: 7,
  grid: 1,
});

let sportObj = reactive({
  data: getFactorDataBytype('LiveSports'),
});

let chessObj = reactive({
  data: getFactorDataBytype('Card'),
});

function getLuckyWinData() {
  let slotData = lobbyInfo.value.StaticPicture.LuckyWin;
  if (!slotData.apiLuckyWin) return [];
  let list = slotData.apiLuckyWin.GameList;
  return list;
}

function getFactorDataBytype(type) {
  let data = lobbyInfo.value.StaticPicture[type];
  let rusult = [];
  if (!data) return;
  for (let key in data) {
    let info = data[key];
    if (info.Factory.Status == 1) {
      rusult.push(info);
    }
  }
  rusult.sort((a, b) => {
    return b.Factory.Sort - a.Factory.Sort
  })
  return rusult;
}



function toHome() {
  router.push({ name: 'home' })
}
function setVSwiperRef(swiper, obj) {
  obj.swiperRef = swiper;
}
function updateVSwiperIndex(obj) {
  obj.swiperIndex = obj.swiperRef.snapIndex || 0;
}
function prevVSwiperSlide(obj) {
  obj.swiperRef?.slidePrev();
}
function nextVSwiperSlide(obj) {
  obj.swiperRef?.slideNext();
}
function isShowPrevDisable(obj) {
  return obj.swiperIndex == 0;
}
function isShowNextDisable(obj) {
  return obj.swiperIndex + 1 >= Math.ceil(obj.totalPage / obj.pageSize);
}

const login = () => {
  if (!account.value.length || !password.value.length) {
    ElMessage({
      message: "Mật khẩu tài khoản không được để trống",
      type: "error",
    });
    return;
  }
  let params = {
    account: account.value,
    password: password.value,
    platform: tools.platform(),
    uuid: uuid.value,
    uuid_web: uuid.value,
  };
  doLogin({
    params: params,
    url: http.userLogin,
  }).then(
    (resolve) => {
      ElMessage({
        message: "Đăng nhập thành công",
        type: "success",
      });
    },
    (reject) => {
      ElMessage({
        message: reject.ErrMsg,
        type: "error",
      });
    }
  );
};
const quitLogin = () => {
  router.push("/");
  loginOutAll();
};
const openRegister = () => {
  bus.emit(bus.event.registerState, true);
};
const handleClick = (tab, event) => {
  let index = Number(tab.index);
  if (tabList[index] && tabList[index].router) {
    router.push({
      path: tabList[index].router,
      // query: {
      //   facName: curFacName,
      // }
    });
  }
};
watch(() => {
  curRouter.path, setSelectIndex();
})
</script>

<style lang="scss" scoped>
.header {
  overflow-y: none;
  width: 100%;
  height: 140px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);

  .navigation-bar {
    width: 100%;
    height: 150px;
    color: #fff;
    box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.25);
    border-bottom: hsla(0, 0%, 100%, 0.25);

    .maxWidthNav {
      width: 100%;
      height: 100%;

      .nav-top {
        box-sizing: border-box;
        background-color: #292e3b;
        border-bottom: 1px solid hsla(0, 0%, 61.6%, 0.12);

        button {
          border: none;
          border-radius: 24px;
          height: 28px;
          padding: 3px 15px;

          >span {
            display: flex;
            align-items: center;
            color: #fff;

            img {
              width: 16px;
              height: 16px;
              margin: 5px;
            }
          }
        }

        .nav-top-container {
          background-color: #292e3b;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 89px;
          margin: 0 auto;
          width: 1500px;

          .logo-box {
            height: 62px;

            img {
              height: 100%;
            }
          }

          .navigation-box {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .navigation-box-top {
              color: #677684;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 28px;
              font-size: 14px;

              .iconfont {
                margin-right: 5px;
              }

              .btn-phoneLogin {
                background: linear-gradient(180deg, #76a2ff, #8691ff);
              }

              .btn-navVideo,
              .btn-chat {
                background: #1e222c;

                span {
                  color: #8491a5;
                }

                .iconfont {
                  color: #8491a5;
                }
              }

              .time-wrapper {
                font-size: 13px;
                margin-left: 10px;
                min-width: 140px;
                color: #8491a5;
              }
            }

            .navigation-box-bottom {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 40px;

              input {
                background: #1e222c !important;
                -webkit-text-fill-color: #999 !important;
                -webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2) !important;
                transition: background-color 5000s ease-in-out 0s;
                height: 34px;
                border-radius: 24px;
                border: none;
                color: #999;
                box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2);
                font-size: 14px;
                line-height: 40px;
                outline: none;
                padding: 0 15px;
                width: 100%;
                box-sizing: border-box;
                caret-color: #999;
              }


              .accout-input {
                margin-right: 10px;
              }

              .password {
                position: relative;
                width: 100%;
                margin-right: 10px;

                >input {
                  padding-right: 30px !important;
                }

                .el-input__suffix {
                  position: absolute;
                  height: 100%;
                  right: 5px;
                  top: 0;
                  text-align: center;
                  color: #c0c4cc;
                  transition: all 0.3s;
                  pointer-events: none;
                }
              }

              .btn-login,
              .btn-registe {
                background: #292e3b;
                position: relative;
                transition: all 0.3s;

                .iconfont {
                  background: linear-gradient(180deg, #94b3ff, #5b7cfe);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  margin-right: 10px;
                }
              }

              .btn-login:hover,
              .btn-registe:hover {
                transform: scale(1.05);
              }

              .btn-login::before,
              .btn-registe::before {
                background: linear-gradient(180deg, #94b3ff, #5b7cfe);
                content: "";
                border-radius: 24px;
                inset: 0;
                padding: 2px;
                position: absolute;
                -webkit-mask: linear-gradient(#fff 0 0) content-box,
                  linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
              }

              .user-nav {
                background: #1e222c;
                border-radius: 8px;
                display: flex;
                align-items: center;
                height: 40px;
                padding: 0 10px;

                button {
                  background: 0 0;
                  border: none;
                  box-shadow: none;
                  height: 34px;
                  margin: 0;
                  padding: 0;
                  transition: all 0.3s;

                  img {
                    width: 28px;
                    height: 28px;
                    margin-right: 5px;
                    opacity: 0.8;
                  }

                  span {
                    color: #677684;
                  }
                }

                button+button {
                  margin-left: 15px;
                }

                button:hover {
                  transform: scale(1.05);
                }
              }

              .user-wrapper {
                position: relative;
                cursor: pointer;

                .user-layout {
                  display: flex;
                  justify-content: space-between;

                  .user-icon {
                    margin-left: 10px;
                    width: 40px;
                    height: 40px;
                  }

                  .user-info {
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    p {
                      padding: 0;
                      margin: 0;
                      display: block;
                      height: 20px;
                      color: #677684;
                      line-height: normal;
                      text-overflow: ellipsis;
                      font-size: 14px;
                    }

                    .total_money {
                      color: #fa0;
                      margin-right: 5px;
                    }
                  }

                  .user-refresh {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    >i {
                      margin-right: 5px;
                      color: #90a2dc;
                    }

                    .el-icon-arrow-down {
                      color: #90a2dc;
                    }
                  }
                }

                .user-pop {
                  position: absolute;
                  top: 40px;
                  left: 0px;
                  z-index: 1001;

                  ul {
                    background: 0 0;
                    box-shadow: none;
                    margin-top: 5px;
                    z-index: 100;
                    min-width: 200px;
                    padding: 5px 0;
                    border-radius: 2px;
                    list-style: none;
                    position: relative;
                    margin: 0;

                    li:first-child {
                      border-radius: 12px 12px 0 0;
                    }

                    li:last-child {
                      border-radius: 0 0 12px 12px;
                      text-align: center;
                    }

                    li {
                      position: relative;
                      background: #363d4e;
                      box-shadow: inset 0 -0.5px 0 0 rgba(132, 144, 167, 0.3);
                      color: #8491a5;
                      height: 56px;
                      line-height: 56px;
                      padding: 0 20px;
                      font-size: 14px;
                      list-style: none;
                      cursor: pointer;
                      position: relative;
                      transition: border-color 0.3s, background-color 0.3s, color 0.3s;
                      box-sizing: border-box;
                      white-space: nowrap;
                      text-align: right;

                      >span:not(:last-child) {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 45px;
                      }

                      i {
                        color: #8491a5;
                        margin-left: 10px;
                      }
                    }

                    li:hover {
                      background: #292e3b;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .nav-main {
        height: 50px;
        background-image: none;
        background-color: #1e222c;
        border-bottom: none;
        box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.1);
        position: relative;
        z-index: 3;
        width: 100%;
        display: flex;
        align-items: center;

        .routerGroup {
          width: 1500px;
          margin: 0 auto;

          :deep(.el-tabs) {
            .el-tabs__header {
              margin: 0;
            }

            .el-tabs__nav-wrap:after {
              background: 0 0;
            }

            .el-tabs__nav {
              height: 50px !important;
              display: flex;
              align-items: center;
              justify-content: center;
              float: none !important;

              .el-tabs__active-bar {
                transform: translateX(0px);
                height: 0 !important;
              }

              .el-tabs__item {
                width: auto;
                height: 100%;
                color: #90a2dc;
                margin-right: 10px !important; //标题栏数量变化，此处距离需要修改
                // margin-right: 20px !important;
                padding: 0 !important;

                .navItemContainer {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  position: relative;
                  padding: 0 8px;
                  box-sizing: border-box;

                  .hotLabel {
                    position: absolute;
                    z-index: 1;
                    height: 28px;
                    top: -4px;
                    right: -2px;
                    -webkit-transform-origin: 36% 20%;
                    transform-origin: 36% 20%;
                    animation: rotate .8s ease-in-out;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                  }

                  @keyframes rotate {
                    0% {
                      transform: rotate(0deg);
                    }

                    100% {
                      transform: rotate(30deg);
                    }
                  }

                  .navItem {
                    font-weight: 500;
                    border-radius: 24px;
                    padding: 0 5px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .icon {
                      width: 26px;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      margin-right: 6px;

                      i {
                        font-size: 22px;
                      }
                    }

                    .name {
                      width: auto;
                      white-space: nowrap;
                      word-break: break-word;
                      text-align: left;
                      overflow: hidden;
                      position: relative;
                      top: 1px;
                      font-size: 14px;
                    }
                  }
                }
              }

              .el-tabs__item:hover .navItem {
                border-radius: 24px;
              }
            }

            .is-active,
            .el-tabs__item:hover {
              background-image: linear-gradient(119deg, #7146ff, #4a69ff);
            }

            .is-active .navItem,
            .el-tabs__item:hover .navItem {
              background: 0 0 !important;
              color: #fff !important;

              .icon {
                color: #fff;
              }
            }

            .is-active:before,
            .el-tabs__item:hover:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 2px solid hsla(0, 0%, 100%, 0.7);
              box-sizing: border-box;
            }
          }
        }
      }
    }

    .swiper-button-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      cursor: pointer;
      left: -90px;
      background: hsla(0, 0%, 100%, 0.05);
      border-radius: 50%;
      border: none !important;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      color: hsla(0, 0%, 100%, 0.7);
      width: 72px;
      height: 72px;
      top: 50%;
      transform: translateY(-50%);
    }

    .swiper-button-prev:after {
      font-family: swiper-icons;
      font-size: 24px;
      content: "prev";
    }

    .swiper-button-next {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      cursor: pointer;
      right: -90px;
      background: hsla(0, 0%, 100%, 0.05);
      border-radius: 50%;
      border: none !important;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      color: hsla(0, 0%, 100%, 0.7);
      width: 72px;
      height: 72px;
      top: 50%;
      transform: translateY(-50%);
    }

    .swiper-button-next:after {
      font-family: swiper-icons;
      font-size: 24px;
      content: "next";
    }

    .swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      pointer-events: none;
    }

    .showPop {
      max-height: 700px !important;
    }

    .other-game-pop {
      background-color: #292e3b;
      top: 140px;
      background-image: none;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      z-index: 5000;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
      transition: all 0.3s;

      .luckyWin {
        width: 1400px;
        padding-bottom: 10px;
        position: relative;

        :deep(.swiper) {}

        .navPlatItem {
          width: 180px;
          height: 210px;
          margin: 10px;
          cursor: pointer;
          position: relative;

          .cover {
            background: rgba(30, 34, 44, .2);
            padding: 80px 20px 0 !important;
            border-radius: 6px;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1000;

            .btn-play {
              min-width: 132px;
              height: 40px;
              background: #4a69ff;
              box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, .4);
              border-radius: 20px;
              display: flex;
              align-items: center;
              color: #fff;
              padding: 0 16px;
              cursor: pointer;
              box-sizing: border-box;
              font-size: 13px;

              .icon-play {
                width: 24px;
                height: 24px;
                background: #fff;
                box-shadow: 0 0 3px 0 rgba(0, 0, 0, .3);
                border-radius: 12px;
              }

              .icon-play::after {
                display: inline-block;
                content: "";
                border: 6px solid transparent;
                border-left-color: #677684;
                margin-left: 10px;
                margin-top: 6px;
              }

              .btn-txt {
                flex: 1;
                text-align: center;
              }
            }
          }

          .name {
            position: absolute;
            border: 1px solid #677684;
            border-radius: 6px;
            left: 50%;
            bottom: 16px !important;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 92%;

            p {
              height: 28px;
              line-height: 28px;
              color: #fff;
              font-size: 14px;
              text-align: center;
            }
          }

          .imgContainer {
            width: 100%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              width: 100%;
            }
          }
        }

        .navPlatItem:hover .cover {
          display: block;
        }
      }

      .liveCasino {
        width: 1400px;
        padding-bottom: 10px;
        position: relative;

        .navPlatItem {
          width: 194px;
          height: 245px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 10px;
          transition: all 0.5s;
          margin: 0 12px;
          position: relative;

          .content {
            width: 100%;
            height: 100%;

            .cover {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 100000;
              background: rgba(30, 34, 44, 0.2);
              padding: 110px 20px 0;
              border-radius: 6px;

              .btn-play {
                min-width: 132px;
                height: 40px;
                background: #4a69ff;
                box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, 0.4);
                border-radius: 20px;
                display: flex;
                align-items: center;
                color: #fff;
                padding: 0 16px;
                cursor: pointer;
                box-sizing: border-box;
                font-size: 13px;

                .icon-play {
                  width: 24px;
                  height: 24px;
                  background: #fff;
                  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
                  border-radius: 12px;
                }

                .icon-play::after {
                  display: inline-block;
                  content: "";
                  border: 6px solid transparent;
                  border-left-color: #677684;
                  margin-left: 10px;
                  margin-top: 6px;
                }

                .btn-txt {
                  flex: 1;
                  text-align: center;
                }
              }
            }

            .liveContainer {
              display: flex;
              flex-direction: column;
              align-items: center;

              .top {
                width: 144px;
                height: 144px;
                overflow: hidden;
                border-radius: 72px;
                background-image: url("@/assets/pc/header/img_casino_nav_bg_dark.png");
                background-size: 100% 100%;
                position: relative;
                box-shadow: none;

                .foreground {
                  width: 144px;
                  top: 40px;
                  position: absolute;
                }

                .croupier {
                  top: 10px;
                  left: 10px;
                  width: 130px;
                  position: absolute;
                }
              }

              .bot {
                width: 174px;
                height: 70px;
                margin-top: 10px;
                text-align: center;
                border-radius: 35px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-shadow: none;
                border: 1px solid #363d4e;

                .logo {
                  flex-shrink: 0;
                  height: 32px;
                  width: 100%;

                  .aelive {
                    height: 100%;
                  }
                }

                p {
                  margin-top: 2px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #fff;
                }
              }
            }
          }
        }

        .navPlatItem:hover .content .cover {
          display: block;
        }
      }

      .sports-chess {
        width: 1410px;
        height: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 99999;

        li {
          .navPlatItem {
            margin: 10px;
            width: 214px;
            height: 320px;
            cursor: pointer;
            position: relative;

            .cover {
              background: rgba(30, 34, 44, 0.2);
              padding: 140px 41px 0;
              border-radius: 6px;
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 100000;

              .btn-play {
                min-width: 132px;
                height: 40px;
                background: #4a69ff;
                box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, 0.4);
                border-radius: 20px;
                display: flex;
                align-items: center;
                color: #fff;
                padding: 0 16px;
                cursor: pointer;
                box-sizing: border-box;
                font-size: 13px;

                .icon-play {
                  width: 24px;
                  height: 24px;
                  background: #fff;
                  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
                  border-radius: 12px;
                }

                .icon-play::after {
                  display: inline-block;
                  content: "";
                  border: 6px solid transparent;
                  border-left-color: #677684;
                  margin-left: 10px;
                  margin-top: 6px;
                }

                .btn-txt {
                  flex: 1;
                  text-align: center;
                }
              }
            }

            .name {
              border: 1px solid #677684;
              border-radius: 6px;
              left: 50%;
              bottom: 4px;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              width: 92%;
              position: absolute;

              p {
                height: 28px;
                line-height: 28px;
                color: #fff;
                font-size: 14px;
                text-align: center;
              }
            }

            .imgContainer {
              overflow: hidden;
              width: 100%;
              height: 100%;
              position: relative;

              .main {
                z-index: 1;
                position: absolute;
                width: 100%;
              }

              .bot {
                bottom: 45px;
                position: absolute;
                width: 100%;
              }

              .logo {
                // left: 30px;
                top: 26px;
                height: 30px;
                width: auto;
                position: absolute;
              }
            }
          }

          .navPlatItem:hover .cover {
            display: block;
          }
        }

        .disabled {
          cursor: not-allowed !important;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
    }
  }
}

.lottery-game-pop {
  background-color: #292e3b;
  top: 140px;
  background-image: none;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  z-index: 5000;

  box-sizing: border-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s;

  .lottery {
    width: 100%;
    padding: 20px 0;
    position: relative;
  }
}
</style>
