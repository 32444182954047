<template>
  <template v-if="isFactory">
    <router-view />
  </template>
  <template v-else>
    <Splash v-if="loading" />
    <router-view v-if="hasLoad" />
  </template>
</template>


<script setup>
import Splash from "@/views/pc/components/Splash.vue";
import Load from "@/views/pc/components/Load.vue";
import bus from '@/utils/bus'
import { useGetters, useActions, useMutations } from '@/store/hooks/storeState/index'
import { ref, nextTick } from 'vue';
import { onMounted } from "vue";
import log from "./utils/logger";
import { useRouter, useRoute } from "vue-router";
import { topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { Toast } from "vant";
import store from '@/store'
import { ElMessage } from "element-plus";
const { isLogged } = useGetters("tcp", ["isLogged"]);
const { getLobbyInfo } = useActions('global', ['getLobbyInfo'])
const { init } = useActions("global", ["init"]);
const { connect_lottery } = useActions("tcp_lottery", ["connect_lottery"]);
const { connect } = useActions("tcp", ["connect"]);
const { autoLogin } = useActions("user", ["autoLogin"]);
const { setOutToken } = useMutations("user", ["setOutToken"]);
const { token, outToken } = useGetters("user", ["token", "outToken"]);


const { resetGame } = useActions("global", ["resetGame"]);
const router = useRouter();
const route = useRoute();
let isFactory = ref(false);//是否厂商的标识
let hasLoad = ref(false);
let loading = ref(false);

if (getToken()) {
  setOutToken(getToken())
}

if (window.location.href.indexOf("navigator") >= 0) {
  isFactory.value = true;
} else {
  isFactory.value = false;
  loading.value = true;
  startGame();
  bus.off(bus.event.showLoading);
  bus.on(bus.event.showLoading, (bool) => {
    loading.value = bool;
  })
  bus.off(bus.event.resetGame);
  bus.on(bus.event.resetGame, () => {
    hasLoad.value = false;
    startGame();
  })
}


onMounted(() => {
  visibilitychange();
})
/** 浏览器窗口监听 */
const visibilitychange = () => {
  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden') {
      log.error('离开了本网页---')
    } else {
      // let getIsLogged = store.getters["tcp/isLogged"];
      // log.error('离开了本网页---', isLogged.value, getIsLogged, mqant.isConnect())
      // ElMessage.info(`回来了本网页---,登录状态1为${isLogged.value},登录状态2为${getIsLogged}链接状态${mqant.isConnect()}`);
      log.error('回来了本网页---')
      if (isLogged.value) {
        if (mqant.isConnect()) {
          mqant.request(topic.tcpTest, {}, function (data, topicName, msg) {
            log.info('topic.tcpTest---', data)
            if (data.Code == 0) {
            } else {
              loading.value = true;
              resetGame();
            }
          });
        } else {
          loading.value = true;
          resetGame();
        }
      } else {
        if (!token.value && !outToken.value) {
          loading.value = true;
          resetGame();
        }
      }
    }
  });
}

function getToken() {
  let url = window.location.href
  let token = ''
  var arr = url.split('?')
  if (arr.length > 1) {
    let p = url.split('?')[1].split('#')[0]
    if (p) {
      let keyValue = p.split('&');
      let obj = {};
      for (let i = 0; i < keyValue.length; i++) {
        let item = keyValue[i].split('=');
        let key = item[0];
        let value = item[1];
        obj[key] = value;
      }
      token = obj.token
    }
  }


  return token
}

function startGame() {
  getLobbyInfo().then(() => {
    hasLoad.value = true;
    init().then(() => {
      //综合盘连接
      connect().then(() => {
        if (!isLogged.value) {
          autoLogin().then(() => {
            log.info('autoLogin111')
            loading.value = false;
          }, (err) => {
            log.info('autoLogin222')
            router.push('/');
            loading.value = false
          });
        }
        //彩票连接
        connect_lottery();
      });
    })
  });
}
</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
