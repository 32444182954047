<template>
  <div style="position: relative;overflow:hidden;" class="wrap">
    <topHeader :title="$t('转盘')" :showRight="false" :showBack="true"></topHeader>
    <img :src="$img('turntable/' + 'turnPhoneBg.png')" alt="" style="width: 100%;min-height: 2500px;">
    <div @click="showRecord = true" class="record canClick" style="position: absolute;top:65px;text-align: center;left:calc(50vw - 50px);font-size: 10px;z-index: 99;color:#e9af40">
      Thông tin quy đổi</div>

    <div style="position: absolute;left: 0;width: 100%;" class="turnTop">
      <div style="display: flex;flex-direction: row;justify-content: space-between;width: 80%;margin-left: 10%;">
        <div style="position: relative;">
          <img :src="$img('turntable/' + 'turnTableNo.png')" alt="" style="width: 95px;">
          <div style="color:#e9af40;position: absolute;left: 0;top:20px;right:0;bottom:0;text-align: center;">
            <div style="text-align: center;font-size: 10px;">Tích điểm</div>
            <div style="text-align: center;font-size: 16px;" v-if="config.Points">{{ Math.floor(config.Points)
						}}
            </div>

          </div>
        </div>

        <div style="position: relative;">
          <img :src="$img('turntable/' + 'turnTableNo.png')" alt="" style="width: 95px;">
          <div style="color:#e9af40;position: absolute;left: 0;top:25px;text-align: center;right:0;bottom:0;">
            <div style="font-size: 16px;">{{ Math.floor(config.Points / config.TurnNeedPoints) }}<span style="font-size: 10px;">lần</span></div>

          </div>
        </div>

      </div>
      <div style="position: relative;margin: 0 auto;width: 300px;margin-top: -20px;">
        <img :src="$img('turntable/' + 'light.png')" alt="" style="width: 300px;position: relative;z-index: 5;">
        <!-- <img :src="$img('turntable/'+'000.png')" alt=""
					style="width: 300px;position: absolute;top: 2.5px;left: 0;"> -->
        <div style="position: absolute;width: 260px;left: 20px;top: 20px;">
          <zhuanPan ref="zhuanpan"></zhuanPan>

        </div>
        <img :src="$img('turntable/' + 'zhuanAc.png')" class="canClick" @click="toDraw()" alt="" style="width: 60px;position: absolute;z-index: 5;left: 120px;top: 120px;">

      </div>

      <div style="width: 247px;border: 1.5px solid rgba(246, 175, 23, 1);margin: 25px auto;height: 95px;border-radius: 10px;">
        <div style="width: 160px;margin: 3px auto;" class="flexs_sb">

          <img :src="$img('turntable/' + 'num_L.png')" v-if="config.LNum > 0" alt="" style="width: 20px;">
          <img :src="$img('turntable/' + 'num_L_H.png')" v-else alt="" style="width: 20px;">

          <img :src="$img('turntable/' + 'num_U.png')" v-if="config.UNum > 0" alt="" style="width: 20px;">
          <img :src="$img('turntable/' + 'num_U_H.png')" v-else alt="" style="width: 20px;">

          <img :src="$img('turntable/' + 'num_C.png')" v-if="config.CNum > 0" alt="" style="width:20px;">
          <img :src="$img('turntable/' + 'num_C_H.png')" v-else alt="" style="width:20px;">

          <img :src="$img('turntable/' + 'num_K.png')" v-if="config.KNum > 0" alt="" style="width: 20px;">
          <img :src="$img('turntable/' + 'num_K_H.png')" v-else alt="" style="width: 20px;">

          <img :src="$img('turntable/' + 'num_Y.png')" v-if="config.YNum > 0" alt="" style="width: 20px;">
          <img :src="$img('turntable/' + 'num_Y_H.png')" v-else alt="" style="width: 20px;">
        </div>
        <div style="width: 210px;border: 1.5px solid rgba(246, 175, 23, 1);margin:2px auto;height:30.5px;border-radius: 10px;" class="flexs_r_c">
          <img :src="$img('turntable/' + 'iphone14.png')" alt="" style="width: 14px;margin-left:9px;">
          <div style="font-size:10px;color:white;font-weight:bold;margin-left:12.5px;">IPHONE 15 PRO
            MAX 512GB</div>
        </div>
        <div style="color: white;font-size: 10px;margin-left: 23.5px;  font-style: italic;margin-top: -2px;">
          *Thu thập 5 chữ cái để nhận thưởng</div>
      </div>
      <div style="width: 296px;margin: 20px auto;">
        <div v-for="(item, index) in jiangList" :key="item" style="position: relative;margin-top: 10px;">
          <img :src="$img('turntable/' + 'jiangWrap.png')" alt="" style="width: 100%;">
          <div class="flexs_r_c" style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;">
            <div class="flexs_r_c" style="position: relative;">
              <div class="numWrap">

                <div style="position: relative;z-index: 0;">{{ item.num }}</div>
                <img v-if="index == 0" :src="$img('turntable/' + 'No1.png')" alt="">
                <img v-else-if="index == 1" :src="$img('turntable/' + 'No2.png')" alt="">
                <img v-else-if="index == 2" :src="$img('turntable/' + 'No3.png')" alt="">
                <img v-else :src="$img('turntable/' + 'No_other.png')" alt="">

              </div>

            </div>
            <img :src="$img('turntable/' + 'jiang_' + item.word + '.png')" alt="" style="height:26px;margin-left: 32px;">

            <div style="font-size: 12px;position: absolute;left:195px;height: 32px;line-height: 32px;color: white;">
              {{ item.money }}</div>

          </div>
        </div>

      </div>

      <div style="250px;margin: 0px auto;text-align:center;">
        <img :src="$img('turntable/' + 'winner.png')" alt="" style="width: 150px;">
        <div style="position: relative;">
          <img :src="$img('turntable/' + 'whiteBg.png')" alt="" style="width: 90%;margin-top: 10px;margin-left: 5%;">
          <div style="position: absolute;width: 80%;height: 80%;left: 50%;transform: translateX(-50%);top: 5%;overflow: hidden;">
            <div class="gameList" :style="{ 'margin-top': tableTop + 'px' }">
              <div v-for="(item, index) in tzList" :key="index" style="height: 35px;width: 80%;margin-left: 10%;border-bottom: 1px solid #293659;position: relative;">
                <div class="flexs_sb" style="margin-top: 3px;">
                  <div style="color:#f0f0f0;font-size: 14px;">{{ $tools.formatDate(item.CreateAt,
										"yyyy-MM-dd") }}</div>

                  <div style="color:#95a0b4;font-size: 14px;">
                    {{ item.NickName.substr(0, 2) + '***' + item.NickName.substr(item.NickName.length -
											2, 2) }}
                  </div>

                </div>

                <div class="flexs_sb" style="margin-top: 3px;">
                  <div>&nbsp;</div>

                  <div style="color:white;font-size: 12px;color:#ffe400;" v-if="item.GetGold > 0" class="canClick">{{ dealWord(item.WordType) }}-{{ item.GetGold / 1000 }}K</div>
                  <div style="color:white;font-size: 12px;color:#ffe400;" v-else class="canClick">
                    {{ dealWord(item.WordType) }}-{{ item.GetPoint }}điểm</div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- <img :src="$img('turntable/'+'dipan.png')" alt="" style="width: 100%;margin-top: -60px;"> -->

      </div>

      <div style="width: 280px;margin: 20px auto;position: relative;border-radius: 10px;padding: 0 18px;padding-top: 45px;color:#0a194a;font-size:13px;font-weight:bold;background-color: white;padding-bottom:25px ;line-height: 24px;margin-top: 47px;z-index:3;">
        <div style="position: absolute;left: 65px;top:-15px;">
          <div style="position: relative;">
            <img :src="$img('turntable/' + 'turnTableBtn.png')" alt="" style="width: 150px;">
            <div style="color:#e9af40;font-size:10px;width:100%;text-align: center;font-weight: bold;top: 3px;position: absolute;">
              QUY TẮC TÍCH ĐIỂM</div>
          </div>

        </div>

        <img :src="$img('turntable/' + 'sd_01.png')" alt="" style="width: 88px;position:absolute;left:230px;top:0px;z-index:0;">
        <!-- <img :src="$img('turntable/'+'sd_02.png')" alt="" style="width: 150px;"> -->
        <p style="text-align: center;"><span style="color: rgb(225, 60, 57);"><strong>VÒNG QUAY MAY MẮN RINH NGAY
              IPHONE 15 PRO MAX VÀ NHẬN TRIỆU TIỀN THƯỞNG</strong></span></p>
        <p style="text-align: justify;">※ Mã khuyến mãi:<span style="color: rgb(0, 0, 0);"> &nbsp;</span><span style="color: rgb(231, 76, 60);">VQMM</span></p>
        <p style="text-align: justify;">※ Đối tượng:<span style="color: rgb(0, 0, 0);"> Tất cả thành viên
            ZBET668</span></p>
        <p>※ Nội dung khuyến mãi <span style="color: rgb(0, 0, 0);">:</span></p>
        <p><span style="color: rgb(0, 0, 0);">- Thành viên tham gia nạp tiền và cược tại ZBET668 sẽ có cơ hội tích
            điểm. Điểm tích lũy có thể dùng để quay vòng quay may mắn để nhận quà là tiền thưởng và thu thập các
            chữ cái dùng để ghép thành ZBET8 nhận thưởng Iphone 15 Pro Max 512G. </span></p>
        <p><span style="color: rgb(0, 0, 0);">- 10 điểm tích lũy sẽ đổi được 1 lượt quay</span></p>
        <p><span style="color: rgb(0, 0, 0);">- Mỗi lần nạp tiền sẽ sinh ra điểm, nạp tích lũy 10,000,000 VND sẽ
            được 1 điểm.</span></p>
        <p><span style="color: rgb(0, 0, 0);">- Mỗi vé cược hợp lệ (vé cược thắng hoặc thua) của thành viên sẽ tạo
            ra tích điểm và thành viên sẽ nhận được 1 điểm cho cược tích lũy 30,000,000 VND.</span></p>
        <p>※Quy định khuyến mãi<span style="color: rgb(0, 0, 0);">:</span></p>
        <p>1. Bất kỳ nhóm hoặc cá nhân nào gian lận hoặc lợi dụng nhằm trực lợi khuyến mãi, ZBET668 có quyền hủy tài
          khoản và tất cả quyền lợi của thành viên đó.</p>
        <p>2. ZBET668 có quyền thực hiện, sửa đổi và chấm dứt hoạt động theo quyết định riêng mà không cần thông báo
          trước.</p>
        <p>3. <span style="color: rgb(0, 0, 0);">Tham gia nghĩa là bạn đã đồng ý với " </span><span style="color: rgb(251, 160, 38);">Quy Tắc Và Điều Khoản Khuyến Mãi </span><span style="color: rgb(0, 0, 0);">"</span></p>
      </div>

      <div>

      </div>

    </div>
    <showAward v-if="showDia" @close='showDia = false' :awardData='awardObj'></showAward>
    <turnRecord v-if="showRecord" @cancel='showRecord = false'></turnRecord>

  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations
} from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { bus, busName } from "@/components/core/bus";


import zhuanPan from "@/views/mobile/components/turntable/zhuanPan.vue"
import showAward from "@/views/mobile/components/turntable/showAward.vue"
import turnRecord from "@/views/mobile/components/turntable/turnRecord.vue"
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";



import tools from "@/utils/tools";

import {
  req,
  qs
} from "@/utils/request";

import {
  topic
} from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import {
  Dialog
} from 'vant';
import 'vant/es/dialog/style';
export default {
  components: {
    zhuanPan,
    showAward,
    turnRecord,
    topHeader
  },
  data() {
    return {
      config: {},
      tableTop: 0,
      timer: null,
      tzList: [],
      showRecord: false,

      jiangList: [{
        'num': 1,
        'word': 'y',
        'money': '8888K'
      },
      {
        'num': 2,
        'word': 'k',
        'money': '5888K'
      },
      {
        'num': 3,
        'word': 'c',
        'money': '888K'
      },
      {
        'num': 4,
        'word': 'u',
        'money': '30 ĐIỂM'
      },
      {
        'num': 5,
        'word': 'u',
        'money': '388K'
      },
      {
        'num': 6,
        'word': 'l',
        'money': '68K'
      },
      {
        'num': 7,
        'word': 'l',
        'money': '18K'
      },
      {
        'num': 8,
        'word': 'l',
        'money': '2 ĐIỂM'
      }

      ],
      canTurn: true,
      awardObj: null,
      showDia: false

    }
  },
  computed: {
    ...mapGetters("tcp", ["isLogged", "info"]),
    ...mapGetters("global", ["lobbyInfo"]),

  },

  created() {
    this.getRecord()
    if (this.isLogged) {
      this.getConfigInfo()

    }
    var that = this
    bus.off('HD_StartTurnTable')
    bus.on('HD_StartTurnTable', (val) => {
      console.log('zsd1111', val)
      var obj = val.data.Data
      that.awardObj = obj
      that.$refs.zhuanpan.toDraw(obj.No)
    })


    bus.off('canTurn')
    bus.on('canTurn', (val) => {
      //console.log('zsd1111',val)
      that.showDia = true
      that.canTurn = true
      that.getConfigInfo()

    })

    this.timer = setInterval(() => {
      if (-(this.tableTop) > this.tzList.length * 35) {
        this.tableTop = 0;
      } else {
        this.tableTop -= 1;

      }
    }, 100);

  },
  methods: {
    dealWord(e) {
      //lucky ZBET8
      if (e == 'L') {
        return 'Z'
      } else if (e == 'U') {
        return 'B'
      } else if (e == 'C') {
        return 'E'
      } else if (e == 'K') {
        return 'T'
      } else if (e == 'Y') {
        return '8'
      }
    },
    async getRecord() {
      //turnTableAllRecord

      let params = {
        TurnTableAllRecord: '',
        platformType: tools.platform(),
      }
      var that = this
      let rsp = await req.post(http.lobbyConf, qs.stringify(params));
      if (rsp.status == 200 && rsp.data.Code == 0) {
        that.tzList = rsp.data.Data.TurnTableAllRecord
      } else {

      }
    },
    getConfigInfo() {
      let params = {


      };
      let that = this;
      mqant.request(topic.getTurnTableConf, params, function (res) {
        if (res.Code == 0) {
          that.config = res.Data.Conf

        } else {
          that.$message({
            message: that.$t(res.ErrMsg),
            type: 'error'
          });
        }
      });
    },
    toDraw() {

      if (!this.isLogged) {
        bus.emit('showLogin', {})
        return
      }
      if (!this.canTurn) {
        return
      }
      this.canTurn = false
      let params = {


      };
      let that = this;
      mqant.request(topic.startTurnTable, params, function (res) {
        //console.log('asdaaz',res)
        if (res.Code == 0) {
          // var obj = res.Data
          // that.$refs.zhuanpan.toDraw(obj.No)


        } else {

          that.$message({
            message: that.$t(res.ErrMsg),
            type: 'error'
          });
        }
      });
    }

  },

};
</script>

<style scoped lang="scss">
.numWrap {
  line-height: 26px;
  text-align: center;
  margin-left: 10px;
  position: relative;
  font-size: 17.5px;
  width: 26px;
  height: 26px;
  z-index: 1;

  img {
    width: 26px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.wrap {
  padding-top: 50px;
  //height:calc(100vh - 100px);
}

.turnTop {
  top: 65px;
}
</style>
