<template>
    <div class="user">
        <div class="leftMenu">
            <div class="payCard">
                <div class="userInfoBox">
                    <div>
                        <span class="avatar el-avatar el-avatar--circle">
                            <img :src="require('@/assets/common/avatar/' + userInfo.Avatar + '.png')"
                                style="object-fit: cover;" @click="openAvatar()">
                        </span>
                    </div>
                    <div class="info">
                        <div class="userName">
                            <span> {{ userInfo.Account }}</span>
                            <!-- <i class="iconfont icon-icon_amend_name cursorP"></i> -->
                        </div>
                        <p class="jionDay"> Gia nhập ngày thứ {{ getDay() }} </p>
                    </div>
                </div>
                <div class="userMoney">
                    <div class="money">
                        <span class="totalMoney">Tổng số dư:
                        </span>
                        <span>{{ $tools.formatNum2k(wallet.VndBalance) }}</span>
                        <!-- <i class="iconfont icon-icon_refresh_gold_12"></i> -->
                    </div>
                    <div class="payInfo">
                        <div @click="tabItemClick(-1, 'recharge')">
                            <img :src="$img('header/chongqian.svg')" alt="">
                            <span>Nạp tiền</span>
                        </div>
                        <div @click="tabItemClick(-2, 'drawMoney')">
                            <img :src="$img('header/quxian.svg')" alt="">
                            <span>Rút tiền</span>
                        </div>
                    </div>
                    <div class="payInfo" style="display: none;">
                        <div class="payInfoSpan"><span>Nạp tiền</span></div>
                        <div class="payInfoSpan"><span>Rút tiền</span></div>
                    </div>
                </div>
            </div>
            <ul class="menubar">
                <li v-for="(item, index) in tabList" class="menubar-item" :class="tabSelectIndex == index ? 'select' : ''"
                    @click="tabItemClick(index, item.router)">
                    <img :src="$img('user/vip_icon.png')" v-if="item.router == 'userVip'"
                        style="width:16px;margin: 0 15px 0 0;" alt="">

                    <i class=" menuIcon iconfont" v-else :class="item.iconfont"></i>
                    <span v-if="tabSelectIndex == index" class="themeActiveBar"></span>
                    <span>{{ item.text }}</span>
                </li>
            </ul>
        </div>
        <div class="rightView">
            <router-view />
        </div>
        <el-dialog width="400"  v-model="drawMoneyDialog.show" title="Thay đổi mật khẩu giao dịch" >
            <!-- <span class="closeBtn" @click="drawMoneyDialog.show = false">
              <i class="iconfont icon-icon_close_dark"></i>
            </span> -->
            <div class="dialogBody transactionPwdBody">
              <el-form ref="drawMoneyRef" :model="drawMoneyDialog" :rules="rules" label-position="top">
               
                <el-form-item label="Mật khẩu giao dịch mới" prop="password">
                  <el-input v-model="drawMoneyDialog.password" placeholder="Mật khẩu rút tiền" type="password" />
                </el-form-item>
                <el-form-item label="Xác nhận lại mật khẩu giao dịch" prop="checkPass">
                  <el-input v-model="drawMoneyDialog.checkPass" placeholder="Mật khẩu rút tiền" type="password" />
                </el-form-item>
              </el-form>
              <el-button style="width:100%;" class="el-button el-button--primary el-button--small" @click="drawMoneySumit">
                {{ $t("提交") }}</el-button>
            </div>
          </el-dialog>
          <el-dialog v-model="addBankDialog.show" width="400" title="Thêm thông tin ngân hàng" >
            <!-- <span class="closeBtn" @click="addBankDialog.show = false">
              <i class="iconfont icon-icon_close_dark"></i>
            </span> -->
            <div class="dialogBody transactionPwdBody">
              <el-form ref="addBankRef" :model="addBankDialog" :rules="rules" label-position="top">
                <el-form-item label="Chọn tên ngân hàng">
                  <el-select v-model="addBankDialog.bankName" style="width:100%;">
                    <el-option v-for="item in addBankDialog.list" :key="item" :value="item.BtName">{{ item.BtName
                    }}</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Vui lòng nhập stk ngân hàng" prop="cardNum">
                  <el-input v-model="addBankDialog.cardNum" placeholder="vui lòng nhập số tài khoản ngân hàng" />
                </el-form-item>
                <el-form-item label="Xin điền họ tên" prop="realName">
                  <el-input v-model="userAll.userData.realName" :disabled="addBankDialog.isDisabled"
                    style="cursor: not-allowed" placeholder="Xin điền họ tên" />
                </el-form-item>
      
                <el-form-item label="Vui lòng Mật khẩu rút tiền" prop="DoudouPwd">
                  <el-input type="text" v-model="addBankDialog.DoudouPwd" style="cursor: not-allowed"
                    placeholder="Vui lòng Mật khẩu rút tiền" />
                </el-form-item>
              </el-form>
              <el-button style="width:100%;" class="el-button el-button--primary el-button--small" @click="addBankSumit">
                {{ $t("提交") }}</el-button>
            </div>
          </el-dialog>
    </div>
</template>

<script>

import moment from "moment";
import { mapGetters, mapActions, mapMutations } from "vuex";

import { topic } from "@/components/protocol/api";
export default {
    data() {
        return {};
    },
    props: {},
    computed: {
        ...mapGetters("tcp", ["isLogged", "wallet", "basicInfo"]),
        ...mapGetters("user", ["userInfo"]),
    },
    components: {},
    created() {
        var that = this;

    },
    methods: {

        getDay() {
            let dateSpan, tempDate, iDays

            var date = moment();
            var sDate1 = this.$tools.formatDate(this.userInfo.CreateAt, "yyyy-MM-dd")
            var sDate2 = moment(moment().valueOf()).format("YYYY-MM-DD")

            sDate1 = Date.parse(sDate1)
            sDate2 = Date.parse(sDate2)
            dateSpan = sDate2 - sDate1
            dateSpan = Math.abs(dateSpan)
            iDays = Math.floor(dateSpan / (24 * 3600 * 1000))
            return iDays + 1

        },
        openAvatar() {
            bus.emit(bus.event.avatarState, true);
        }
    },
};
</script>

<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    reactive,
    nextTick,
    watch,
} from "vue";

import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import sf_games_btn_02_svga from "@/assets/pc/svga/sf_games_btn_02.svga";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { nameRule, passRule, phoneRule, realNameRule } from '@/utils/vaildate.js'
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { watchEffect } from "vue";
import { bus } from '../../../components/core/bus';
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const curRoute = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin, } = useActions("user", [
    "onLogged",
    "doLogin",
]);
let registerDays = ref(0);
let tabSelectIndex = ref(0);
const tabList = reactive([
    { router: 'discountDraw', text: 'Nhận ưu đãi ', iconfont: 'icon-icon_nav_discounts' },
    { router: 'personal', text: 'Thông tin cá nhân', iconfont: 'icon-icon_personal_data' },

    { router: 'record', text: 'Lịch sử giao dịch', iconfont: 'icon-icon_top_up_record' },
    { router: 'billRecord', text: 'Lịch sử cược', iconfont: 'icon-icon_vote_record' },
    { router: 'betHistory', text: 'Doanh số cược', iconfont: 'icon-icon_stake' },
    { router: 'message', text: 'Hộp thư', iconfont: 'icon-icon_message_centre' },
    { router: '/help', text: 'Trung tâm trợ giúp', iconfont: 'icon-icon_help_centre' },
    { router: 'lotteryResult', text: 'Kết quả mở thưởng', iconfont: 'icon-icon_draw_notice' },
    { router: 'userVip', text: 'Phúc lợi Vip', iconfont: 'icon-icon_personal_data' },


])

let drawMoneyDialog = reactive({
  show: false,
  oldDwPassword: "",
  password: "",
  checkPass: "",
});
let addBankDialog = reactive({
  show: false,
  list: [],
  selectIndex: 0,
  bankName: "",
  cardNum: "",
  realName: "",
  isDisabled: false,
  DoudouPwd: "",
});

let userAll = reactive({
  userData: {
    DoudouPwd: []
  },
});
let addBankRef = ref(null);
let drawMoneyRef = ref(null);

if (isLogged.value) {
    getUserInfo()
} else {
    bus.on('onConnected', () => {
        getUserInfo()
    })
}

const addBankSumit = () => {
  addBankRef.value.validate((valid) => {
    if (valid) {
      let obj = {
        btName: addBankDialog.bankName,
        cardNum: addBankDialog.cardNum,
        accountName: userAll.userData.realName.toString(),
      };
      mqant.request(topic.bindBank, obj, function (data, topicName, msg) {
        if (data.Code == 0) {
          ElMessage({
            message: "Thiết lập thành công",
            type: "success",
          });
          addBankDialog.show = false;
        } else {
          ElMessage({
            message: data.ErrMsg,
            type: "error",
          });
        }
      });
    } else {
      return false;
    }
  });
};

const drawMoneySumit = () => {
  drawMoneyRef.value.validate((valid) => {
    if (valid) {
      let obj = {
        DoudouPwd: drawMoneyDialog.checkPass,
      };
      mqant.request(
        topic.updateDoudouPwd,
        obj,
        function (data, topicName, msg) {
          if (data.Code == 0) {
            ElMessage({
              message: "Thiết lập thành công",
              type: "success",
            });
            drawMoneyDialog.show = false;
            addBankDialog.show = true;

            drawMoneyDialog.password = '';
            drawMoneyDialog.oldDwPassword = '';
            drawMoneyDialog.checkPass = '';
            
          } else {
            ElMessage({
              message: data.ErrMsg,
              type: "erro",
            });
          }
        }
      );
    } else {
      return false;
    }
  });
}; 

function getUserInfo() {
    mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        if (data.Code == 0) {
            registerDays.value = data.Data.RegisterDays
        }
    });
}
function tabItemClick(index, name) {
    tabSelectIndex.value = index;
    if (name == '/help') {
        //帮助中心
        router.push({
            path: '/help',
            query: {
                index: 0
            }
        })
        return;
    }

    if (name == "recharge" || name == "drawMoney") {
    mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
      getBankList();
      userAll.userData = data.Data;
      addBankDialog.isDisabled = !data.Data.realName ? false : true

        if (!data.Data.DoudouPwd.length) {
        drawMoneyDialog.show = true;

        return
      }
      if (!data.Data.myDouDouBT.length) {
        addBankDialog.show = true;


    } else {
        router.push({
          path: "/userCenter/" + name,
          query: {
            name: name,
          },
        });
      }

    });
  }else {
    router.push({
        path: '/userCenter/' + name,
        query: {
            name: name
        }
    })
  }
}


function getBankList() {
  mqant.request(topic.bankList, {}, function (data, topicName, msg) {
    addBankDialog.list = data.Data.douDouBtList;
    addBankDialog.bankName = data.Data.douDouBtList[0].BtName;

    // log.info('getBankList', data.Data)
  });
}


function changeSelectIndex() {
    if (!curRoute.query.name) return;
    if (curRoute.path == "/help") return;//不处理help
    if (curRoute.query.name == 'recharge') {
        tabSelectIndex.value = -1;
    } else if (curRoute.query.name == 'drawMoney') {
        tabSelectIndex.value = -2;
    }
    else {
        for (let key in tabList) {
            if (curRoute.query.name == tabList[key].router) {
                tabSelectIndex.value = Number(key);
                break;
            }
        }
    }
    tabItemClick(tabSelectIndex.value, curRoute.query.name);
}
watch(
    () => curRoute.query.name, () => {
        changeSelectIndex()
    }, { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
.user {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color: #1e242c;
    flex-basis: 20px;
    padding-top: 24px;

    .leftMenu {
        width: 213px;

        .payCard {
            color: #8491a5;

            .userInfoBox {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 16px;
                height: 200px;
                background-image: linear-gradient(137deg, #a98fff 1%, #4a69ff 99%);
                border: 1px solid rgba(115, 122, 146, .14);
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, .05);
                border-radius: 12px;

                .avatar {
                    width: 93px;
                    height: 93px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        max-width: 93px;
                        max-height: 93px;
                        height: auto;
                        display: block;
                        object-fit: cover;
                        vertical-align: middle;
                    }
                }

                .info {
                    padding-left: 9px;

                    .userName {
                        color: #fff;
                        width: 142px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        margin-bottom: 4px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 1.5;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;

                        span {
                            font-size: 15px;
                            margin-right: 5px;
                        }
                    }

                    .jionDay {
                        height: auto;
                        width: 100%;
                        font-size: 13px;
                        color: hsla(0, 0%, 100%, .65);
                        word-wrap: break-word;
                        margin-top: 10px;
                        text-align: center;
                    }
                }
            }

            .userMoney {
                padding: 16px;
                margin-top: 13px;
                position: relative;
                background: #292e3b;
                border: 1px solid rgba(115, 122, 146, .14);
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, .05);
                border-radius: 12px 12px 0 0;
                height: 140px;
                color: #2b2e34;

                .money {
                    font-size: 13px;
                    color: #ffaf13;
                    font-weight: 500;
                    margin-bottom: 10px;
                    max-width: 190px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .totalMoney {
                        color: #8491a5;
                        margin-right: 5px;
                    }

                    .icon-icon_refresh_gold_12 {
                        cursor: pointer;
                        margin-left: 5px;
                        color: #4a69ff;
                    }
                }

                .payInfo {
                    display: flex;
                    justify-content: space-between;
                    flex-basis: 20px;
                    width: 182px;
                    margin-top: 10px;
                    background: #262933;
                    border-radius: 7px;
                    padding: 8px 0;

                    div {
                        width: 84px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        font-size: 14px;
                        color: #8491a5;
                        box-shadow: none;
                        cursor: pointer;
                        border-radius: 16px;

                        img {
                            font-size: 28px;
                            height: 28px;
                            margin-bottom: 4px;
                        }

                        span {
                            font-size: 12px;
                            margin-top: 5px;
                            -webkit-transform: scale(.86);
                            transform: scale(.86);
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .menubar {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            overflow: hidden;
            background: #292e3b;
            border-right: none;
            box-shadow: inset 0 -0.5px 0 0 rgba(132, 144, 167, .3);
            font-size: 14px;

            .menubar-item {
                padding: 0 20px;
                display: flex;
                align-items: center;
                height: 56px;
                line-height: 56px;
                cursor: pointer;
                position: relative;
                transition: border-color .3s, background-color .3s, color .3s;
                box-sizing: border-box;
                white-space: nowrap;
                border: none;
                box-shadow: inset 0 -0.5px 0 0 rgba(132, 144, 167, .3);
                color: #8491a5;

                .menuIcon {
                    margin: 0 15px 0 0;
                    font-size: 16px;
                }

                .themeActiveBar {
                    position: absolute;
                    width: 4px;
                    height: 32px;
                    background-color: #fff;
                    border-radius: 2px;
                    top: 12px;
                    left: 0;
                }
            }

            .select {
                background-color: #1e222c;
                color: #fff;
            }
        }
    }

    .rightView {
        overflow: hidden;
        width: 1160px;
        background-color: #292e3b;
        border: 1px solid rgba(115, 122, 146, .14);
        box-sizing: border-box;
        margin-left: 24px;
        border-radius: 12px;
    }

    .smallRightView {
        width: 807px !important;
    }
}
</style>